import React from "react";
import "../../App.scss";
import { Card, PageHeader, List, Tag, Spin, Badge } from "antd";
import Breadcrumb from "../common/breadcrumb";
import { withRouter, NavLink } from "react-router-dom";
import rest from "../../helpers/rest";
import TradingTimer from './timer';
import * as moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru')


class TradingSelectComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tradelist: [],
      loading: true
    };
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Выбор торгового дня", key: "/" },
    ];
  }

  componentWillMount() {
    document.title = "Выбор торгового дня | Букетная.ОПТ";
    this.getActiveTrades();
  }

  getActiveTrades() { 
    let byAdmin = false;
    if(localStorage.getItem('c_token')){byAdmin = true};

    rest.get("/api/Trading/", {active: true, byAdmin: byAdmin}).then((data) => {
      
      this.setState({ tradelist: data.data.data, loading: false });      
    });
  }

  render() {
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,
      ,
      <Card key={2} className="content-card">
        <PageHeader
          className="site-page-header"
          onBack={() => this.props.history.goBack()}
          title="Выберите торговый день"
        />
        <Spin spinning={this.state.loading}>
        <div className="tradelistwrapp">
        <List
          itemLayout="horizontal"
          dataSource={this.state.tradelist}
          renderItem={(item) => (
            <List.Item actions={ (!item.diffdates || item.diffdates < 0) && [
              
              <NavLink className="list-loadmore-more" to={`/exchange/${item.id}`}>
                Перейти
              </NavLink>
        ]} >
              <List.Item.Meta                
                title={<Tag color="green">{moment(item.date).format('DD MMMM YYYY')}</Tag>}
                description={[`Осталось позиций: ${item.poscount}`]}
              />
              {
                item.diffdates > 0 && <TradingTimer update={() => window.location.reload()} seconds={item.diffdates} />
              }
              
            </List.Item>
          )}
        />
        </div>
        </Spin>
      </Card>,
    ];
  }
}

export default withRouter(TradingSelectComponent);

import React from "react";
import "../../App.scss";
import { Spin, Card, Row, Col, Tooltip, Form, Input } from "antd";
import Breadcrumb from "../common/breadcrumb";
import {
  PageHeader,
  Button,
  Collapse,
  Select,
  Table,
  Tag,
  Divider,
  Popconfirm,
  Switch,
} from "antd";
import { withRouter, NavLink } from "react-router-dom";
import AppHelper from "../../helpers/app";
import rest from "../../helpers/rest";
import {
  EditOutlined,
  DeleteOutlined,
  AlignLeftOutlined,
  LoginOutlined,
  UserOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import moment from "moment";
const { Option } = Select;
class UsersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Справочники", key: "/directory/" },
      { title: "Пользователи", key: "/users/" },
    ];
    this.state = {
      cataspan: 24,
      loading: true,
      userlist: [],
      createuserload: false,
      pageSize: parseInt(localStorage.getItem("userspagesize")) || 20,
      managers: [],
      numberUsers: ''
    };
    this.formFilterRef = React.createRef();
    this.addUser = this.addUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.loginAs = this.loginAs.bind(this);
    this.resetFilter = this.resetFilter.bind(this);

    this.columns = [
      {
        title: "ФИО",
        dataIndex: "fio",
        render: (text, record) => {
          return (
            <p>
              {record.discount > 0 && (
                <PercentageOutlined spin style={{ color: "#52c41a" }} />
              )}{" "}
              {`${record.sname} ${record.fname}`}
            </p>
          );
        },
      },
      { title: "Номер телефона", dataIndex: "phone" },
      { title: "Логин", dataIndex: "login" },
      { title: "Организация", dataIndex: "orgName" },
      {
        title: "Баланс",
        dataIndex: "balance",
        render: (text, record) => {
          return (
            <Tag color={record.balance >= 0 ? "green" : "red"}>
              {AppHelper.toCurrency(record.balance, "RUB", "Ru-ru")}
            </Tag>
          );
        },
      },
      {
        title: "Последний контакт",
        dataIndex: "LAST_CALL",
        render: (text, record) => {
          return !record.LAST_CALL ? 'Отсутствует' : moment(record.LAST_CALL).format('DD MMMM YYYY')
        },
      },
      {
        title: "Тип",
        key: "type",
        render: (text, record) => {
          return record.type === "admin" ? (
            <span>Администратор</span>
          ) : (
            <span>Пользователь</span>
          );
        },
      },
      {
        title: "Активен",
        key: "ban",
        render: (text, record) => {
          return record.ban === 1 ? (
            <Tag color="red">Заблокирован</Tag>
          ) : (
            <Tag color="green">Активен</Tag>
          );
        },
      },
      {
        title: "Действия",
        key: "actions",
        render: (text, record) => {
          return !this.props.forSelect ? (
            <span>
              {record.type === "user" && (
                <Tooltip
                  title={`Войти как пользователь ${record.sname} ${record.fname}`}
                >
                  <LoginOutlined onClick={() => this.loginAs(record.login)} />
                </Tooltip>
              )}
              <Divider type="vertical" />
              {record.type === "user" && (
                <Tooltip title="Заявки пользователя">
                  <NavLink to={`/users/${record.id}/requests`}>
                    <AlignLeftOutlined />
                  </NavLink>
                </Tooltip>
              )}
              <Divider type="vertical" />
              <NavLink to={`/users/${record.id}`}>
                <EditOutlined />
              </NavLink>
              <Divider type="vertical" />
              <Popconfirm
                title="Удалить пользователя?"
                onConfirm={() => this.deleteUser(record)}
                okText="Да"
                cancelText="Нет"
              >
                <a style={{ color: "red" }}>
                  <DeleteOutlined />
                </a>
              </Popconfirm>
              <Divider type="vertical" />
              {
                <NavLink
                  to={`/${record.type == "user" ? "profile" : "manager"}/${
                    record.id
                  }`}
                >
                  <UserOutlined />
                </NavLink>
              }
            </span>
          ) : (
            <Button
              type="primary"
              onClick={() => this.props.onSelect(record.id)}
            >
              Выбрать
            </Button>
          );
        },
      },
    ];
    if (this.props.forSelect) {
      this.columns = this.columns.filter(
        (i) => !["orgName", "balance", "type"].includes(i.dataIndex)
      );
    }
  }

  deleteUser(user) {
    this.setState({ loading: true });
    rest.delete("/api/Account", { id: user.id }).then((deleteuser) => {
      this.getUsers();
    });
  }

  loginAs(login) {
    this.setState({ loading: true });
    rest
      .post("/api/Account/token", { login: login, byAdmin: true, password: "" })
      .then((data) => {
        let ctoken = localStorage.getItem("token", data.data.token);
        localStorage.setItem("c_token", ctoken);
        localStorage.setItem("token", data.data.token);
        this.props.history.push("/stock");
        window.location.reload();
      })
      .catch((err) => {
        let status = err.response ? err.response.status : 200;
        if (status === 401) message.error("Неверный логин или пароль");
        if (status === 403)
          message.error(
            "Ваш аккаунт заблокирован. Обратитесь к администратору"
          );
      });
  }

  addUser() {
    this.setState({ createuserload: true });
    rest.post("/api/Account/empty").then((data) => {
      this.props.history.push(`/users/${data.data.data.insertId}`);
    });
  }

  componentDidMount(){
    if(localStorage.getItem('filter')){
      let filter = JSON.parse(localStorage.getItem("filter"));
      this.formFilterRef.current.setFieldsValue({
        phone: filter.phone,
        manager: filter.manager,
        withdiscount: filter.withdiscount,
      })
    }
  }

  componentWillMount() {
    document.title = "Пользователи | Букетная.ОПТ";
    this.getUsers();
    rest.get("/api/Account/managers").then((managers) => {
      console.log(managers.data.data)
      this.setState({ managers: managers.data.data });
    });
    console.log(this.managers)
  }

  getUsers() {
    let params = {};
    if (this.formFilterRef.current) {
      localStorage.setItem('filter', JSON.stringify(this.formFilterRef.current.getFieldsValue()));
    }

    this.setState({ loading: true });
    console.log(params)
    if(localStorage.getItem('filter')){
      params = JSON.parse(localStorage.getItem("filter"));
    }
    
    rest.get("/api/Account/getlist", params).then((users) => {
      let userlist = users.data.data;
      userlist.map((item, index) => {
        item.key = index;
      });
      console.log(this.getEnding(userlist.length))
      this.setState({numberUsers:this.getEnding(userlist.length)})
      this.setState({ userlist: userlist, loading: false });
    });
    
  }

  getEnding(value){
    console.log(value)
    let words = ['клиент', 'клиента', 'клиентов'];
    let n = value % 100;
    if(n >= 5 && n <= 20){
      return  value + ' клиентов'
    }
    n = value % 10;
    if(n == 1){
      return value + ' клиент'
    }
    if(n >= 2 && n <= 4){
      return value + ' клиента'
    }
    return value + ' клиентов'
  }

  resetFilter() {
    let params = {};
    this.setState({ loading: true });
    this.formFilterRef.current.resetFields()
 
    rest.get("/api/Account/getlist", params).then((users) => {
      let userlist = users.data.data;
      userlist.map((item, index) => {
        item.key = index;
      });
      this.setState({ userlist: userlist, loading: false });
    });
  }

  render() {
    const style = { padding: "0px 8px" };
    return [
      !this.props.forSelect && (
        <Breadcrumb
          key={1}
          style={{ margin: "16px 0" }}
          list={this.breadcrumbs}
        />
      ),
      <Row key={2}>
        <Col span={this.state.cataspan}>
          <Card className="content-card">
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Пользователи"
              extra={[
                !this.props.forSelect && (
                  <Spin key="1" spinning={this.state.createuserload}>
                    <Button type="primary" onClick={() => this.addUser()}>
                      <span>Добавить пользователя</span>
                    </Button>
                  </Spin>
                ),
              ]}
            />
            <Card>
              <div className="stock_filter">
                <Form
                  name="filter"
                  layout="vertical"
                  ref={this.formFilterRef}
                  onFinish={() => this.getUsers()}
                >
                  <Form.Item label="Номер телефона" name="phone">
                    <Input placeholder="Не выбрано"/>
                  </Form.Item>
                  <Form.Item label="Менеджер" name="manager">
                  <Select
                      allowClear
                      placeholder ="Не выбрано" 
                      style={{textAlign:'left'}}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toString().toLowerCase().includes(input.toLowerCase())}
                    >
                      {this.state.managers.map(item=> <Option value={+item.id}>{item.fname + ' ' + item.sname}  </Option>)}
                    </Select>
                  </Form.Item>
                  <div className="users__discount_switcher">
                    <Form.Item
                      label="Только со скидкой"
                      name="withdiscount"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </div>

                  <Form.Item>
                    <Button className="filterbtn" onClick={this.resetFilter}>
                      Сбросить фильтр
                    </Button>
                    <Button className="filterbtn" htmlType="submit">
                      Применить
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Card>
            <div className="users_number">Результат поиска: {this.state.numberUsers}</div>
            <div className="userswrapp">
              <Spin spinning={this.state.loading}>
                <Table
                  columns={this.columns}
                  dataSource={this.state.userlist}
                  style={{ marginTop: 20 }}
                  onChange={(e) => {
                    localStorage.setItem("userspagesize", e.pageSize);
                    this.setState({ pageSize: e.pageSize });
                  }}
                  pagination={{
                    pageSizeOptions: [10, 20, 50, 100],
                    pageSize: this.state.pageSize,
                    showSizeChanger: true,
                  }}
                />
              </Spin>
            </div>
          </Card>
        </Col>
      </Row>,
    ];
  }
}

export default withRouter(UsersComponent);

import * as React from "react";
import {
  Modal,
  Button
} from "antd";
import Stock from "../../../stock/stock";

class StockSelectorComponent extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {visible: this.props.visible};    
  }

  componentWillReceiveProps(next){
      this.setState({visible: next.visible});
  }

 


  render() {
    return (
        <Modal
        visible={this.state.visible}
        title="Выбор позиции склада"    
        width="1300px"    
        onCancel={() => {
            this.props.onClose();
        }}
        footer={[          
          <Button key="submit" onClick={() => this.props.onClose()}>
            Закрыть
          </Button>          
        ]}
      >
        <Stock forSelect={true} hideActions={true} onSelect={(data) => {
            this.props.onSelect(data.id);
        }} />
      </Modal>
    );
  }
}

export default StockSelectorComponent;

import * as React from "react";
import { Breadcrumb, Skeleton, Spin } from "antd";
import { NavLink } from "react-router-dom";
import AppHelper from "../../../../helpers/app";
import rest from "../../../../helpers/rest";
import RequestSingleV2 from "./single";
import AppContext from "../../../../components/common/appContext";
import Emitter from '../../../../helpers/events';
class RequestListV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      totalSize: 0,
      currentPage: 1,
      filterParams: {},
      requests: [],
      loading: false,      
    };    
  }

  getRequests = (filter = {}, start = false) => {
    this.setState({ loading: true, totalSize: 0, filterParams: filter});
    let params = this.state.filterParams;
    if(start){
      params.page = 1;
      params.pagesize = 10;
      this.setState({currentPage: 1, requests: []})
    }else{
      params.page = this.state.currentPage;
      params.pagesize = this.state.pageSize;
    }
    
    if(filter){
      params = {...params, ...filter};
    }

    rest.get("/api/Requests/all", params).then((data) => {
        
      this.setState({
        totalSize: data.data.data.count,
        requests: [...this.state.requests, ...data.data.data.list],
        loading: false,
      });
    });
  };

  componentWillMount() {
    this.getRequests();
    Emitter.on('scrollend', () => {
        if(this.state.totalSize == this.state.requests.length) return;        
        if(!this.state.loading){
            this.setState({currentPage: this.state.currentPage +1});
            this.getRequests(this.state.filterParams);
        }
        
    })
  }

  

  render() {
    return [
      <div        
        key={1}
        className="requests__list"
      >
          <Spin spinning={this.state.loading} >
        {this.state.requests.map((elem, index) => {
          return <RequestSingleV2 key={elem.id} data={elem} />;
        })}
        </Spin>
      </div>,
    ];
  }
}
RequestListV2.contextType = AppContext;

export default RequestListV2;

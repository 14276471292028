import React from "react";
import "../../App.scss";
import { Modal, Button } from 'antd';
import UsersComponent from '../users/index';
class UserSelectModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: this.props.visible };
  }

  componentWillReceiveProps(nextProps){
      this.setState({visible: nextProps.visible})
  }

  render() {
    return (
      <Modal width="1224px" title="Выбор пользователя" visible={this.state.visible} onCancel={() => this.props.onClose()}>
        <UsersComponent forSelect={true} onSelect={(id) => this.props.onSelect(id)} />
      </Modal>
    );
  }
}



export default UserSelectModalComponent;

import React from "react";
import "../../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import AppContext from "../../../components/common/appContext";
import {
  Spin,
  Card,
  Row,
  Col,
  Tooltip,
  Form,
  Input,
  Skeleton,
  Table,
  Tag,
  DatePicker,
  Space,
  message,
} from "antd";
import { PageHeader, Menu, Dropdown, Button, Tabs } from "antd";
import rest from "../../../helpers/rest";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../../helpers/app";
import RequestPositonsModal from "../urequests/requestpositions/index";
//import 'moment/locale/zh-cn';
import locale from "antd/es/date-picker/locale/ru_RU";
import { EyeOutlined } from "@ant-design/icons";
moment.locale("ru");
const { RangePicker } = DatePicker;

class UsersRequestMini extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: true,
      tableData: [],
      showpositionsrequest: null,
      showpositionsvisible: false,
    };

    this.getBalanceInfo = this.getBalanceInfo.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.historycolumns = [
      {
        title: "Номер заявки",
        render: (text, record) => {
          return `№${record.id}`;
        },
        key: "id",
      },
      {
        title: "Дата создания",
        key: "created",
        render: (text, record) => {
          return moment(record.created).format("DD-MM-YYYY HH:mm");
        },
      },
      {
        title: "Статус",
        render: (text, record) => {
          let status = AppHelper.getRequestStatus(record.status);
          return <Tag color={status.color}>{status.label}</Tag>;
        },
        key: "summ",
      },
      {
        title: "Действия",
        render: (text, record) => {
          return (
            <a>
              <EyeOutlined
                onClick={() => {
                  this.setState({
                    showpositionsrequest: record,
                    showpositionsvisible: true,
                  });
                }}
              />
            </a>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.getBalanceInfo();
    this.props.onRef(this);
    //console.log(this.context)
  }

  getBalanceInfo(start, end) {
    let params = {};
    if (start) {
      params.start = start;
    }
    if (end) {
      params.end = end;
    }
    if (this.props.userid && !this.props.formanager) {
      params.userid = this.props.userid;
    } else {
      if (this.props.userid && this.props.formanager) {
        params.managerid = this.props.userid;
      } else {
        params.userid = this.context.id;
      }
    }
    params.pagesize = "unset";

    this.setState({ loading: true });
    rest.get("/api/Requests/all", params).then((data) => {
      //console.log(data)
      let tabledata = data.data.data.list;
      tabledata.map((i, index) => {
        tabledata[index].key = i.id;
      });
      this.setState({ loading: false, tableData: tabledata });
      if (start && end) {
        this.formRef.current.setFieldsValue({
          date: [moment(start), moment(end)],
        });
      }
    });
  }

  onSubmit() {
    let formdata = this.formRef.current.getFieldsValue();

    if (formdata.date) {
      this.getBalanceInfo(
        moment(formdata.date[0]).format("YYYY-MM-DD"),
        moment(formdata.date[1]).format("YYYY-MM-DD")
      );
    } else {
      this.getBalanceInfo(null, null);
    }
  }

  render() {
    return [
      <RequestPositonsModal
        onRef={(ref) => (this.child = ref)}
        key={0}
        request={this.state.showpositionsrequest}
        visible={this.state.showpositionsvisible}
        close={() =>
          this.setState({ showpositionsvisible: false, showpositionsid: null })
        }
      ></RequestPositonsModal>,
      this.state.loading ? (
        <Skeleton key={1} />
      ) : (
        <div key={1} className="balancehistory">
          <Form
            onFinish={this.onSubmit}
            onFinishFailed={() => message.warn("Заполните данные")}
            name="basic"
            className="useredit"
            ref={this.formRef}
            layout="vertical"
          >
            <div className="userdateselector">
              <Form.Item name="date">
                <RangePicker locale={locale} />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "10px" }}
              >
                Фильтровать
              </Button>
            </div>
          </Form>
          <Table
            dataSource={this.state.tableData}
            columns={this.historycolumns}
          />
        </div>
      ),
    ];
  }
}

UsersRequestMini.contextType = AppContext;

export default withRouter(UsersRequestMini);




import React from 'react';
import '../../App.scss';
import { Card, Form, Spin, Input, InputNumber, Row, Col, message } from 'antd';
import Breadcrumb from '../common/breadcrumb'
import AppContext from '../../components/common/appContext';
import { PageHeader, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import rest from '../../helpers/rest';
import StockEditForm from "../../components/stock/editstocksform";
import Loading from '../loading';

const {TextArea} = Input;


class ExchangeEditComponent extends React.Component{
    constructor(props){
        super(props);                  
        this.state = {loading: true, data: {}, editloading: false}    
        this.breadcrumbs = [
            { title: "Букетная.ОПТ", key: "/" },
            { title: "Биржа", key: "/exchange/" },
            { title: "Редактирование", key: "" },
          ]
          this.childForm = React.createRef();
    }


    componentWillMount(){      
      rest.get('/api/Exchange/single', {id: this.props.match.params.id}).then(data => {        
        this.setState({data: data.data.data, loading: false})
      })
    }

    submit(data){      
      let params = data;
      params.id = this.state.data.id;
      this.childForm.setLoad(true);
      this.setState({editloading: true})
      rest.update('/api/Exchange/', params).then(data => {        
        this.setState({editloading: false})
        this.childForm.setLoad(false);
        message.success('Позиция биржи изменена')
        //console.log(params);
        if(params.tradingday){
          this.props.history.push(`/exchange/${params.tradingday}`)
        }else{
          this.props.history.push('/exchange')
        }
        
        
      })
    }

    

    

    render(){        
        return [
            <Breadcrumb
            key={1}
            style={{ margin: "16px 0" }}
            list={this.breadcrumbs}
          />,
          <Row key={2}>
          <Col span={24}>
          <Card className="content-card">
          <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Редактирование позиции биржи"
              
            />
           
            {
              !this.state.loading ?
              <div className="editformstock">
              <StockEditForm forTrade={true} onSuccessSubmit={(data) => this.submit(data)} onRef={(ref) => (this.childForm = ref)} data={this.state.data} catalogid={this.state.data.catalogid} />
              <Spin spinning={this.state.editloading}><Button onClick={() => this.childForm.submitForm()} type="primary">Сохранить</Button></Spin>
              </div> : <Loading mini={true} />
            }
            
           
            </Card>
              </Col>
              </Row>
            ]
        
    }
}

ExchangeEditComponent.contextType = AppContext;

export default withRouter(ExchangeEditComponent);
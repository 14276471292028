import * as React from "react";
import { Select, Form, message, Skeleton } from "antd";
import rest from "../../helpers/rest";
import { Radio, Spin } from "antd";
import AppContext from "../common/appContext";
import Loading from "../loading";
const { Option } = Select;

class DevileryButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delivery: '',
      type: this.props.type,
      loading:true
    };

    this.formRef = React.createRef();
  }

  componentWillMount() {
    let params = {};
    if(this.state.type = "stock"){
      params.id = this.context.id;
      params.type = this.props.type;
       
    }
    if(this.state.type = "exchange"){
      params.id = this.context.id;
      params.type = this.props.type;
       
    }
    rest.get("/api/Requests/getdelivery", params).then((data) => {
      this.setState({
        delivery: data.data.data[0] ? data.data.data[0].delivery : null,
        IDRequest: data.data.data[0] ? data.data.data[0].id : null,
        loading: false
      });
      console.log(this.state.delivery);
    });
  }

  onChangeDelivery = (e) => {
    let params = {}
    params.delivery = e.target.value;
    params.id = this.context.id;
    params.type = this.props.type;
    this.setState({  loading:true})
    this.setState({ delivery: e.target.value }, () => console.log(e.target.value, this.state.delivery));
    rest.update("/api/Requests/updatedelivery", params).then((data) => {
      this.setState({ loading: false})
    }).catch(e => {
      message.error(e.response.data.data.detail)
      this.setState({delivery:0,  loading: false})
      //console.log(e.response.data.data.detail);
    });
  };

  render() {
    return (
      !this.state.loading ?
        <Form
        name="radio_date"
        ref={this.formRef}
        className="dashfastactions"
        layout="vertical"
        initialValues={{radio: this.state.delivery}}
      >
        <Form.Item name="radio">
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            onChange={this.onChangeDelivery}
        
          >
            <Radio.Button value={1} >
              Доставка
            </Radio.Button>
            <Radio.Button value={2} >
              Такси
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form> : <Spin spinning={this.state.loading}> </Spin>
    );
  }
}

DevileryButtonComponent.contextType = AppContext;

export default DevileryButtonComponent;

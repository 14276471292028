import React from "react";
import "../../../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import AppContext from "../../../../components/common/appContext";
import { Spin, Card, Row, Col, Tooltip, Form, Input, Skeleton, Table, Tag, DatePicker, Space, message, } from "antd";
import { PageHeader, Menu, Dropdown, Button, Tabs } from "antd";
import rest from "../../../../helpers/rest";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../../../helpers/app";
//import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/ru_RU';
import {EyeOutlined} from '@ant-design/icons';
import Modal from "antd/lib/modal/Modal";
import TotalPrice from '../../../requests/totalprice'
moment.locale("ru");
const { RangePicker } = DatePicker;





class RequestPositonsModal extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {loading : true, request: {id: null}, visible: false, positions: []}
    this.getPositions = this.getPositions.bind(this);
  }

 

  componentDidMount() {this.props.onRef(this)}

  getPositions(){
      if(this.state.request && this.state.visible){
          this.setState({loading: true})
        rest.get('/api/Cart/getbyrequest', {request: this.state.request.id, type: this.state.request.type}).then(data => {            
            this.setState({loading: false, positions: data.data.data.cart})
        })
      }
      
  }

  componentWillReceiveProps(nextProps) {
      
      this.setState({request: nextProps.request, visible: nextProps.visible, positions: []}, () => {
        this.getPositions();
        if(this.child){
          this.child.getPrice()
        }
        //this.child.getPrice()
      })
  }


  render() {
    return [<Modal onCancel={() => this.props.close()}  onOk={() => this.props.close()} key={0} visible={this.state.visible} title={`Список позиций заявки №${this.state.request ? this.state.request.id : 0}`}>
        <Spin spinning={this.state.loading}>
        {!this.state.loading && <TotalPrice nopadding={true} onRef={ref => (this.child = ref)} type={this.state.request ? this.state.request.type : 0} id={this.state.request ? this.state.request.id : 0} />}
        <div className="minipositionslist">
            {
                this.state.positions.map((i, index) => {
                    return <div key={index} className="minipositem">
                        <p className="name">{i.name}</p>
                        <p className="confirm"><span className="label">Подтвержденное количество:</span> <span className="value">{i.confirmedquantity}</span></p>
                        <p className="price"><span className="label">Стоимость:</span> <span className="value">{AppHelper.toCurrency(i.price, "RUB","Ru-ru")}</span></p>
                    </div>
                })
            }
            
        </div>
        </Spin>
    </Modal>];
  }
}

RequestPositonsModal.contextType = AppContext;

export default withRouter(RequestPositonsModal);

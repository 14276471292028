import React from 'react';
import '../../App.scss';
import {Card, PageHeader, Form, Button, Pagination, Input, Menu, Dropdown, Space } from 'antd';
import Breadcrumb from '../common/breadcrumb'
import CategorySelectComponent from '../common/categoryselect';
import ColorSelectComponent from '../common/colorselect';
import SortSelect from '../common/sortselect';
import rest from '../../helpers/rest';
import Loading from '../loading';
import SingleGoodComponent from '../catalog/singlegood'
import { withRouter } from 'react-router-dom';
import AppHelper from '../../helpers/app';
import AppContext from "../../components/common/appContext";
import Devilery from "../common/devilerybutton";

class Exchange extends React.Component{
    constructor(props){
        super(props);
        this.breadcrumbs = [
            { title: "Букетная.ОПТ", key: "/" },
            { title: "Биржа", key: "/exchange/" }
        ];
        this.state = {
            filterParams: {tradingday: this.props.computedMatch.params.day},
            // По сколько отображать на странице
            pageSize: localStorage.getItem('pageSize') || 10,
            totalSize: 0,
            currentPage: 1,
            // Список позиций склада
            exchangeList: [],
            loading: true
        }
        this.formFilterRef = React.createRef(); 
        this.getExchange = this.getExchange.bind(this);
        this.filterAction = this.filterAction.bind(this)
        this.onChange = this.onChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.getBarCodes = this.getBarCodes.bind(this);
    }

    componentWillMount(){
        document.title = 'Биржа | Букетная.ОПТ'
        this.getExchange();
        //console.log(this.props)
        
    }

    componentDidMount(){
        //console.log(this.context.userdiscount)
        
    }

    onChange(page,size){
        AppHelper.setPageSize(size);
        this.setState({currentPage: page, pageSize: size})        
        this.filterAction(false);
    }

    getExchange(){
        this.setState({loading: true})
        let params = this.state.filterParams;
        params.page = this.state.currentPage;
        params.pagesize = this.state.pageSize
        rest.get('/api/Exchange', params).then(data => {
            
            this.setState({exchangeList: data.data.data.list, totalSize: data.data.data.count, loading: false, percentdiscount: (data.data.data.percentdiscount && data.data.data.percentdiscount.length > 0) ? data.data.data.percentdiscount[0] : null})
        })
    }
    
    filterAction(pageSkip = true){        
        let formdata = this.formFilterRef.current.getFieldsValue();     
        
        let params = {
            tradingday: this.props.computedMatch.params.day
        }
        Object.keys(formdata).map(item => {
            if(formdata[item] !== undefined){
                if(typeof(formdata[item]) === 'Array' && formdata[item].length > 0){
                    params[item] = formdata[item].join(',');
                }else{
                    params[item] = formdata[item];
                }
                
            }
        })  
        if(pageSkip){
            this.setState({filterParams: params, currentPage: 1}, () => {
                this.getExchange();
            });              
        }else{
            this.setState({filterParams: params}, () => {
                this.getExchange();
            });              
        }
        
    }

    resetFilter(){        
        this.setState({filterParams: {tradingday: this.props.computedMatch.params.day}})
        this.formFilterRef.current.resetFields();
        this.filterAction();

    }

    // Получение остатков
  getLost(pdf = false){
    
    this.context.setLoad(true, 'Генерация остатков')
    rest.get('/api/Exchange/lost', {tr: this.props.computedMatch.params.day}).then(data => {
      
      this.context.setLoad(false)
      if(pdf){
        console.log(`https://view.officeapps.live.com/op/view.aspx?src=${rest.getfileurl(data.data.data)}`)
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${rest.getfileurl(data.data.data)}`, '_blank');
      }else{
        
        window.open(rest.getfileurl(data.data.data), '_blank');
      }
      
    }).catch(e => {
      this.context.setLoad(false)
    })    
  }

    getBarCodes(){
        this.context.setLoad(true, 'Генерация штрих-кодов');
        rest.post('/api/BarCodes/', {type: 'exchange', tradingday: this.props.computedMatch.params.day}).then(data => {
            //data.data.data.pdf__name
            let url = rest.getfileurl(`/stickers/${data.data.data.pdf__name}`);
            window.open(url, 'blank');
            this.context.setLoad(false, '');
        })
    }

    menuRender() {
        return (
          <Menu>
            <Menu.Item onClick={() => this.props.history.push("/excelparser")}>
              <a>Выгрузить из Excel</a>              
            </Menu.Item>        
            <Menu.Item onClick={() => this.getBarCodes()}>
              <a>Получить штрихкоды</a>
            </Menu.Item>        
            <Menu.Item onClick={() => this.getLost(false)}>
              <a>Получение остатков - Excel</a>
            </Menu.Item>   
            <Menu.Item onClick={() => this.getLost(true)}>
              <a>Получение остатков - открыть</a>
            </Menu.Item>   
            
            
          </Menu>
        );
      }

      getPercentDiscount(){ 
        if(this.state.percentdiscount && this.state.percentdiscount.DISCOUNT.match(/\d+/g)){
          return parseInt(this.state.percentdiscount.DISCOUNT.match(/\d+/g)[0] || 0)
        }
        return null;
      }

    render(){
        
        return [
            <Breadcrumb key={1} style={{ margin: '16px 0' }} list={this.breadcrumbs} />,
                <Card
                    key={2}
                    className="content-card "
                >
                    <PageHeader
                        className="site-page-header exchange__actions"
                        onBack={() => this.props.history.goBack()}
                        title="Биржа"                       
                        extra={ (this.context.usertype === "admin") && <Space direction="vertical">
                        <Dropdown overlay={this.menuRender()} placement="bottomRight">
                          <Button>Действия</Button>
                        </Dropdown>
                      </Space>}
                    />
                    <Card>
                 <div className="stock_filter">
                    <Form                        
                        name="filter"
                        layout="vertical"
                        ref={this.formFilterRef}
                        initialValues={{ remember: true }}     
                        onFinish={() => this.filterAction()}                   
                        >
                            <Form.Item
                      label="Название позиции"
                      name="posname"                      
                    >
                      <Input />
                    </Form.Item>
                        <CategorySelectComponent tradingday={this.props.computedMatch.params.day} type={'exchange'} />                                                                       
                        <ColorSelectComponent />
                        <SortSelect hidePrice={false} hideGrowth={false} sort={() => this.filterAction()} />
                        <Form.Item>
                        <Button className="filterbtn" onClick={this.resetFilter}>
                            Сбросить фильтр
                        </Button>
                        <Button className="filterbtn" htmlType="submit">
                            Фильтровать
                        </Button>
                    </Form.Item>
                    </Form>
                    {this.context.usertype !== "admin" ?
                      <Devilery type={"exchange"}/>:''}
                    </div>
                    </Card>
                    {
                        !this.state.loading ? <div className="stocklist">
                        {
                            this.state.exchangeList.map((item, index) => {
                                return <SingleGoodComponent  percent={this.getPercentDiscount()} selftcostdiscount={(this.context.userdiscount || []).filter(i => i.DISCOUNT == "selfcost").length > 0} blockMinus={item.reqstatus === 'done' || item.reqstatus === 'admindone'} fromStock={false} fromExchange={true} tradingday={this.props.computedMatch.params.day} setLoad={() => this.setState({loading: true})} onDelete={() => this.filterAction(false)} data={{...item, count: 1}} type="exchange" key={index}/>                                
                            })
                        }
                        </div> : <Loading mini={true} />
                    }
                    {!this.state.loading && <Pagination pageSizeOptions={[10,25,50]} onChange={this.onChange} onShowSizeChange={this.onChange} showSizeChanger={true} current={this.state.currentPage} pageSize={this.state.pageSize} defaultCurrent={1} total={this.state.totalSize} />}
                </Card>
            ]
        
    }
}
Exchange.contextType = AppContext;
export default withRouter(Exchange);
var axios = require("axios");

function objectToParams(obj) {
  let str = "";
  str = Object.keys(obj)
    .map(function (key) {
      return key + "=" + obj[key];
    })
    .join("&");
  return str;
}

//const resturl = "http://192.168.1.10:3333"
//const resturl = "http://api.bunch-webshop.ru"
//const resturl = "https://flowers.mowins.ru:3005";
//const resturl = "http://localhost:3005"
let resturl = ""
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  resturl = "http://localhost:3005";
  //resturl = "https://api.bunch-webshop.ru";
} else {
  resturl = "https://api.buketnaya.store";
  //resturl = "https://apitest1.naturalcode.ru";
}

//axios.defaults.baseURL = "http://192.168.1.15:3005";
axios.defaults.baseURL = resturl;

const rest = {
  getavatarurl: (id,img) => {
    return `${resturl}/files/avatars/${id}/${img}`
  },
  uploadavatarurl: (id) => {
    return `${resturl}/files/avatars/${id}`
  },
  getfileurl: (path) => {
    return `${resturl}${path}`
  },
  get: (path, params = {}) => {
    let pars = `?${objectToParams(params)}`;
    return axios
      .get(`${path}${pars.length>2 ? pars : ''}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          c_token : localStorage.getItem("c_token")
        },
      })
      .then((data) => {
        return new Promise((resolve, reject) => {
          resolve(data);
        });
      })
      .catch((err) => {
        rest.errorHandler(err);
        return new Promise((resolve, reject) => {
          reject(err);
        });
      });
  },
  post: (path, params) => {
    
    return axios
      .post(path , params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => {
        return new Promise((resolve, reject) => {
          resolve(data);
        });
      })
      .catch((err) => {
        rest.errorHandler(err);
        return new Promise((resolve, reject) => {
          reject(err);
        });
      });
  },
  update: (path, params) => {
    return axios
      .put(path, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => {
        return new Promise((resolve, reject) => {
          resolve(data);
        });
      })
      .catch((err) => {
        rest.errorHandler(err);
        return new Promise((resolve, reject) => {
          reject(err);
        });
      });
  },
  delete: (path, params = {}) => {
    return axios
      .delete(`${path}?${objectToParams(params)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => {
        return new Promise((resolve, reject) => {
          resolve(data);
        });
      })
      .catch((err) => {
        rest.errorHandler(err);
        return new Promise((resolve, reject) => {
          reject(err);
        });
      });
  },
  errorHandler(error, msg) {
    if (error.response.status === 401 && window.location.pathname !== '/login') {
      window.location.reload();
    }

    if (error.response.data.error) {
      //message.error(msg)
      return;
    }

    // if(error.response.data.error || error.response.data.data.error){
    //     message.error(error.response.data.detail || error.response.data.data.detail)
    // }
  },
};

export default rest;

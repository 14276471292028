


import React from 'react';
import '../../App.scss';
import { Card, Form, Spin, Input, InputNumber, Row, Col, message } from 'antd';
import Breadcrumb from '../common/breadcrumb'
import AppContext from '../../components/common/appContext';
import { PageHeader, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import rest from '../../helpers/rest';
import StockEditForm from "../../components/stock/editstocksform";

const {TextArea} = Input;


class StockEditComponent extends React.Component{
    constructor(props){
        super(props);                  
        this.state = {loading: true, data: {}, editloading: false}    
        this.breadcrumbs = [
            { title: "Букетная.ОПТ", key: "/" },
            { title: "Склад", key: "/stock/" },
          ]
          this.childForm = React.createRef();
    }


    componentWillMount(){      
      rest.get('/api/Stock/single', {id: this.props.match.params.id}).then(data => {
        
        this.setState({data: data.data.data, loading: false})
      })
    }

    submit(data){
      
      let params = data;
      params.id = this.state.data.id;
      this.childForm.setLoad(true);
      this.setState({editloading: true})
      rest.update('/api/Stock/', params).then(data => {
        
        this.setState({editloading: false})
        this.childForm.setLoad(false);
        message.success('Позиция склада изменена')
        this.props.history.push('/stock')
      })
    }

    

    

    render(){        
        return [
            <Breadcrumb
            key={1}
            style={{ margin: "16px 0" }}
            list={this.breadcrumbs}
          />,
          <Row key={2}>
          <Col span={24}>
          <Card className="content-card">
          <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Редактирование позиции склада"
              
            />
           
            {
              !this.state.loading && 
              <div className="editformstock">
              <StockEditForm onSuccessSubmit={(data) => this.submit(data)} onRef={(ref) => (this.childForm = ref)} data={this.state.data} catalogid={this.state.data.catalogid} />
              <Spin spinning={this.state.editloading}><Button onClick={() => this.childForm.submitForm()} type="primary">Сохранить</Button></Spin>
              </div>
            }
            
           
            </Card>
              </Col>
              </Row>
            ]
        
    }
}

StockEditComponent.contextType = AppContext;

export default withRouter(StockEditComponent);
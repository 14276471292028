import * as React from 'react';
import { Breadcrumb, Skeleton } from 'antd';
import { NavLink } from 'react-router-dom';

class BreadcrumbsV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}        
    }

    
    

    render() {        
        const list = this.props.list || []
        return [
            <div className="breadcrumbs__v2" key={1}>   
                <ul>
                    {
                        list.map((i,index) => {
                            if(!i.path || index == list.length -1){
                                return <li key={index}>
                                    <span>{i.name}</span>
                                </li>            
                            }else{
                                return <li key={index}>
                                    <NavLink to={i.path}>{i.name}</NavLink>
                                </li>
                            }
                        })
                    }                    
                </ul>
                <div className='line'></div>
                {this.props.children}             
            </div>
            
        ]
    }
}

export default BreadcrumbsV2;

import React from "react";
import { Card, Row, Col, List, Modal, Button, Form, Input, message, InputNumber } from "antd";
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class OrganizationEdit extends React.Component {
    constructor(props){
        super(props);
        this.formRef = React.createRef();
    }

    onSubmit(){
        
    }

    render(){
        return[
           <div className="laweditform">
            <Form.Item
            key={2}
              label="ИНН"
              name="INN"
              rules={[
                { required: true, message: "Пожалуйста введите ИНН организации" },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
            key={1}
              label="БИК"
              name="BIK"              
            >
              <InputNumber />
            </Form.Item>
            </div>
            ]
    }

    
}

export default OrganizationEdit
import * as React from "react";
import { Select, Form, Modal, Spin, Button, Upload, message } from "antd";
import rest from "../../../helpers/rest";
import { FileOutlined, DeleteOutlined, InboxOutlined } from "@ant-design/icons";
const { Dragger } = Upload;
// const uploadprops = {
//   name: "file",
//   multiple: true,
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   onChange(info) {
//     const { status } = info.file;
//     if (status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   onDrop(e) {
//     console.log("Dropped files", e.dataTransfer.files);
//   },
// };

class TransactionFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      open: this.props.open,
      files: [],
      loading: true,
      uploadprops: {}
    };
    this.getTransactionFiles = this.getTransactionFiles.bind(this);
    this.openFile = this.openFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      { id: nextProps.id, open: nextProps.open, files: [], loading: true, uploadprops: {
        name: "file",
        multiple: false,
        action: rest.getfileurl(`/files/transactions/${nextProps.id}`),
        showUploadList: false,
        onChange: (info) => {
          const { status } = info.file;
          this.setState({loading: true})
          if (status !== "uploading") {            
            this.setState({loading: true})
          }
          if (status === "done") {
            message.success(`${info.file.name} file uploaded successfully.`);            
            this.getTransactionFiles();
          } else if (status === "error") {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          
        },
      } },
      () => {
        this.getTransactionFiles();
      }
    );
  }

  componentDidMount() {}


  deleteFile(id){
    this.setState({loading: true})
    rest.delete('/api/Transactions/deletefile/', {id:id}).then(data => {      
      message.success('Файл удален');
      this.getTransactionFiles();
    })
  }

  getTransactionFiles() {
    this.setState({loading: true});
    if (!this.state.open || this.state.id == 0) return;
    rest.get("/api/Transactions/files/", { id: this.state.id }).then((data) => {
      //console.log(data.data.data);
      this.setState({ files: data.data.data, loading: false });
    });
  }

  openFile(path) {
    window.open(rest.getfileurl(path), "_blank");
  }

  render() {
    return (
      <Modal
        title={`Вложения транзакции № ${this.state.id}`}
        visible={this.state.open}
        onOk={() => {
          this.props.close();
        }}
        onCancel={() => {
          this.props.close();
        }}
        footer={[
          <Button key="back" onClick={() => this.props.close()}>
            Закрыть
          </Button>,
        ]}
      >
        <Spin spinning={this.state.loading}>
          {this.state.files.map((item, i) => {
            return (
              <div className="fileitem" key={i}>
                <a
                  className="downloadlink"
                  onClick={() => this.openFile(item.path)}
                >
                  <div className="icon">
                    <FileOutlined />
                  </div>
                  <span>Вложение №{item.id}</span>
                </a>
                <a onClick={() => {this.deleteFile(item.id)}}>
                  <DeleteOutlined />
                </a>
              </div>
            );
          })}
          <Dragger {...this.state.uploadprops} style={{marginTop: '15px'}}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Нажмите или перенесите файлы для загрузки
            </p>
            <p className="ant-upload-hint">
              Поддерживается только одиночная загрузка файлов
            </p>
          </Dragger>
        </Spin>
      </Modal>
    );
  }
}

export default TransactionFiles;

import React from "react";
import "../../../../App.scss";
import {
  Spin,
  message,
  Popconfirm,
  Radio,
  Form
} from "antd";
import { Button, Input, Modal } from "antd";
import { withRouter } from "react-router-dom";
import rest from "../../../../helpers/rest";
import AppHelper from "../../../../helpers/app";
import * as moment from "moment";
import "moment/locale/ru";
import AppContext from "../../../common/appContext";
import { EditOutlined,DeleteOutlined } from "@ant-design/icons";
moment.locale("ru");

class PreordersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      positions: [],
      preorder: null,
      isModalVisible: false,
      setIsModalVisible: false,
      preorderComment: '',
      disabled:''
    };
    this.preorderFormArea = React.createRef();
    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
  }

  componentWillMount() {
    this.getPreorder();
   
  }

  getPreorder = () => {
    this.setState({ loading: true });
    rest
      .get("/api/Requests/getpreorder", { id: this.props.id })
      .then((data) => {
        this.setState({ loading: false, positions: data.data.data.positions, preorder: data.data.data });
        console.log(data.data.data.positions);
      });
  };

  showModal ()  {
    console.log(this.state.preorder);
    this.setState({
      isModalVisible:true
    })
    if(this.state.comment !== ''){
      this.setState(
        { disabled: false })
    }else{
      this.setState(
        { disabled: true })
    }
  };

  handleOk ()  {
    let params = {};
    params.comment = this.state.preorderComment;
    params.idreq = this.state.preorder.ID;
    if(this.state.preorderComment !== ''){
      rest.post('/api/Requests/addcomment', params).then(data => {
        this.setState({isModalVisible:false})
        this.getPreorder()
      })
    }else{
      this.handleCancel();
    }
  };

  handleCancel ()  {
    this.setState({
      isModalVisible:false
    })
  };

  onChangeComment(e) {
    console.log(e.target.value)
    this.setState({preorderComment: e.target.value});
    if(e.target.value !== ''){
      this.setState({disabled: false});
    } else{
      this.setState({disabled: true});
    }
  }

  deleteComment() {
    let params = {};
    params.idreq = this.state.preorder.ID;
      rest.post('/api/Requests/deletecomment', params).then(data => {
        this.getPreorder()
        message.success('Комментарий удален');
      })
  }


  onChange = (e, id) => {
    console.log(e.target.value, id)
    let params = {};
    this.setState({ status: e.target.value }, () => {
      params.id = id;
      params.status = this.state.status;
      rest.post('/api/Requests/updatestatuspreorder', params).then(data => {
        this.getPreorder()
      })
    });
    console.log(e.target.value, id)
  };

  render() {
    return [
      <Spin key={1} spinning={this.state.loading}>
        {this.state.preorder && <div className="preorder__info">
          <h2>Предзаказ на  <span>{moment(this.state.preorder.TODATE || new Date()).format('DD MMMM YYYY')}</span></h2>
          <h3 className="client__fio">{this.state.preorder.fname} {this.state.preorder.sname}</h3>
          {this.state.preorder.phone && <a href={'tel:'+ this.state.preorder.phone} className="client__phone">+7{AppHelper.formatUsPhone(String(this.state.preorder.phone).substring(1,11))}</a>}
        </div>}
        <div className="preorder__positions">
          {this.state.positions.map((i) => (
            
            <div key={i.id} className="preorder__positions_item">
              <div
                className="position__image"
                style={{background: `url(${rest.getavatarurl(i.id, i.image)})`}}
              ></div>
              <div className="position__info">
                  {/* <p className="name">{i.name}</p>
                  <p className="quality"><b>Количество:</b>{i.quality}</p> */}
                  <div className="params">
                  <div className="param">
                        <span className="label">Название:</span>
                        <span className="value">{i.name}</span>
                      </div>
                      <div className="param">
                        <span className="label">Количество:</span>
                        <span className="value">{i.quality}</span>
                      </div>                      
                      {i.growth && <div className="param">
                        <span className="label">Рост:</span>
                        <span className="value">{i.growth}</span>
                      </div>}
                      <div className="param">
                        <span className="label">Максимальная цена:</span>
                        <span className="value">{AppHelper.toCurrency(i.MAXPRICE, "RUB", "Ru-ru")}</span>
                      </div>
                      {i.TOCHANGE && <div className="param">
                        <span className="label">Замена:</span>
                        <span className="value">{i.TOCHANGE}</span>
                      </div>}
                      {i.ACOMMENT && <div className="param">
                        <span className="label">Комментарий администратора:</span>
                        <span className="value">{i.ACOMMENT}</span>
                      </div>}
                      {i.UCOMMENT && <div className="param">
                        <span className="label">Комментарий пользователя:</span>
                        <span className="value">{i.UCOMMENT}</span>
                      </div>}
                      {this.context.usertype != 'user' ? 
                      <Form
                      name="radio_date"
                      ref={this.formRef2}
                      className="dashfastactions"
                      layout="vertical"
                      initialValues={{radio: i.STATUS}}
                    >
                      <Form.Item name="radio">
                        <Radio.Group
                          optionType="button"
                    
                          onChange={(e) => this.onChange(e,i.IDpos)}
                          value={i.STATUS}
                        >
                          <Radio.Button value={1} checked="false">
                            Заказан
                          </Radio.Button>
                          <Radio.Button value={2} checked="false">
                            Заказана замена
                          </Radio.Button>
                          <Radio.Button value={3} checked="false">
                            Не заказан
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Form>
                     : ""}
                      
                  </div>
              </div>
            </div>
          ))}
        </div>
        {this.state.preorder && this.context.usertype != 'user' ? 
        this.state.preorder.comment ? 
          <div> 
            <h3>Комментарий {" "}
              <EditOutlined
                style={{ color: "#1890ff" }}
                onClick={this.showModal}
              />
              <Popconfirm
                title={`Удалить комментарий?`}
                okText="Да"
                cancelText="Нет"
                onConfirm={() => this.deleteComment()}
              >
                <a style={{ color: "red", marginLeft: '10px' }}>
                  <DeleteOutlined />
                </a>
              </Popconfirm>
            </h3>  
            <p>{this.state.preorder.comment}</p>  
          </div> : 
          <Button type="primary" onClick={this.showModal}>Добавить комментарий</Button> : ""
        }
      <Modal 
        title="Редактирование комментария" 
        visible={this.state.isModalVisible} 
        okButtonProps={{ disabled: this.state.disabled }}
        onOk={this.handleOk} 
        onCancel={this.handleCancel} 
        centered={true} 
        okText="Сохранить" 
        cancelText="Отмена">
        <Input 
          placeholder="Введите комментарий" 
          onChange={(value) => this.onChangeComment(value)} 
          defaultValue={this.state.preorder ? this.state.preorder.comment : '' }
          />
      </Modal>
      </Spin>,
    ];
  }
}

PreordersView.contextType = AppContext;

export default withRouter(PreordersView);

import React from "react";
import "../../App.scss";
import { Spin, Pagination, Modal, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  Input,
  Collapse,
  Tag,
  Menu,
  Dropdown,
  Select,
  Switch,
  message,
} from "antd";
import { Alert } from 'antd';
import AppContext from "../common/appContext";
import { PageHeader } from "antd";
import rest from "../../helpers/rest";
import { withRouter } from "react-router-dom";
import AppHelper from "../../helpers/app";
import {DollarCircleOutlined} from '@ant-design/icons';
import SingleRequestList from "./singlerequestlist";
import SortSelect from "../common/sortselect";
import UserCart from "../user-cart";
import * as moment from "moment";
import "moment/locale/ru";
import PayTypeMulti from "./multipaytype/mpaytype";
moment.locale("ru");


const { Option } = Select;
const { Panel } = Collapse;

class RequestsComponentForAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Пользовательские заявки", key: "/requests/" },
    ];

    this.state = {
      tofiscaleid: null, 
      tofiscalertype: null,
      fiscalemodal: false,
      cataspan: 24,
      requests: [],
      tradingdayslist: [],
      loading: true,
      tradingload: true,
      filterParams: {},
      statusFilter: true,
      // По сколько отображать на странице
      pageSize: 10,
      totalSize: 0,
      currentPage: 1,
      toStatusChange: { reqid: null },
      changeStatusModalVisible: false,
      returnMoney: false,
      changeStatusId: null,
      returnMoneyVisible: false,
      returnMoneyId: null,
      returnMoneyLoad: false,
      // ID зазявки для списания средств в долг
      debtRequestId: null,
      // Статус заявки для списания средств в долг
      debtRequestStatus: "issued",
      debtRequestCurrentStatus: "",
      debtThrowBalanceVisible: false,
      fiscalload: false,
      
    };
    document.title = "Пользовательские заявки | Букетная.ОПТ";
    this.getRequests = this.getRequests.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.setFiscaleModal = this.setFiscaleModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.filterAction = this.filterAction.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.formFilterRef = React.createRef();
    this.formStatusRef = React.createRef();
    this.payMethodsRef = React.createRef();
    this.returnMoneyForRequest = this.returnMoneyForRequest.bind(this);
    this.writeOffMoney = this.writeOffMoney.bind(this);
    this.renderRequestAction = this.renderRequestAction.bind(this);
    this.fiscalize = this.fiscalize.bind(this);
  }

  changeStatusAction() {
    this.setState({ changeStatusModalVisible: false });
    this.changeStatus(
      this.state.toStatusChange.reqid,
      this.formStatusRef.current.getFieldsValue().status
    );
    this.formStatusRef.current.setFieldsValue({
      status: null,
    });
  }

  filterAction(pageSkip = true) {
    let formdata = this.formFilterRef.current.getFieldsValue();

    let params = {};
    Object.keys(formdata).map((item) => {
      if (formdata[item] !== undefined) {
        if (typeof formdata[item] === "Array" && formdata[item].length > 0) {
          params[item] = formdata[item].join(",");
        } else {
          params[item] = formdata[item];
        }
      }
    });
    if (pageSkip) {
      this.setState({ filterParams: params, currentPage: 1 }, () => {
        this.getRequests();
        localStorage.setItem('fromuserspage_listfilter', JSON.stringify(params));
      });
    } else {
      this.setState({ filterParams: params }, () => {
        this.getRequests();
        localStorage.setItem('fromuserspage_listfilter', JSON.stringify(params));
      });
    }
  }

  statusList(pos) {
    let filtered = [];
    let menues = [
      {
        code: "active",
        inner: (
          <Menu.Item
            key={0}
            onClick={() => this.changeStatus(pos.id, "active", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("active").color}>
              {AppHelper.getRequestStatus("active").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "done",
        inner: (
          <Menu.Item
            key={1}
            onClick={() => this.changeStatus(pos.id, "done", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("done").color}>
              {AppHelper.getRequestStatus("done").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "admindone",
        inner: (
          <Menu.Item
            key={2}
            onClick={() => this.changeStatus(pos.id, "admindone", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("admindone").color}>
              {AppHelper.getRequestStatus("admindone").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "collected",
        inner: (
          <Menu.Item
            key={3}
            onClick={() => this.changeStatus(pos.id, "collected", pos.status)}
          >
            <Tag
              color={AppHelper.getRequestStatus("collected", pos.status).color}
            >
              {AppHelper.getRequestStatus("collected").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "issued",
        inner: (
          <Menu.Item
            key={4}
            onClick={() => this.changeStatus(pos.id, "issued", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("issued").color}>
              {AppHelper.getRequestStatus("issued").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "cancel",
        inner: (
          <Menu.Item
            key={5}
            onClick={() => this.changeStatus(pos.id, "cancel", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("cancel").color}>
              {AppHelper.getRequestStatus("cancel").label}
            </Tag>
          </Menu.Item>
        ),
      },
    ];
    let access_statusses = [
      { status: "active", access: ["done", "cancel"] },
      {
        status: "done",
        access: ["active", "cancel", "admindone", "issued", "collected"],
      },
      {
        status: "admindone",
        access: ["active", "issued", "done", "cancel", "collected"],
      },
      {
        status: "collected",
        access: ["cancel", "active", "admindone", "issued"],
      },
      {
        status: "issued",
        access: ["active", "cancel", "admindone", "done", "collected"],
      },
      { status: "cancel", access: [] },
    ];
    filtered = menues
      .filter((elem) => elem.code !== pos.status)
      .filter((i) =>
        access_statusses
          .filter((as) => as.status == pos.status)[0]
          .access.includes(i.code)
      );
    if (pos.status === "cancel") {
      filtered = menues.filter((elem) => elem.code === "cancel");
    }
    return (
      <Menu>
        {filtered.map((item, index) => {
          return item.inner
        })}
      </Menu>
    );
  }

  changeStatus(id, status, currentstatus, throwbalance = false) {
    this.setState({ debtThrowBalanceVisible: false });
    // if(currentstatus == 'issued'){
    //   this.setState({returnMoneyVisible: true, returnMoneyId: id})
    // }
    this.setState({ loading: true });
    rest
      .update("/api/Requests/changestatus", {
        requestid: id,
        status: status,
        throwbalance: throwbalance,
      })
      .then((data) => {
        this.getRequests();
      })
      .catch((err) => {
        message.error(err.response.data.data.detail);
        this.setState({ loading: false });
        let reserr = err.response.data.data;
        if (reserr.type && reserr.type == "nonpayed") {
          this.setState({
            debtRequestId: id,
            debtRequestStatus: "issued",
            debtThrowBalanceVisible: true,
            debtRequestCurrentStatus: currentstatus,
          });
        }
      });
  }

  writeOffMoney(reqid, throwbalance) {
    this.setState({ loading: true, debtThrowBalanceVisible: false });
    if (this.state.loading) return;
    rest
      .update("/api/Requests/writeoff", {
        id: reqid,
        throwbalance: throwbalance,
      })
      .then((data) => {
        this.getRequests();
      })
      .catch((err) => {
        message.error(err.response.data.data.detail);
        this.setState({ loading: false });
        let reserr = err.response.data.data;
        if (reserr.type && reserr.type == "nonpayed") {
          this.setState({
            debtRequestId: reqid,
            debtRequestStatus: "issued",
            debtThrowBalanceVisible: true,
          });
        }
      });
  }

  getRequestStatus(item, index) {
    let result = "";
    if (item.status === "active") {
      result = (
        <Tag key={index} color="green">
          Активная
        </Tag>
      );
    } else {
      result = (
        <Tag key={index} color="orange">
          Неизвестный статус
        </Tag>
      );
    }
    return result;
  }

  componentWillReceiveProps() {
    if(this.state.statusFilter == true){
      if(this.props.history.action == "POP") {
        let filter = JSON.parse(localStorage.getItem('fromuserspage_listfilter'));
        this.setState({
          filterParams: filter,
          currentPage: filter.page,
          statusFilter: false
        }, () => {
          this.formFilterRef.current.setFieldsValue({
            tradingday: this.state.filterParams.tradingday,
            requestid: this.state.filterParams.requestid,
            status: this.state.filterParams.status,
            forstock: this.state.filterParams.forstock
          });
          this.getRequests();
        })
        
      }else{
        this.getRequests();
        this.setState({
          statusFilter: false
        })
      }
    }
  }

  componentWillMount() {
    rest.get("/api/Trading/").then((data) => {
      let tradingtostate = [];
      data.data.data.map((item, index) => {
        tradingtostate.push({
          name: moment(item.date).format("DD MMMM YYYY"),
          code: item.id,
        });
        //this.setState({tradingdayslist: tradingtostate})
      });

      this.setState(
        { tradingdayslist: tradingtostate, tradingload: false },
        () => {}
      );
    });
  }

  returnMoneyForRequest() {
    this.setState({ returnMoneyLoad: true });
    rest
      .post("/api/Requests/returnmoneyforrequest", {
        id: this.state.returnMoneyId,
      })
      .then((e) => {
        message.success("Средства возвращены");
        this.setState({ returnMoneyLoad: false, returnMoneyVisible: false });
        this.getRequests();
      })
      .catch((e) => {
        message.error(e.response.data.data.detail);
        this.setState({ returnMoneyLoad: false, returnMoneyVisible: false });
      });
  }

  getRequests() {
    this.setState({ loading: true, totalSize: 0, requests: [] });
    let params = this.state.filterParams;
    params.page = this.state.currentPage;
    params.pagesize = this.state.pageSize;

    rest.get("/api/Requests/all", params).then((data) => {
      this.setState({
        totalSize: data.data.data.count,
        requests: data.data.data.list,
        loading: false,
      });
      console.log(data.data.data.list);
    });
  }

  onChange(page, size) {
    
    this.setState({ currentPage: page, pageSize: size });
    this.filterAction(false);
  }

  resetFilter() {
    this.setState({ filterParams: {} });
    this.formFilterRef.current.resetFields();
    this.filterAction();
  }

  generatePackageList(id, pdf = true){
    this.context.setLoad(true, 'Генерация накладной')
    rest.get('/api/Requests/generatepackinglist', {id:id, front: true}).then(data => {
      this.context.setLoad(false)
      if(pdf){
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${rest.getfileurl(data.data.data)}`, '_blank');
      }else{
        window.open(rest.getfileurl(data.data.data), '_blank');
      }
      
    }).catch(e => {
      this.context.setLoad(false)
    })
  }

  async fiscalize(id, type){
    if(this.state.fiscalload){
      message.info('Дождитесь загрузки')
      return;
    }

    let paytype = await this.payMethodsRef.current.validPayForms();
    if(!paytype){
      message.error('Выберите тип оплаты');
      return;
    }
    let formsumm = await this.payMethodsRef.current.getFormSumm();
    if(formsumm != this.state.toFiscaleSum){
      message.error('Сумма заявки не может отличаться от суммы оплаты')
      return;
    }
    
    this.setState({loading: true, fiscalemodal: false, fiscalload: true})
    
    rest.post('/api/Requests/addtofiscale', {id:id, fiscaletype: type || 1, paytype: paytype}).then(data => {
      message.success('Чек отправлен на фискализацию')
      this.setState({loading: false});
      this.filterAction();
    })
  }

  renderRequestAction(item) {
    let menu = [];    

    // Если заявка не оплачена и подходит статус
    let statusestowriteoff = ["done","admindone","collected","issued"];
    if(!item.paid && statusestowriteoff.includes(item.status)){
      menu.push(<Menu.Item key={1}><a onClick={() => this.writeOffMoney(item.id)} >Списать средства</a></Menu.Item>)  
    };

    // Если заявка оплачена
    let statusestowriteback = ["done", "admindone", "collected", "issued"];
    if(item.paid && statusestowriteoff.includes(item.status)){
      menu.push(<Menu.Item key={2}><a onClick={() => this.setState({returnMoneyVisible: true,returnMoneyId: item.id,})} >Вернуть средства</a></Menu.Item>)  
    };

    // Если заявка в итоговом статусе, даем возможность сформировать накладную
    if(["done", "admindone", "collected", "issued"].includes(item.status)){
      menu.push(<Menu.Item key={3}><a onClick={() => this.generatePackageList(item.id, false)} >Товарная накладная - скачать</a></Menu.Item>)  
      menu.push(<Menu.Item key={4}><a onClick={() => this.generatePackageList(item.id, true)} >Товарная накладная - открыть</a></Menu.Item>)  
    }

    // Если заявка выдана - показываем кнопку для фискализации чека
    if(["issued"].includes(item.status) && !item.is_fiscal){
      menu.push(<Menu.Item key={5}><a onClick={() => this.setFiscaleModal(item.id, item.type)} >Фискализировать чек</a></Menu.Item>)        
      
    }



    
    return (<Menu>{menu}</Menu>);
    
  }

  setFiscaleModal(id, type){
    this.setState({tofiscaleid: id, fiscalemodal: true, tofiscalertype: type, fiscalload: true})
    rest.get('/api/Requests/total', {type: type, requestid: id}).then(data => {

      let fiscalesum = data.data.data.total;
      if(data.data.data.discount){
        data.data.data.discount.map(i => {
          fiscalesum -= i.sum
        })
      }
      this.setState({toFiscaleSum: fiscalesum, fiscalload: false});
    })
  }

  showProfileUser = (id) => {
    this.context.openDrawer(
      `Просмотр профиля`,
      <UserCart
        userid={id}
        update={() => {
          this.setState({ totalvisible: false });
          setTimeout(() => {
          this.setState({ totalvisible: true });
        }, 3);
        }}
      />
    );
  };

  render() {
    return [
      <Modal
        key={101}
        title="Выдать заказ в долг ?"
        onOk={() => this.writeOffMoney(this.state.debtRequestId, true)}
        onCancel={() => this.setState({ debtThrowBalanceVisible: false })}
        visible={this.state.debtThrowBalanceVisible}
      >
        {/* onOk={handleOk} onCancel={handleCancel} */}

        <p>Выдать заказ №{this.state.debtRequestId} в долг?</p>
      </Modal>,
      <Modal
        key={100}
        title="Подтверждение"
        onOk={() => this.returnMoneyForRequest()}
        onCancel={() => this.setState({ returnMoneyVisible: false })}
        visible={this.state.returnMoneyVisible}
      >
        {/* onOk={handleOk} onCancel={handleCancel} */}
        <Spin spinning={this.state.returnMoneyLoad}>
          <p>Вернуть средства на баланс пользователя ?</p>
        </Spin>
      </Modal>,
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card">
        <Row key={1}>
          <Col span={this.state.cataspan}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Пользовательские заявки"
            />
          </Col>
        </Row>
        <Row key={2}>
          <Col span={24}>
            <div className="usersrequestfilter">
              <Card className="content-card">
                <Form
                  name="filter"
                  layout="vertical"
                  ref={this.formFilterRef}
                  initialValues={{ remember: true }}
                  onFinish={() => this.filterAction()}
                >
                  <Form.Item name="requestid" label="Номер заявки">
                    <Input />
                  </Form.Item>

                  <Form.Item>
                    <SortSelect
                      hidePrice={false}
                      label="Статус"
                      placeholdertext="Статус"
                      name="status"
                      options={[
                        { name: "Активная", code: "active" },
                        { name: "Подтвержденная", code: "done" },
                        { name: "Отменена", code: "cancel" },
                        { name: "Выдан", code: "issued" },
                        { name: "Собран", code: "collected" },
                      ]}
                      hideGrowth={false}
                      sort={() => null}
                    />
                    {!this.state.tradingload && (
                      <SortSelect
                        name="tradingday"
                        hidePrice={false}
                        label="Торговый день"
                        placeholdertext="Торговый день"
                        options={this.state.tradingdayslist}
                        hideGrowth={false}
                        sort={() => null}
                      />
                    )}
                    <Form.Item
                      name="forstock"
                      label="Со склада"
                      valuePropName="checked"
                      className="miniswitch"
                    >
                      <Switch />
                    </Form.Item>

                    <Button className="filterbtn" onClick={this.resetFilter}>
                      Сбросить фильтр
                    </Button>
                    <Button className="filterbtn" htmlType="submit">
                      Фильтровать
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </Col>
          <Col span={24}>
            <Spin spinning={this.state.loading}>
              <div className="requests_wrapper">
                {this.state.requests.length === 0 && !this.state.loading && (
                  <h3>Заявки не найдены</h3>
                )}
                <Collapse key={1} accordion>
                  {this.state.requests.map((item, index) => {
                    return (
                      <Panel
                        key={item.id}
                        header={[
                          `Заявка ${item.id} | `,<span key={item.id + 1}  className="req__fio" onClick={() => this.showProfileUser(item.userid)}>
                            {item.sname} {item.fname}</span>, ` - ${item.orgName || ""} - ${item.email} `,
                          item.phone.length > 0 ? (
                            <a key={item.id} href={`tel:+${item.phone}`}>
                              +{item.phone}
                            </a>
                          ) : (
                            ""
                          ),
                        ]}
                        extra={[
                          item.tddate && (
                            <Tag key={0}>
                              {"Торговый день: " +
                                moment(item.tddate).format("DD.MM.YYYY")}
                            </Tag>
                          ),


                          <Dropdown key={1} overlay={this.statusList(item)}>
                            <Tag
                              onClick={() => {
                                this.setState({
                                  toStatusChange: {
                                    reqid: item.id,
                                    status: item.status,
                                  },
                                  changeStatusModalVisible: true,
                                });
                              }}
                              key={0}
                              color={
                                AppHelper.getRequestStatus(item.status).color
                              }
                            >
                              {AppHelper.getRequestStatus(item.status).label}
                            </Tag>
                          </Dropdown>,

                          
                          <Tag key={2}>
                            {moment(item.created).format("DD.MM.YYYY")}
                          </Tag>,
                          <Tag key={3}>
                            {item.type === "stock" ? "Со склада" : "С биржи"}
                          </Tag>,                                                    
                          <Dropdown key={4} overlay={this.renderRequestAction(item)} placement="bottomLeft" arrow>
                            <Button>Действия</Button>
                          </Dropdown>,
                          <div className="requestpaidinfo" key={5}>
                            {item.paid ? <DollarCircleOutlined style={{color: 'green'}} /> : <DollarCircleOutlined style={{color: 'red'}} />}
                          </div>,
                          <div className="fiscalinfo" key={6}>
                            {item.is_fiscal ? <Tag >Фискализирован</Tag> : ''}
                          </div>,
                          <div key={7}>
                            {item.PERCENT_DISCOUNT ? <Tag color="lime">Скидка {item.PERCENT_DISCOUNT}%</Tag> : ""}
                          </div>,
                          <div key={8}>
                            {item.delivery ? item.delivery == 1 ? 
                            <Tooltip title={item.address}>
                              <div className="requests__icon-delivery"></div>
                            </Tooltip> : item.delivery == 2 ? 
                            <Tooltip title={item.address}>
                              <div className="requests__icon-taxi"></div>
                            </Tooltip> : '' :''}
                          </div>
                        
                        ]}
                        key={item.id}
                      >
                        <SingleRequestList
                        key={9023844}
                          updateRequests={() => this.getRequests()}
                          data={item}
                        />
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
              <div className="m10">
                {!this.state.loading && (
                  <Pagination
                    pageSizeOptions={[5, 10, 25, 50]}
                    onChange={this.onChange}
                    onShowSizeChange={this.onChange}
                    showSizeChanger={true}
                    current={this.state.currentPage}
                    pageSize={this.state.pageSize}
                    defaultCurrent={1}
                    total={this.state.totalSize}
                  />
                )}
              </div>
            </Spin>
          </Col>
        </Row>
      </Card>,
      <Modal
        key={3}
        title={`Смена статуса для заявки №${this.state.toStatusChange.reqid}`}
        onOk={() => this.formStatusRef.current.submit()}
        onCancel={() => this.setState({ changeStatusModalVisible: false })}
        visible={this.state.changeStatusModalVisible}
      >
        <Form
          name="filter"
          layout="vertical"
          ref={this.formStatusRef}
          onFinish={() => this.changeStatusAction()}
        >
          <Form.Item
            name="status"
            label="Новый статус"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите значение",
              },
            ]}
          >
            <Select style={{ width: "100%" }} placeholder="Выберите статус">
              {AppHelper.statusList(this.state.toStatusChange.status, true).map(
                (item, i) => {
                  return (
                    <Option
                      label={item.name}
                      value={item.name}
                      key={i.toString(36) + i}
                    >
                      {item.label}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>,
       <Modal
       visible={this.state.fiscalemodal}
       title="Фискализация чека"
       key={44543}
       onOk={this.handleOk}
       onCancel={() => this.setState({fiscalemodal: false, tofiscaleid: null})}
       footer={[          
        <Button key="back" type="primary" onClick={() => this.fiscalize(this.state.tofiscaleid, 1)}>Фискализировать</Button>,
        // <Button key="back" type="primary" onClick={() => this.fiscalize(this.state.tofiscaleid, 2)}>По карте</Button>,
          <Button key="back" onClick={() => this.setState({fiscalemodal: false, tofiscaleid: null})}>Отмена</Button>
       ]}
     > 
     <Spin spinning={this.state.fiscalload}>
       <PayTypeMulti ref={this.payMethodsRef} total={this.state.toFiscaleSum} />
       {/* <p>Выберите тип оплаты для фискализации</p>       
       <Alert message="Внимание! Оплата переводом на карту не фискализируется" type="warning" /> */}
       </Spin>
     </Modal>
    ];
  }
}

RequestsComponentForAdmin.contextType = AppContext;

export default withRouter(RequestsComponentForAdmin);

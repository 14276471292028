import React from "react";
import "../../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import AppContext from "../../../components/common/appContext";
import {
  Spin,
  Card,
  Row,
  Col,
  Tooltip,
  Form,
  Input,
  Skeleton,
  Table,
  Tag,
  DatePicker,
  Space,
  message,
} from "antd";
import { PageHeader, Menu, Dropdown, Button, Tabs, Modal } from "antd";
import rest from "../../../helpers/rest";
import { PlusOutlined } from "@ant-design/icons";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../../helpers/app";
//import 'moment/locale/zh-cn';
import locale from "antd/es/date-picker/locale/ru_RU";
import { FileTextOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import TransactionFiles from "../../transactions/files/index";
import PreordersView from '../../requests/preorder/forview/index'
import { Select } from "antd";

moment.locale("ru");

const { Option } = Select;
const { RangePicker } = DatePicker;

class UPreorders extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {tableData: []};
    this.historycolumns = [{
        title: "№",
        dataIndex: "ID",
        key: "ID",
      },
      {
        title: "На дату",
        dataIndex: "TODATE",
        render: (text, record) => {
          if (moment(record.TODATE).isAfter()) {
            return (
              <Tag color="green">
                {moment(record.TODATE).format("DD-MM-YYYY")}
              </Tag>
            );
          } else {
            return (
              <Tag color="orange">
                {moment(record.TODATE).format("DD-MM-YYYY")}
              </Tag>
            );
          }
        },
      },
      {
        title: "Имя",
        dataIndex: "fname",
        render: (text, record) => {
          return `${record.fname} ${record.sname}`;
        },
      },
      {
        title: "Действия",
        render: (text, record) => (
          <div className="preorder__actions table">
            <EyeOutlined
              style={{ color: "#1890ff" }}
              onClick={() => {
                this.viewPreorder(record.ID);
              }}
            />
            <EditOutlined
              style={{ color: "#1890ff" }}
              onClick={() => {
                this.props.history.push(`/preorder/${record.ID}`);
              }}
            />
          </div>
        ),
      },
    ];
  }

  componentDidMount() {    
      this.getPreorders()    
  }

  viewPreorder = (id) => {
    this.context.openDrawer(
      `Просмотр предзаказа №${id}`,
      <PreordersView id={id} />
    );
  };

  onSubmit() {
      //console.log( this.formRefAdd.current.getFieldsValue())
      let params = {...this.formRefAdd.current.getFieldsValue(), ...{userid: this.props.userid}};
      this.setState({addLoading: true})
      rest.post("/Api/Account/touchespost",params).then(data => {          
          this.setState({addLoading: false, addModal: false});
          this.getTouches();
      })

  }

  getPreorders = (date = undefined) => {
    
    this.setState({ loading: true });    
    let params = {userid: this.props.userid};    
    rest.get("/api/Requests/getpreorders", params).then((data) => {
      this.setState({ tableData: data.data.data, loading: false });        
    });
  
};

  
  render() {
    return [     
      <Spin key={6} spinning={this.state.loading}>
      <Table dataSource={this.state.tableData} columns={this.historycolumns} />,
      </Spin>,
      ,
    ];
  }
}

UPreorders.contextType = AppContext;

export default withRouter(UPreorders);

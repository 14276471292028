import React from "react";
import "../../../App.scss";
import { message, Skeleton } from "antd";
import Breadcrumb from "../../common/breadcrumb";
import { Card, Row, Col, List, Modal, Button, Form, Input } from "antd";
import AppContext from "../../../components/common/appContext";
import { PageHeader, Popconfirm } from "antd";
import rest from "../../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../../loading";
import { withRouter } from "react-router-dom";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class CategoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Справочники", key: "/directory/" },
      { title: "Категории", key: "/category/" },
    ];
    this.formRef = React.createRef();
    this.formRefEdit = React.createRef();
    this.state = {
      cataspan: 24,
      categorylist: [1, 2],
      loading: true,
      addModalVisible: false,
      formAddLoader: false,
      editModalVisible: false,
      activeCat: {}
    };
    document.title = "Категории | Букетная.ОПТ";

    this.addModalShow = this.addModalShow.bind(this);
    this.closeAddModa = this.closeAddModa.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this)
    this.submitEditForm = this.submitEditForm.bind(this)
    this.onSubmit = this.onSubmit.bind(this);
    this.submitAddForm = this.submitAddForm.bind(this);
    this.getCatList = this.getCatList.bind(this);
    this.showEditCat = this.showEditCat.bind(this)
    this.closeEditModa = this.closeEditModa.bind(this)
  }

  componentWillMount() {
    this.getCatList();
  }

  getCatList() {
    this.setState({ loading: true });
    rest.get("/api/Category").then((category) => {
      //console.log(category)
      this.setState({ categorylist: category.data.data.list, loading: false });
    });
  }

  onSubmit() {
    let formdata = this.formRef.current.getFieldsValue();
    this.setState({ formAddLoader: true });
    rest.post("/api/Category/", formdata).then((catresult) => {
      this.closeAddModa();
      this.getCatList();
      //this.formRef.current.reset();
    });
  }

  onSubmitEdit() {
    let formdata = this.formRefEdit.current.getFieldsValue();
    
    this.setState({ formAddLoader: true });
    
    rest.update("/api/Category/", {catname: formdata.catname, id: this.state.activeCat.id}).then((catresult) => {
      this.closeAddModa();
      this.getCatList();      
    });
  }

  addModalShow() {
    this.setState({ addModalVisible: true });
  }

  closeAddModa() {
    this.setState({editModalVisible: false, addModalVisible: false, formAddLoader: false });
  }
  closeEditModa(){
    this.setState({ editModalVisible: false, formAddLoader: false });
  }

  submitAddForm() {
    this.formRef.current.submit();  
  }

  submitEditForm() {
    this.formRefEdit.current.submit();  
  }

  deleteCat(id){
      this.setState({loading: true})
      rest.delete('/api/Category', {id:id}).then(() => {
          this.getCatList();
          message.success('Категория удалена');          
      })
  }

  showEditCat(item){
      this.setState({editModalVisible: true, activeCat: item})
      setTimeout(() => {
        
        this.formRefEdit.current.setFieldsValue({catname: item.name})
      }, 100)
      
      //
  }

  render() {
    const style = { padding: "0px 20px" };
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card">
        <Row key={2}>
          <Col span={this.state.cataspan}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Категории"
              extra={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.addModalShow()}
                >
                  Добавить
                </Button>,
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={style} className="categorylist">
            <List
              itemLayout="horizontal"
              dataSource={this.state.categorylist}
              locale={{emptyText: 'Категории не найдены'}}
              renderItem={(item, index) => (
                <Skeleton
                  key={index}
                  title={false}
                  loading={this.state.loading}
                  active
                >
                  <List.Item
                    className="categoryitem"
                    actions={[
                      <a key="list-loadmore-edit" onClick={() => this.showEditCat(item)}>
                        <EditOutlined />
                      </a>,
                      <Popconfirm
                        title="Удалить категорию?"
                        onConfirm={() => this.deleteCat(item.id)}                        
                        okText="Да"
                        cancelText="Нет"
                      >
                        <a key="list-loadmore-more">
                          <DeleteOutlined />
                        </a>
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta title={item.name} />
                  </List.Item>
                </Skeleton>
              )}
            ></List>
          </Col>
        </Row>
      </Card>,
      <Modal
      key={3}
      title="Редактирование категории"
      visible={this.state.editModalVisible}
      onCancel={this.closeEditModa}
      onOk={this.submitEditForm}
    >
      {this.state.formAddLoader ? (
        <Loading mini={true} />
      ) : (
        <Form
          {...layout}
          name="addcatform"
          ref={this.formRefEdit}
          onFinish={this.onSubmitEdit}
        >
          <Form.Item
            label="Название категории"
            name="catname"
            rules={[
              { required: true, message: "Пожалуйста введите категорию" },
            ]}
          >
            <Input />
          </Form.Item>
          
        </Form>
      )}
    </Modal>,
      <Modal
        key={4}
        title="Добавление категории"
        visible={this.state.addModalVisible}
        onCancel={this.closeAddModa}
        onOk={this.submitAddForm}
      >
        {this.state.formAddLoader ? (
          <Loading mini={true} />
        ) : (
          <Form
            {...layout}
            name="addcatform"
            ref={this.formRef}
            onFinish={this.onSubmit}
          >
            <Form.Item
              label="Название категории"
              name="catname"
              rules={[
                { required: true, message: "Пожалуйста введите категорию" },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        )}
      </Modal>,
    ];
  }
}

CategoryComponent.contextType = AppContext;

export default withRouter(CategoryComponent);

import React from 'react';
import '../../App.scss';
import { Card, Spin } from 'antd';
import Breadcrumb from '../common/breadcrumb'
import AppContext from '../../components/common/appContext';
import { PageHeader, Button, Typography, Form, Input, message } from 'antd';
import { withRouter, NavLink } from 'react-router-dom';
import rest from '../../helpers/rest';
const { Text, Link, Title } = Typography;


const layout = {
    labelCol: { offset: 6, sm: 12 },
    wrapperCol: { offset: 6, sm: 12 },
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
  };
  

class ResetPassComponent extends React.Component{
    constructor(props){
        super(props);
        this.breadcrumbs = [
            { title: "Букетная.ОПТ", key: "/" },
            { title: "Изменение пароля", key: "/stock/" }
        ];
        this.state = {
            loading: false
        }
        this.formRef = React.createRef();
        this.formValidator = this.formValidator.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
    }

    componentWillMount(){
        document.title = 'Изменить пароль | Букетная.ОПТ';        
    }

    onSubmit(){
        this.setState({loading: true})
        //this.formRef.current.getFieldValue();
        rest.update('/api/Account/newpass', this.formRef.current.getFieldValue()).then(data => {
            message.success('Пароль успешно изменен');
            this.setState({loading: false})
        })
        .catch((error) => {
          console.error(error);
          this.setState({loading: false})
          message.error('Введен неверный старый пароль');
        });
    }

    formValidator(values, fieldName){
        
        let fieldsErrors = {};
        
        if(values.repeatpass !== values.newpass){
            fieldsErrors.repeatpass = ['Пароли не совпадают']
        }
        if(!values.oldpass){
            fieldsErrors.oldpass = ['Введите старый пароль']
        }
        if(!values.repeatpass){
            fieldsErrors.repeatpass = ['Подтвердите пароль']
        }
        if(!values.newpass){
            fieldsErrors.newpass = ['Введите новый пароль']
        }
        const errors = fieldsErrors[fieldName];
        
        return errors ? Promise.reject(errors) : Promise.resolve();
        
    }

    render(){        
        return [
                <Breadcrumb key={1} style={{ margin: '16px 0' }} list={this.breadcrumbs} />,
                <Card
                    key={2}
                    className="content-card"                    
                >
                    <PageHeader
                        className="site-page-header"
                        onBack={() => this.props.history.goBack()}
                        title="Изменить пароль"                        
                    />
                    <Spin spinning={this.state.loading}>
                    <Form
                {...layout}
                onFinish={this.onSubmit}
                onFinishFailed={() => message.warn("Заполните данные")}
                name="resetpass"
                className="resetpass"
                ref={this.formRef}
                layout="vertical"
              >                
                <Form.Item
                  label="Старый пароль"
                  name="oldpass"
                  rules={[{ validator: () => this.formValidator(this.formRef.current.getFieldsValue(), 'oldpass')  }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Новый пароль"
                  name="newpass"
                  rules={[{ validator: () => this.formValidator(this.formRef.current.getFieldsValue(), 'newpass')  }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Повтор"
                  name="repeatpass"                
                  rules={[{ validator: () => this.formValidator(this.formRef.current.getFieldsValue(), 'repeatpass')  }]}
                >
                  <Input.Password onChange={this.validRepeat} />
                </Form.Item>
                
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                </Form.Item>
              </Form>
              </Spin>
                    
                </Card>
            ]
        
    }
}

ResetPassComponent.contextType = AppContext;

export default withRouter(ResetPassComponent);
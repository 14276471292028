import React from "react";
import "../../../App.scss";
import { message, Skeleton } from "antd";
import Breadcrumb from "../../common/breadcrumb";
import {
  Card,
  Row,
  Col,
  List,
  Button,
  Form,
  Input,
  InputNumber,
  Spin,
  Modal,
} from "antd";

import {
  PlusCircleOutlined
} from '@ant-design/icons';
import AppContext from "../../../components/common/appContext";
import rest from "../../../helpers/rest";
import ParsedItem from "../parseditem/parseditem";
import Catalog from "../../catalog/catalog";
import { withRouter } from "react-router-dom";

const findTimeout = undefined;

class CatalogSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: props.data,
      modalVisible: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  render() {
    return [
      this.props.canSelect &&
      <span
        key={1}
        className="subtitlemini"
        onClick={() => this.setState({ modalVisible: true })}
      >
        {this.props.label || 'Выбрать'}        
      </span>,
      <div key={33}>{this.props.icon && <PlusCircleOutlined onClick={() => this.setState({ modalVisible: true })} style={{
        fontSize: '50px',
        marginTop: '10px',
        color: '#008acc'
      }} />}</div>,
      <Modal
        key={2}
        width={1500}
        onCancel={() => this.setState({ modalVisible: false })}
        visible={this.state.modalVisible}
        title="Выбор позиции из каталога"
      >
        <Catalog
        forPreorder={true}
        key={4}
          forSelect={true}
          onSelect={(e) => {
            this.setState({ modalVisible: false });
            this.props.onSelect({ id: e.id, name: e.name, imageName: e.image, color: e.color, colorname: e.colorname, colorvalue: e.colorvalue, image: e.image });
          }}
          key={3}
        />
      </Modal>,
    ];
  }
}

CatalogSelect.CatalogSelect = AppContext;

export default withRouter(CatalogSelect);

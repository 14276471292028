import React from "react";
import "../../../App.scss";
import {
  Spin,
  Card,
  Row,
  Col,
  Switch,
  message,
  Select,
  Popconfirm,
  InputNumber,
  Table,
  Tag,
} from "antd";
import Breadcrumb from "../../common/breadcrumb";
import { PageHeader, Button, Form, Input } from "antd";

import { withRouter } from "react-router-dom";
import rest from "../../../helpers/rest";
import MaskedInput from "antd-mask-input";
import AppHelper from "../../../helpers/app";
import UserSelectModalComponent from "../../users/userselectmodal";
import CatalogSelect from "../../excelparser/catalogselect/catalogselect";
import SingleGoodComponent from "../../catalog/singlegood";
import PreordersView from "./forview/index";
import { EyeOutlined, EditOutlined,DeleteOutlined } from "@ant-design/icons";
import { Calendar, Badge } from "antd";
import * as moment from "moment";
import "moment/locale/ru";
import AppContext from "../../common/appContext";
import locale from "antd/es/date-picker/locale/ru_RU";
moment.locale("ru");

const { Option } = Select;

const layout = {
  labelCol: { offset: 6, sm: 12 },
  wrapperCol: { offset: 6, sm: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

function getListData(value) {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        { type: "warning", content: "This is warning event." },
        { type: "success", content: "This is usual event." },
      ];
      break;
    case 10:
      listData = [
        { type: "warning", content: "This is warning event." },
        { type: "success", content: "This is usual event." },
        { type: "error", content: "This is error event." },
      ];
      break;
    case 15:
      listData = [
        { type: "warning", content: "This is warning event" },
        { type: "success", content: "This is very long usual event。。...." },
        { type: "error", content: "This is error event 1." },
        { type: "error", content: "This is error event 2." },
        { type: "error", content: "This is error event 3." },
        { type: "error", content: "This is error event 4." },
      ];
      break;
    default:
  }
  return listData || [];
}

function getMonthData(value) {
  if (value.month() === 8) {
    return 1394;
  }
}

class PreordersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableData: [],
      calendarOrdersLoading: true,
      calendarOrders: [],
      calendarMode: "month",
      curretCalendarValue: moment(new Date()),
      breadcrumbs: [
        { title: "Букетная.ОПТ", key: "/" },
        { title: "Предзаказы", key: "/preorders" },
      ],
    };

    this.deletePreorders = this.deletePreorders.bind(this);
    this.getPreorders = this.getPreorders.bind(this);

    this.columns = [
      {
        title: "№",
        dataIndex: "ID",        
        sorter: (a, b) => a.ID - b.ID,
      },
      {
        title: "Дата создания",
        dataIndex: "CREATION",
        render: (text, record) => {
       
          return  moment(record.DATE_CREATION).format("DD-MM-YYYY").replace(/\-/g, '.')
              
        },
      },
      {
        title: "На дату",
        dataIndex: "TODATE",
        render: (text, record) => {
          if (moment(record.TODATE).isAfter()) {
            return (
              <Tag color="green">
                {moment(record.TODATE).format("DD-MM-YYYY").replace(/\-/g, '.')}
              </Tag>
            );
          } else {
            return (
              <Tag color="orange">
                {moment(record.TODATE).format("DD-MM-YYYY").replace(/\-/g, '.')}
              </Tag>
            );
          }
        },
      },
      {
        title: "Имя",
        dataIndex: "fname",
        render: (text, record) => {
          return `${record.fname} ${record.sname}`;
        },
      },
      {
        title: "Действия",
        render: (text, record) => (
          <div className="preorder__actions table">
            <EyeOutlined
              style={{ color: "#1890ff" }}
              onClick={() => {
                this.viewPreorder(record.ID);
              }}
            />
            <EditOutlined
              style={{ color: "#1890ff" }}
              onClick={() => {
                this.props.history.push(`/preorder/${record.ID}`);
              }}
            />
            <Popconfirm
              title={`Удалить предзаказ?`}
              onConfirm={() => this.deletePreorders(record.ID)}
              okText="Да"
              cancelText="Нет"
            >
              <a style={{ color: "red", marginLeft: '10px' }}>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </div>
        ),
      },
    ];
  }

  monthCellRender = (value) => {
    let preorders = this.state.calendarOrders.filter(
      (i) => moment(i.TODATE).format("MM YYYY") === value.format("MM YYYY")
    );
    if (preorders.length > 0) {
      return (
        <div className="calendar__badge">
          <Badge
            className="site-badge-count-109"
            count={preorders.length}
            style={{ backgroundColor: "#52c41a" }}
          />
        </div>
      );
    }
  };

  getPreordersByMonthForCalendar = (month, year, mode = "month") => {
    return new Promise((res, rej) => {
    this.setState({ calendarOrdersLoading: true,  });
    if (mode == "month") {
      rest
        .get("/api/Requests/preordersbymonth", { month, year })
        .then((data) => {
          //console.log(data.data.data)
          this.setState({ calendarOrders: data.data.data });
          this.setState({ calendarOrdersLoading: false });
          res();
        });
    } else if (mode == "year") {
      rest
        .get("/api/Requests/preordersbyyear", { month, year })
        .then((data) => {
          //console.log(data.data.data)
          res();
          this.setState({ calendarOrders: data.data.data });
          this.setState({ calendarOrdersLoading: false });
        });
    }
  })
  };

  dateCellRender = (value) => {
    let preorders = this.state.calendarOrders.filter(
      (i) =>
        moment(i.TODATE).format("DD MM YYYY") === value.format("DD MM YYYY")
    );
    if (preorders.length > 0 ) {
      return (
        <div className="calendar__badge">
          <Badge
            className="site-badge-count-109"
            count={preorders.length}
            style={{ backgroundColor: "#52c41a" }}
          />
        </div>
      );
    }
  };

  viewPreorder = (id) => {
    this.context.openDrawer(
      `Просмотр предзаказа №${id}`,
      <PreordersView id={id} />
    );
  };

  preorder = () => {
    this.setState({ loading: true });
    rest.post("/api/Requests/preorder").then((data) => {
      this.setState({ loading: false });
      this.props.history.push(`/preorder/${data.data.data}`);
    });
  };

  getPreorders = (date = undefined) => {
    
      this.setState({ loading: true,  curretCalendarValue: date});
      let params = {};
      if (this.context.usertype == "admin") {
        params = {
          date: moment(date).format("DD M YYYY"),
          mode: this.state.calendarMode,
        };
      }
      rest.get("/api/Requests/getpreorders", params).then((data) => {
        this.setState({ tableData: data.data.data, loading: false });
         
      });
    
  };

  deletePreorders(id){
    this.setState({loading: true})
    rest.delete('/api/Requests/deletepreorders', {id:id}).then(data => {
      if (this.context.usertype == "admin") {
        this.getPreordersByMonthForCalendar(
          this.state.curretCalendarValue.format('M'),this.state.curretCalendarValue.format('YYYY'),this.state.calendarMode
        ).then(e => {
          this.getPreorders(this.state.curretCalendarValue)
        })
      } else {
        this.getPreorders();
      }     
      message.success('Предзаказ удален');     
    })
  }

  componentWillMount() {
    if (this.context.usertype == "admin") {
      this.getPreordersByMonthForCalendar(
        moment(new Date()).format("M"),
        moment(new Date()).format("YYYY")
      ).then(e => {
        this.getPreorders(moment(new Date()))
      })
    } else {
      this.getPreorders();
    }
  }

  calendarChange = (event, mode = "month") => {
    this.setState({ calendarMode: mode });
    this.getPreordersByMonthForCalendar(
      event.format("M"),
      event.format("YYYY"),
      mode
    );
  };

  render() {
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.state.breadcrumbs}
      />,
      <Spin key={2} spinning={this.state.loading}>
        <Row key={2}>
          <Col md={{ span: this.context.usertype == "admin" ? 13 : "24" }}>
            <Card className="content-card">
              <PageHeader
                className="site-page-header"
                onBack={() => this.props.history.goBack()}
                title="Предзаказы"
                extra={[
                  <Spin key="1" spinning={false}>
                    <Button onClick={() => this.preorder()} type="primary">
                      <span>Добавить предзаказ</span>
                    </Button>
                  </Spin>,
                ]}
              />
              <Table columns={this.columns} dataSource={this.state.tableData} />
            </Card>
          </Col>
          {this.context.usertype == "admin" && (
            <Col md={{ span: 10, offset: 1 }}>
              <Card className="content-card">
                <Spin spinning={this.state.calendarOrdersLoading}>
                  <Calendar
                    locale={locale}
                    onChange={this.getPreorders } 
                    value={this.state.curretCalendarValue}
                    onPanelChange={this.calendarChange}
                    dateCellRender={this.dateCellRender}
                    monthCellRender={this.monthCellRender}
                  />
                </Spin>
              </Card>
            </Col>
          )}
        </Row>
      </Spin>,
    ];
  }
}

PreordersComponent.contextType = AppContext;

export default withRouter(PreordersComponent);

import * as React from "react";
import {
  Breadcrumb,
  Skeleton,
  Tag,
  Menu,
  Dropdown,
  Button,
  Space,
  Spin,
  Modal,
  message,
  Popconfirm,
  Tooltip
} from "antd";
import { NavLink } from "react-router-dom";
import AppHelper from "../../../../helpers/app";
import rest from "../../../../helpers/rest";
import {
  ArrowDownOutlined,
  EyeOutlined,
  MoneyCollectOutlined,
  OrderedListOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import * as moment from "moment";
import "moment/locale/ru";
import TotalPrice from "../../totalprice";
import AppContext from "../../../common/appContext";
import SingleRequestList from "../../singlerequestlist";
import UserCart from "../../../user-cart";
import RequestPayment from "./payment/index";
import RequestDiscount from './discountset/index'
import userCart from "../../../user-cart";
moment.locale("ru");

class RequestSingleV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moreBoxOpen: false,
      request: this.props.data,
      totalvisible: false,
      loading: false,
      debtThrowBalanceVisible: false,
      loadingmessage: "",
      returnMoneyVisible: false,
      returnMoneyId: null,
      returnMoneyLoad: false,
      returnMoney: false,
      debtRequestId: null,
    };
  }

  renderMoreBox = () => {
    if (this.state.moreBoxOpen) {
      return <div className="request__morebox">Тут инфа дополнительна</div>;
    } else {
      return "";
    }
  };

  fiscaleSuccess = () => {
    this.context.closeDrawer();
    this.updateRequest(true);
  };

  updateRequest = (checkFiscal = false) => {
    if (checkFiscal) {
      this.setState({ loadingmessage: "Фискализация..." });
    }
    this.setState({ loading: true });
    rest
      .get("/api/Requests/all", { requestid: this.state.request.id })
      .then((data) => {
        let request = data.data.data.list[0];
        if (checkFiscal && !request.is_fiscal) {
          setTimeout(() => {
            this.updateRequest(true);
          }, 2000);
        } else {
          this.setState({
            loading: false,
            request: request,
            loadingmessage: "",
          });
        }
      });
  };

  showRequestPositions = () => {
    this.context.openDrawer(
      `Просмотр заказа №${this.state.request.id}`,
      <SingleRequestList
        data={{ id: this.state.request.id, type: this.state.request.type }}
        update={() => {
          this.setState({ totalvisible: false });
          setTimeout(() => {
            this.setState({ totalvisible: true });
          }, 3);
        }}
      />
    );
  };

  openFiscalDraw = () => {
    this.context.openDrawer(
      `Оплата заявки ${this.state.request.id}`,
      <RequestPayment
        onClose={this.fiscaleSuccess}
        id={this.state.request.id}
        request={this.state.request}
      />
    );
  };

  

  writeOffMoney(reqid, throwbalance) {
    this.setState({ loading: true, debtThrowBalanceVisible: false });
    if (this.state.loading) return;
    rest
      .update("/api/Requests/writeoff", {
        id: reqid,
        throwbalance: throwbalance,
      })
      .then((data) => {
        this.updateRequest();
      })
      .catch((err) => {
        message.error(err.response.data.data.detail);
        this.setState({ loading: false });
        let reserr = err.response.data.data;
        if (reserr.type && reserr.type == "nonpayed") {
          this.setState({
            debtRequestId: reqid,
            debtRequestStatus: "issued",
            debtThrowBalanceVisible: true,
          });
        }
      });
  }

  generatePackageList = (pdf = true) => {
    let id = this.state.request.id;
    this.context.setLoad(true, "Генерация накладной");
    rest
      .get("/api/Requests/generatepackinglist", { id: id, front: true })
      .then((data) => {
        this.context.setLoad(false);
        if (pdf) {
          window.open(
            `https://view.officeapps.live.com/op/view.aspx?src=${rest.getfileurl(
              data.data.data
            )}`,
            "_blank"
          );
        } else {
          window.open(rest.getfileurl(data.data.data), "_blank");
        }
      })
      .catch((e) => {
        this.context.setLoad(false);
      });
  };

  statusList(pos) {
    let filtered = [];
    let menues = [
      {
        code: "active",
        inner: (
          <Menu.Item
            key={0}
            onClick={() => this.changeStatus(pos.id, "active", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("active").color}>
              {AppHelper.getRequestStatus("active").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "done",
        inner: (
          <Menu.Item
            key={1}
            onClick={() => this.changeStatus(pos.id, "done", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("done").color}>
              {AppHelper.getRequestStatus("done").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "admindone",
        inner: (
          <Menu.Item
            key={2}
            onClick={() => this.changeStatus(pos.id, "admindone", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("admindone").color}>
              {AppHelper.getRequestStatus("admindone").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "collected",
        inner: (
          <Menu.Item
            key={3}
            onClick={() => this.changeStatus(pos.id, "collected", pos.status)}
          >
            <Tag
              color={AppHelper.getRequestStatus("collected", pos.status).color}
            >
              {AppHelper.getRequestStatus("collected").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "issued",
        inner: (
          <Menu.Item
            key={4}
            onClick={() => this.changeStatus(pos.id, "issued", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("issued").color}>
              {AppHelper.getRequestStatus("issued").label}
            </Tag>
          </Menu.Item>
        ),
      },
      {
        code: "cancel",
        inner: (
          <Menu.Item
            key={5}
            onClick={() => this.changeStatus(pos.id, "cancel", pos.status)}
          >
            <Tag color={AppHelper.getRequestStatus("cancel").color}>
              {AppHelper.getRequestStatus("cancel").label}
            </Tag>
          </Menu.Item>
        ),
      },
    ];
    let access_statusses = [
      { status: "active", access: ["done", "cancel"] },
      {
        status: "done",
        access: ["active", "cancel", "admindone", "issued", "collected"],
      },
      {
        status: "admindone",
        access: ["active", "issued", "done", "cancel", "collected"],
      },
      {
        status: "collected",
        access: ["cancel", "active", "admindone", "issued"],
      },
      {
        status: "issued",
        access: ["active", "cancel", "admindone", "done", "collected"],
      },
      { status: "cancel", access: [] },
    ];
    filtered = menues
      .filter((elem) => elem.code !== pos.status)
      .filter((i) =>
        access_statusses
          .filter((as) => as.status == pos.status)[0]
          .access.includes(i.code)
      );
    if (pos.status === "cancel") {
      filtered = menues.filter((elem) => elem.code === "cancel");
    }
    return (
      <Menu>
        {filtered.map((item, index) => {
          return item.inner;
        })}
      </Menu>
    );
  }

  changeStatus(id, status, currentstatus, throwbalance = false) {
    this.setState({ debtThrowBalanceVisible: false });
    this.setState({ loading: true });
    rest
      .update("/api/Requests/changestatus", {
        requestid: id,
        status: status,
        throwbalance: throwbalance,
      })
      .then((data) => {
        this.updateRequest();
      })
      .catch((err) => {
        message.error(err.response.data.data.detail);
        this.setState({ loading: false });
        this.updateRequest();
      });
  }

  setDiscount = () => {
    if(this.state.request.status === 'issued'){
      message.error('Нельзя установить скидку к заказу в статусе "Выдан"')
      return;
    }
    else if( this.state.request.paid != 0){
      message.error('Нельзя установить скидку к оплаченному заказу')
      return;
    }
    else if(this.state.request.is_fiscal != 0){
      message.error('Нельзя установить скидку к фискализированному заказу')
      return;
    }
    else if(this.state.request.DISCOUNT != null){
      message.error('К этой заявке уже применяется скидка пользователя')
      return;
    }
    //RequestDiscount
    this.context.openDrawer(
      `Скидка по заявке №${this.state.request.id}`,
      <RequestDiscount done={() => {
        this.context.closeDrawer();
        this.setState({ totalvisible: false });
        setTimeout(() => {
          this.updateRequest();
        }, 3)
      }} request={this.state.request} />
    );

  }

  showProfileUser = (id) => {
    this.context.openDrawer(
      `Просмотр профиля`,
      <UserCart
        userid={id}
        update={() => {
          this.setState({ totalvisible: false });
          setTimeout(() => {
            this.setState({ totalvisible: true });
          }, 3);
        }}
      />
    );
  };

  deletePercent(id){
    this.setState({loading: true})
    rest.delete('/api/Requests/deletepercent', {id:id})
    .then(data => {
      this.setState({
        loading:false
      })  
      this.updateRequest();
      message.success('Скидка удалена');     
    })
    .catch((error) => {
      message.error(error.response.data.data.detail);
      this.setState({ loadnig: false });
      this.updateRequest();
    });
  }

  requestActions = () => {
   
    let actions = [];

    if (
      !this.state.request.is_fiscal &&
      ["issued"].includes(this.state.request.status)
    ) {
      actions.push(
        <Menu.Item key={1}>
          <a onClick={this.openFiscalDraw}>Фискализировать</a>
        </Menu.Item>
      );
    }



    if (
      ["done", "admindone", "collected", "issued"].includes(
        this.state.request.status
      ) 
      && this.state.request.paid
    ) {
      actions.push(<Menu.Item key={6}><a onClick={() => this.setState({returnMoneyVisible: true,returnMoneyId: this.state.request.id,})} >Вернуть средства</a></Menu.Item>) 
    }

    if (
      ["done", "admindone", "collected", "issued"].includes(
        this.state.request.status
      )
    ) {
      actions.push(
        <Menu.Item key={2}>
          <a onClick={() => this.generatePackageList(false)}>
            Товарная накладная - скачать
          </a>
        </Menu.Item>
      );
      actions.push(
        <Menu.Item key={3}>
          <a onClick={() => this.generatePackageList(true)}>
            Товарная накладная - открыть
          </a>
        </Menu.Item>
      );
    }

    let statusestowriteoff = ["done", "admindone", "collected", "issued"];
    if (
      !this.state.request.paid &&
      statusestowriteoff.includes(this.state.request.status)
    ) {
      actions.push(
        <Menu.Item key={4}>
          <a onClick={() => this.writeOffMoney(this.state.request.id)}>
            Списать средства
          </a>
        </Menu.Item>
      );
    }
    return {
      count: actions.length,
      menu: <Menu>{actions}</Menu>,
    };
  };

  returnMoneyForRequest() {
    this.setState({ returnMoneyLoad: true });
   

    rest
      .post("/api/Requests/returnmoneyforrequest", {
        id: this.state.returnMoneyId,
      })
      .then((e) => {
        message.success("Средства возвращены");
        this.setState({ returnMoneyLoad: false, returnMoneyVisible: false });
        this.updateRequest();
      })
      .catch((e) => {
        message.error(e.data);
        this.setState({ returnMoneyLoad: false, returnMoneyVisible: false });
      });
  }

  render() {
    const percent = this.state.request.PERCENT_DISCOUNT;
    return [
      <Modal
        key={22}
        title="Выдать заказ в долг ?"
        onOk={() => this.writeOffMoney(this.state.debtRequestId, true)}
        onCancel={() => this.setState({ debtThrowBalanceVisible: false })}
        visible={this.state.debtThrowBalanceVisible}
      >
        {/* onOk={handleOk} onCancel={handleCancel} */}

        <p>Выдать заказ №{this.state.debtRequestId} в долг?</p>
      </Modal>,
      <Modal
        key={100}
        title="Подтверждение"
        onOk={() => this.returnMoneyForRequest()}
        onCancel={() => this.setState({ returnMoneyVisible: false })}
        visible={this.state.returnMoneyVisible}
      >
        {/* onOk={handleOk} onCancel={handleCancel} */}
        <Spin spinning={this.state.returnMoneyLoad}>
          <p>Вернуть средства на баланс пользователя ?</p>
        </Spin>
      </Modal>,

      <div key={1} className="request__v2">
        <div className="request__v2_wrap">
          <Spin
            key={1}
            spinning={this.state.loading}
            tip={this.state.loadingmessage}
          >
            <div className="request__v2_header">
              <div className="row__block">
                <p className="request__number">
                  Заявка №{this.state.request.id}
                </p>
                <div className="request__user">
                  <span className="fio" onClick={() => this.showProfileUser(this.state.request.userid)}>
                    {this.state.request.fname} {this.state.request.sname}
                  </span>
                  <a href={`tel:${this.state.request.phone}`}>
                    +7
                    {AppHelper.formatUsPhone(
                      String(this.state.request.phone).substring(1, 11)
                    )}
                  </a>
                </div>
              </div>
              <div className="row__block">
                <div className="params__list">
                  <div className="params">
                    <span className="label">
                      <span>Статус фискализации</span>
                      <OrderedListOutlined
                        style={{
                          color: this.state.request.is_fiscal ? "green" : "red",
                        }}
                      />
                    </span>
                    <span className="value">
                      {this.state.request.is_fiscal
                        ? "Фискализирован"
                        : "Не фискализирован"}
                    </span>
                  </div>
                  <div className="params">
                    <span className="label">
                      <span>Статус оплаты</span>
                      <MoneyCollectOutlined
                        style={{
                          color: this.state.request.paid ? "green" : "red",
                        }}
                      />
                    </span>
                    <span className="value">
                      <span>
                        {this.state.request.paid ? "Оплачен" : "Не оплачен"}
                      </span>
                    </span>
                  </div>
                  <div className="params">
                    <span className="label">
                      <span>Скидка заявки, %</span>
                    </span>
                    <span className="value">
                      {percent == 0 || !percent ? (
                        <a onClick={this.setDiscount}>Установить скидку</a>
                      ) : (
                        <div className="requests_percent">
                        {percent}%
                        <Popconfirm
                          title={`Удалить скидку?`}
                          okText="Да"
                          cancelText="Нет"
                          onConfirm={() => this.deletePercent(this.state.request.id)}
                        >
                          <a style={{ color: "red", marginLeft: '10px' }}>
                            <DeleteOutlined />
                          </a>
                        </Popconfirm></div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row__block">
                <div className="params__list">
                  <div className="params">
                    <span className="label">Источник заявки</span>
                    <span className="value">
                      {AppHelper.getRequestType(this.state.request.type)}
                    </span>
                  </div>
                  {this.state.request.type == "exchange" && (
                    <div className="params">
                      <span className="label">Торговый день</span>
                      <span className="value">
                        {moment(this.state.request.tddate).format("DD.MM.YYYY")}
                      </span>
                    </div>
                  )}
                  <div className="params">
                      <span className="label">Доставка</span>
                      <span className="value">
                        {this.state.request ? this.state.request.delivery == 1 ? 
                            <Tooltip title={this.state.request.address}>
                              <div className="requests__icon-delivery"></div>
                            </Tooltip> : this.state.request.delivery == 2 ? 
                            <Tooltip title={this.state.request.address}>
                              <div className="requests__icon-taxi"></div>
                            </Tooltip> : '' :''}
                      </span>
                  </div>
                  {/* <div className="params">
                  <span className="label">Количество позиций</span>
                  <span className="value">50</span>
                </div>                 */}
                </div>
              </div>
              <div className="row__block">
                <div className="params__list">
                  <div className="params">
                    <span className="label">Последние изменение</span>
                    <span className="value">
                      {moment(new Date())
                        .subtract(this.state.request.lastmodifydiff, "seconds")
                        .fromNow()}
                    </span>
                  </div>
                  <div className="params">
                    <span className="label">Дата создания</span>
                    <span className="value">
                      {moment(this.state.request.created).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row__block">
                <div className="params__list">
                  <div className="params">
                    <span className="label">Статус</span>

                    <span className="value">
                      <Dropdown
                        key={1}
                        overlay={this.statusList(this.state.request)}
                      >
                        <Tag
                          key={0}
                          color={
                            AppHelper.getRequestStatus(
                              this.state.request.status
                            ).color
                          }
                        >
                          {
                            AppHelper.getRequestStatus(
                              this.state.request.status
                            ).label
                          }
                        </Tag>
                      </Dropdown>
                    </span>
                  </div>
                  <div className="params">
                    <span className="label">Корзина</span>

                    <span
                      onClick={() => this.showRequestPositions()}
                      className="value"
                    >
                      <a>Просмотр</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="row__block">
                <div className="params__list">
                  <div className="params">
                    <span className="label">Итого</span>

                    <span className="value">
                      <span
                        onClick={() => {
                          this.setState({ totalvisible: false });
                          setTimeout(() => {
                            this.setState({ totalvisible: true });
                          }, 3);
                        }}
                      >
                        <a>Посчитать</a>
                      </span>
                      {this.state.totalvisible ? (
                        <TotalPrice
                          small={true}
                          onRef={(ref) => (this.child = ref)}
                          type={this.state.request.type}
                          id={this.state.request.id}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className="params">
                    {this.requestActions().count > 0 && (
                      <Dropdown
                        overlay={this.requestActions().menu}
                        placement="bottomLeft"
                      >
                        <Button>Действия</Button>
                      </Dropdown>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {
              // Пока не потребовался этот блок, но не удаляем на всякий случай
            }
            {/* {this.renderMoreBox()}
            <div
              onClick={() =>
                this.setState({ moreBoxOpen: !this.state.moreBoxOpen })
              }
              className={`open__box ${this.state.moreBoxOpen ? "open" : ""}`}
            >
              <ArrowDownOutlined />
            </div> */}
          </Spin>
        </div>
      </div>,
    ];
  }
}
RequestSingleV2.contextType = AppContext;
export default RequestSingleV2;

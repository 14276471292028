import React from "react";
import "../../App.scss";
import { Card, Pagination, Input, Menu, Dropdown, Space } from "antd";
import Breadcrumb from "../common/breadcrumb";
import AppContext from "../../components/common/appContext";
import { PageHeader, Button, Form } from "antd";
import { withRouter } from "react-router-dom";
import CategorySelectComponent from "../common/categoryselect";
import ColorSelectComponent from "../common/colorselect";
import SingleGoodComponent from "../catalog/singlegood";
import rest from "../../helpers/rest";
import Loading from "../loading";
import SortSelect from "../common/sortselect";
import AppHelper from "../../helpers/app";
import Devilery from "../common/devilerybutton";

class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Склад", key: "/stock/" },
    ];
    this.state = {
      filterParams: {},
      // По сколько отображать на странице
      pageSize: localStorage.getItem("pageSize") || 10,
      totalSize: 0,
      currentPage: 1,
      // Список позиций склада
      stockList: [],
      loading: true,
      selftcostdiscount: false,
      percentdiscount: null
    };
    this.formFilterRef = React.createRef();
    this.getStock = this.getStock.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.getLost = this.getLost.bind(this)
  }

  componentWillMount() {
    document.title = "Склад | Букетная.ОПТ";
    this.getStock();
  }

  getStock() {
    this.setState({ loading: true });
    let params = this.state.filterParams;
    params.page = this.state.currentPage;
    params.pagesize = this.state.pageSize;
    rest.get("/api/Stock", params).then((data) => {
      this.setState({
        stockList: data.data.data.list,
        totalSize: data.data.data.count,
        loading: false,
        selftcostdiscount: data.data.data.selftcostdiscount,
        percentdiscount: (data.data.data.percentdiscount && data.data.data.percentdiscount.length > 0) ? data.data.data.percentdiscount[0] : null
      });
    });
  }

  onChange(page, size) {
    //console.log(size)
    AppHelper.setPageSize(size);
    this.setState({ currentPage: page, pageSize: size });
    this.filterAction(false);
  }

  filterAction(pageSkip = true) {
    let formdata = this.formFilterRef.current.getFieldsValue();
    let params = {};
    Object.keys(formdata).map((item) => {
      if (formdata[item] !== undefined) {
        if (typeof formdata[item] === "Array" && formdata[item].length > 0) {
          params[item] = formdata[item].join(",");
        } else {
          params[item] = formdata[item];
        }
      }
    });
    if (pageSkip) {
      this.setState({ filterParams: params, currentPage: 1 }, () => {
        this.getStock();
      });
    } else {
      this.setState({ filterParams: params }, () => {
        this.getStock();
      });
    }
  }

  resetFilter() {
    this.setState({ filterParams: {} });
    this.formFilterRef.current.resetFields();
    this.filterAction();
  }


  // Функция отправляет запрос на сервер на генерация barcode's для всего склада
  generateBarCodes(){
    this.context.setLoad(true, 'Генерация штрих-кодов');
    rest.post('/api/BarCodes/', {type: 'stock'}).then(data => {
        //data.data.data.pdf__name
        let url = rest.getfileurl(`/stickers/${data.data.data.pdf__name}`);
        window.open(url, 'blank');
        this.context.setLoad(false, '');
    })
  }

  // Получение остатков
  getLost(pdf = false){
    
    this.context.setLoad(true, 'Генерация остатков')
    rest.get('/api/Stock/lost').then(data => {
      
      this.context.setLoad(false)
      if(pdf){
        
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${rest.getfileurl(data.data.data)}`, '_blank');
      }else{
        
        window.open(rest.getfileurl(data.data.data), '_blank');
      }
      
    }).catch(e => {
      this.context.setLoad(false)
    })    
  }

  menuRender() {
    return (
      <Menu>
        <Menu.Item onClick={() => this.getLost(false)}>
          <a>Получение остатков - Excel</a>
        </Menu.Item>        
        <Menu.Item onClick={() => this.getLost(true)}>
          <a>Получение остатков - Открыть</a>
        </Menu.Item>        
      </Menu>
    );
  }

  getPercentDiscount(){    
    if(this.state.percentdiscount && this.state.percentdiscount.DISCOUNT.match(/\d+/g)){
      return parseInt(this.state.percentdiscount.DISCOUNT.match(/\d+/g)[0] || 0)
    }
    return null;
  }

  render() {
    return [
      !this.props.forSelect && (
        <Breadcrumb
          key={1}
          style={{ margin: "16px 0" }}
          list={this.breadcrumbs}
        />
      ),
      <Card key={2} className="content-card">
        {!this.props.forSelect && (
          <PageHeader
            className="site-page-header"
            onBack={() => this.props.history.goBack()}
            title="Склад"
            extra={[
                (this.context.usertype === "admin") && <Space key={1} direction="vertical">
                <Dropdown  overlay={this.menuRender()} placement="bottomRight">
                  <Button>Действия</Button>
                </Dropdown>
              </Space>,
            ]}
          />
        )}
        <Card key={3}>
          <div className="stock_filter">
            <Form
              name="filter"
              layout="vertical"
              ref={this.formFilterRef}
              initialValues={{ remember: true }}
              onFinish={() => this.filterAction()}
            >
              <Form.Item label="Название позиции" name="posname">
                <Input />
              </Form.Item>
              <CategorySelectComponent type={"stock"} />
              <ColorSelectComponent />
              <SortSelect
                hidePrice={false}
                hideGrowth={false}
                sort={() => this.filterAction()}
              />
              <Form.Item>
                <Button className="filterbtn" onClick={this.resetFilter}>
                  Сбросить фильтр
                </Button>
                <Button className="filterbtn" htmlType="submit">
                  Фильтровать
                </Button>
              </Form.Item>
            </Form>
            {this.context.usertype !== "admin" ?
            <Devilery type={"stock"}/> : ''}
          </div>
          
        </Card>

        {!this.state.loading ? (
          <div className="stocklist">
            {this.state.stockList.map((item, index) => {
              return (
                <SingleGoodComponent
                  percent={this.getPercentDiscount()}

                  hideActions={this.props.hideActions}
                  onSelect={(data) => this.props.onSelect(data)}
                  forSelect={this.props.forSelect}
                  selftcostdiscount={
                    (this.context.userdiscount || []).filter(
                      (i) => i.DISCOUNT == "selfcost"
                    ).length > 0
                  }
                  type="stock"
                  refreshList={() => this.getStock()}
                  fromStock={true}
                  setLoad={() => this.setState({ loading: true })}
                  onDelete={() => this.filterAction(false)}
                  data={{...item, count: 1}}
                  key={index}
                  percentDiscountPrice={20}
                  count={1}
                />
              );
            })}
          </div>
        ) : (
          <Loading mini={true} />
        )}
        {!this.state.loading && (
          <Pagination
            pageSizeOptions={[10, 25, 50]}
            onChange={this.onChange}
            onShowSizeChange={this.onChange}
            showSizeChanger={true}
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            defaultCurrent={1}
            total={this.state.totalSize}
          />
        )}
      </Card>,
    ];
  }
}

Stock.contextType = AppContext;

export default withRouter(Stock);

import React from "react";
import "../../App.scss";
import { Card, PageHeader, List, Tag, Spin, Badge } from "antd";
import Breadcrumb from "../common/breadcrumb";
import { withRouter, NavLink } from "react-router-dom";
import rest from "../../helpers/rest";

import * as moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru')


class TradingTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {seconds: this.props.seconds};    
  }

  componentWillMount(){
      setInterval(() => {
          this.setState({seconds: Math.floor(this.state.seconds-1)});
          if(this.state.seconds == 1){this.props.update()}          
        }, 1000)
  }

  secondsToHHMMSS(secs){
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return `Часов: ${hours} - минут: ${minutes} -  секунд: ${seconds}`
  }

  render() {
    return [
        this.state.seconds >= 0 && <div style={{marginTop: '12px', display: 'flex'}} key={1}><Tag color="green"><span style={{fontWeight: 'bold'}}>До открытия биржи</span> : {this.secondsToHHMMSS(this.state.seconds)}</Tag></div>
    ];
  }
}

export default TradingTimer;

import React from "react";
import "../../App.scss";
import { Spin, Card, Row, Col, Form, Pagination, Input } from "antd";
import Breadcrumb from "../common/breadcrumb";
import { PageHeader, Button } from "antd";
import { withRouter } from "react-router-dom";
import rest from "../../helpers/rest";

import { ArrowLeftOutlined } from '@ant-design/icons'
import Loading from "../loading";
import SingleGoodComponent from "../catalog/singlegood";
import ColorSelectComponent from "../common/colorselect";
import CategorySelectComponent from "../common/categoryselect";
import SortSelect from "../common/sortselect";
import AppHelper from "../../helpers/app";

class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Каталог", key: "/catalog/" },
    ];
    this.formFilterRef = React.createRef();
    this.searchColor = this.searchColor.bind(this);
    this.getCatalog = this.getCatalog.bind(this);
    this.emptyPosition = this.emptyPosition.bind(this);
    this.updateByFilter = this.updateByFilter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.state = {
      cataspan: 24,
      cataloglsit: [],
      categoryList: [],
      colorlist: [],
      colorlistresult: [],
      categoryloading: true,
      loading: false,
      // Флаг предсоздания новой позиции, спиннер на кнопке
      addnewload: false,
      // Фильтр
      filterParams: {},
      // По сколько отображать на странице
      pageSize: localStorage.getItem('pageSize') || 10,
      totalSize: 0,
      currentPage: 1,
    };
  }

  onChange(page, size) { 
    AppHelper.setPageSize(size);   
    this.setState({ currentPage: page, pageSize: size });    
    this.filterAction(false);
  }

  getCatalog(params = {}, loading = true) {
    this.setState({ loading: loading });
    params.page = this.state.currentPage;
    params.pagesize = this.state.pageSize;    
    rest.get("/api/Catalog", params).then((catalog) => {      
      this.setState({ cataloglsit: [] }, () => {
        this.setState(
          {
            totalSize: catalog.data.data.count,
            cataloglsit: catalog.data.data.list,
          },
          () => {
            setTimeout(() => {
              this.setState({ loading: false });
            }, 100);
          }
        );
      });
    });
  }

  componentWillMount() {
    if(!this.props.forSelect){document.title = "Каталог | Букетная.ОПТ";}
    
    this.getCatalog();

    rest.get("/api/Category").then((category) => {
      this.setState({
        categoryList: category.data.data,
        categoryloading: false,
      });
    });

    rest.get("/api/Attributes", { type: "color" }).then((colors) => {      
      this.setState({
        colorlist: colors.data.data,
        colorlistresult: colors.data.data,
      });
    });
  }

  categoryList() {
    return this.state.categoryList;
  }

  filterAction(pageSkip = true) {
    let formdata = this.formFilterRef.current.getFieldsValue();    
    let params = {};
    Object.keys(formdata).map((item) => {
      if (formdata[item] !== undefined) {        
        if (typeof formdata[item] === "Array" && formdata[item].length > 0) {
          params[item] = formdata[item].join(",");
        } else {
          params[item] = formdata[item];
        }
      }
    });
    if (pageSkip) {
      this.setState({ filterParams: params, currentPage: 1 }, () => {
        this.getCatalog(params);
      });
    } else {
      this.setState({ filterParams: params }, () => {
        this.getCatalog(params);
      });
    }
  }

  updateByFilter() {
    this.getCatalog(this.state.filterParams, false);
  }

  searchColor(e) {
    this.setState({
      colorlistresult: this.state.colorlist.filter((item) =>
        item.name.includes(e)
      ),
    });
  }

  emptyPosition() {
    this.setState({ addnewload: true });
    rest.post("/api/Catalog/empty").then((position) => {      
      this.props.history.push(`/catalog/edit/${position.data.data.insertId}`);
    });
  }

  resetFilter(){        
    this.setState({filterParams: {}})
    this.formFilterRef.current.resetFields();
    this.filterAction();

}

  render() {
    return [
      !this.props.forSelect && 
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,
      <Row key={2}>
        <Col span={this.state.cataspan}>
          <Card key={2} className="content-card">
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Каталог"
              backIcon={!this.props.forSelect ? <ArrowLeftOutlined /> : ''}
              extra={[
                !this.props.forSelect &&
                <Spin spinning={this.state.addnewload} key={1}>
                  <Button
                    key="1"
                    type="primary"
                    onClick={() => this.emptyPosition()}
                  >
                    Добавить позицию
                  </Button>
                </Spin>,
              ]}
            />
            <div className="catalog_filter">
              {this.state.categoryloading ? (
                <Loading mini={true} />
              ) : (
                <Card>
                  <Form
                    name="filter"
                    layout="vertical"
                    ref={this.formFilterRef}
                    initialValues={{ remember: true }}
                    onFinish={() => this.filterAction()}
                  >
                    <Form.Item
                      label="Название позиции"
                      name="posname"                      
                    >
                      <Input />
                    </Form.Item>
                    <CategorySelectComponent />
                    <ColorSelectComponent />
                    <SortSelect
                      hidePrice={true}
                      hideGrowth={true}
                      sort={() => this.filterAction()}
                    />

                    <Form.Item>
                    <Button className="filterbtn" onClick={this.resetFilter}>
                            Сбросить фильтр
                        </Button>
                      <Button className="filterbtn" htmlType="submit">
                        Фильтровать
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              )}
            </div>
            {!this.state.loading || this.state.categoryloading ? (
              <div className="stocklist">
                {this.state.cataloglsit.map((item, index) => {
                  return (
                    <SingleGoodComponent
                      setLoad={() => this.setState({ loading: true })}
                      forPreorder={this.props.forPreorder}
                      onDelete={() => this.updateByFilter()}
                      data={item}
                      hideActions={this.props.forSelect}
                      forSelect={this.props.forSelect}
                      onSelect={(data) => this.props.onSelect(data)}
                      key={index}
                    />
                  );
                })}
              </div>
            ) : (
              <Loading mini={true} />
            )}
            {!this.state.loading && !this.state.categoryloading && (
              <Pagination
                pageSizeOptions={[10, 25, 50]}
                onChange={this.onChange}
                onShowSizeChange={this.onChange}
                showSizeChanger={true}
                current={this.state.currentPage}
                pageSize={this.state.pageSize}
                defaultCurrent={1}
                total={this.state.totalSize}
              />
            )}
          </Card>
        </Col>
      </Row>,
    ];
  }
}

export default withRouter(Catalog);

import * as React from "react";
import {
  Breadcrumb,
  Skeleton,
  Form,
  Input,
  Select,
  Button,
  Switch,
  InputNumber,
  Tag,
  Space
} from "antd";
import { NavLink, withRouter, Link } from "react-router-dom";
import AppHelper from "../../../../helpers/app";
import rest from "../../../../helpers/rest";
import { SearchOutlined,ClearOutlined } from "@ant-design/icons";
import BreadcrumbsV2 from "../../../common/v2/bc2";
import * as moment from "moment";

import "moment/locale/ru";
moment.locale("ru");
const { Option } = Select;

class RequestsV2Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusses: [],
      tradingdayslist: [],
      filterTags: [],
      phoneUsers: [],
      phoneUsersResult: [],
      phoneChange: ''
    };
    this.searchPhone = this.searchPhone.bind(this);
    this.filterForm = React.createRef();
  }

  componentDidMount() {
    this.setState({ statusses: AppHelper.statusses });
    rest.get("/api/Trading/").then((data) => {
      let tradingtostate = [];
      data.data.data.map((item, index) => {
        tradingtostate.push({
          name: moment(item.date).format("DD MMMM YYYY"),
          code: item.id,
        });        
      });
     
      this.setState({ tradingdayslist: tradingtostate});
    });

    

  }

  clearForm = (type = undefined) => {
      if(!type){
        this.filterForm.current.resetFields();
      }else{
        let params = {};
        params[type] = undefined;
        this.filterForm.current.setFieldsValue(params);
      }      
      this.changeForm();
      setTimeout(() => {
        this.submit();
      }, 1)
  }


  // Срабатывает при изменении формы
  changeForm = () => {
      let values = this.filterForm.current.getFieldsValue();
      let formTags = [];
      
      Object.keys(values).filter(i => values[i]).map(i => {
          let element = {values: []};
          element.type = i;
          if(i === 'status'){
            element.label = 'Статус';
            element.values.push(AppHelper.getRequestStatus(values[i]).label)
          }


          if(i === 'request__number'){
            element.label = 'Номер заявки';
            element.values.push(values[i])
          }

          if(i === 'tradingday__number'){
            element.label = 'Торговый день';
            
            let tdvalue = this.state.tradingdayslist.filter(e => e.code == values[i])
            if(tdvalue.length > 0){
                element.values.push(tdvalue[0].name)
            }
            
          }

          if(i === 'phone'){
            element.label = 'Телефон';
            element.values.push(values.phone)
          }

          if(i === 'fromstock'){
            element.label = 'Только со склада';
            element.values.push('Да')
          }
          formTags.push(element)
      })
      this.setState({filterTags: formTags})
  }
  

  // рендер тегов формы
  getFilterTags = () => {
      return(
          <Space size={0.3}>
              {
                  this.state.filterTags.map(i => <Tag onClose={() => this.clearForm(i.type)} closable key={i.type}><b>{i.label}:</b>{' '+i.values.join(',')}</Tag>)
              }
          </Space>
      )
  }

  submit = () => {
    let filter = this.filterForm.current.getFieldsValue();
    let params = {
      status: filter.status,
      tradingday: filter.tradingday__number,
      requestid: filter.request__number,
      forstock: filter.fromstock,
      phone: filter.phone
    }
    this.props.onFilter(params);
  }

  searchPhone(e) {
    let params = {}
    params.filter = e;
    rest.get("/api/Account/getphoneusers", params).then((data) => {
      if(e != ''){
        this.setState({phoneUsersResult:  data.data.data})
      }
      else{
        this.setState({phoneUsersResult:  []})
      }
    });
    
  }

  render() {
    const breadcrumb = [
      {name: 'Букетная.ОПТ', path: '/stock'},
      {name: 'Заявки V2', path: false},
    ]
    return [
      <div key={1} className="request__v2_filter">
        <div key={1} className="request__v2_filter__inner">
          <BreadcrumbsV2 list={breadcrumb} key={4}>
            <div className="request__top_actions">
              <Button
                shape="round"
                type="primary"
                onClick={() => this.props.history.push("/simplerequest")}
              >
                Быстрая заявка
              </Button>
            </div>
          </BreadcrumbsV2>
          <h2 key={1} className="title">
            Заявки
          </h2>
          <Form
            key={2}
            name="request__filterform"
            layout="vertical"
            autoComplete="off"
            ref={this.filterForm}
            onFinish={() => this.submit()}
            onChange={() => this.changeForm()}
          >
            <div className="form__row">
              <div className="filter__controls_item">
                <Form.Item
                  valuePropName="checked"
                  name="fromstock"
                  label="Со склада"
                  
                >
                  <Switch onChange={() => this.changeForm()} size="large" />
                </Form.Item>
              </div>
            </div>
            <div className="form__row">
              <div key={1} className="filter__controls">
                <div className="filter__controls_item">
                  <Form.Item name="request__number" label="Номер заявки">
                    <InputNumber size="large" />
                  </Form.Item>
                </div>
                {this.state.tradingdayslist.length > 0 &&  <div key={3} className="filter__controls_item">                
                  <Form.Item name="tradingday__number" label="Торговый день">
                    <Select size="large" onChange={() => this.changeForm()}>                        
                    {this.state.tradingdayslist.map((item, index) => {
                        return (
                          <Option value={item.code} key={item.code}>{item.name}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div> }
                
                <div key={2} className="filter__controls_item">
                  <Form.Item name="status" label="Статус заявки">
                    <Select size="large" onChange={() => this.changeForm()}>
                      {this.state.statusses.map((item, index) => {
                        return (
                          <Option value={item.name} key={index}>
                            <Tag
                              color={
                                AppHelper.getRequestStatus(item.name).color
                              }
                            >
                              {item.label}
                            </Tag>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>

                  
                <div key={33} className="filter__controls_item">                
                  <Form.Item name="phone" label="Номер телефона">
                    <Select 
                      showSearch
                      allowClear
                      size="large" 
                      onSearch={this.searchPhone}
                      optionFilterProp="children"
                      onChange={() => this.changeForm()}>                        
                    {this.state.phoneUsersResult.map((item, i) => {
                        return (
                          <Option value={item.phone} key={i.toString(36) + i}>{item.phone}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div> 

                <div key={4} className="filter__controls_item btn">
                  <Button
                    size="large"
                    icon={<SearchOutlined />}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                  >
                    Показать заявки
                  </Button>
                </div>
                <div key={5} className="filter__controls_item btn">
                  <Button
                    size="large"
                    icon={<ClearOutlined />}
                    type="link"
                    danger     
                    onClick={() => this.clearForm()}               
                  >
                    Сбросить
                  </Button>
                </div>
                
                
              </div>
            </div>            
          </Form>
          <div className="filter__tags">              
                  {this.getFilterTags()}              
          </div>
          <div className='requests__link-old_page'><Link to={`/usersrequestsold`} >Перейти на старую версию</Link></div>
        </div>
      </div>,
    ];
  }
}

export default withRouter(RequestsV2Filter);

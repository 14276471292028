import * as React from "react";
import { Select, Form } from "antd";
import rest from "../../../helpers/rest";
const { Option } = Select;

class TransactionTypeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        typeList: [],
        loading: true
    }
    this.getTypes = this.getTypes.bind(this);
  }

  componentDidMount(){
      this.getTypes();
  }

  getTypes(){
      rest.get('/api/Transactions/types').then(data => {
          let types = data.data.data;
          if(this.props.notusers){types = types.filter(i => ![1,2].includes(i.id))}
          this.setState({typeList: types, loading: false})
      })
  }

  render() {
    return (
      <Form.Item label="Тип транзакции" name="transactiontype" rules={[{
        required: this.props.required || false,
        message: 'Выберите тип'
      }]}>
        <Select
          style={{ width: "100%" }}
          placeholder="Выберите тип"
          loading={this.state.loading}
        >
          {this.state.typeList.map((item, i) => {
            return (
              <Option
                label={item.name}
                value={item.id}
                key={i.toString(36) + i}
              >
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default TransactionTypeSelector;

import React from 'react';
import '../../App.scss';
import {Link} from "react-router-dom";
import Breadcrumb from '../common/breadcrumb';
import {Row, Col,Card, PageHeader, Tabs, Collapse, Spin, Tag } from 'antd'
import StockCartRequest from './stockcart';
import ExchangeCartRequest from './exchangecart/index'
import rest from '../../helpers/rest';
import * as moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru')
const { TabPane } = Tabs;
const { Panel } = Collapse;


class Cart extends React.Component{
    constructor(props){
        super(props);
        this.breadcrumbs = [
            { title: "Букетная.ОПТ", key: "/" },
            { title: "Корзина", key: "/catalog/" }
        ];
        this.state = {
            stockrequests: [],
            stockloaing: true,
            exchangeloading: true,
            exchangeloader: false,
            exchangerequests: []
        }
        this.updateRequests = this.updateRequests.bind(this);
    }

    updateRequests(){
        rest.get('/api/Requests/getactive', {type: 'stock'}).then(data => {            
            this.setState({stockrequests: data.data.data, stockloaing: false})
        })
        rest.get('/api/Requests/getactive', {type: 'exchange'}).then(data => {            
            this.setState({exchangerequests: data.data.data, exchangeloading: false})
        })
    }
    
    componentWillMount(){
        document.title = 'Корзина | Букетная.ОПТ'
        // Получаем заявки со склада
        rest.get('/api/Requests/getactive', {type: 'stock'}).then(data => {            
            this.setState({stockrequests: data.data.data, stockloaing: false})
        })
        rest.get('/api/Requests/getactive', {type: 'exchange'}).then(data => {            
            this.setState({exchangerequests: data.data.data, exchangeloading: false})
        })
    }

    getRequestStatus(item, index){
        let result = '';
        if(item.status == 'active'){
            result = <Tag key={index} color="green">Активная</Tag>
        }else{
            result = <Tag key={index} color="orange">Незивестный статус</Tag>
        }
        return result;
    }

    render(){
        return[
            <Breadcrumb key={1} style={{ margin: '16px 0' }} list={this.breadcrumbs} />,
            <Row key={2}>
                <Col span={24}>
                    <Card className="content-card">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => this.props.history.goBack()}
                        title="Корзина"                        
                    />
                    <Spin spinning={this.state.stockloaing || this.state.exchangeloader}>
                    <div className="cart_tabber">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Склад" key="1">
                        
                        {this.state.stockrequests.length > 0 && <Collapse accordion>
                            {
                                this.state.stockrequests.map((item, index) => {
                                    return <Panel header={`Заявка №${item.id}`}  extra={[this.getRequestStatus(item, index)]} key={index}><StockCartRequest updateRequests={() => this.updateRequests()} data={item} /></Panel>                        
                                })
                            }
                            
                        </Collapse>}
                        {
                            (this.state.stockrequests.length === 0 && !this.state.exchangeloading) && <h1>Корзина пуста</h1>
                        }
                        
                        </TabPane>
                        <TabPane tab="Биржа" key="2">
                         
                        {this.state.exchangerequests.length > 0 && <Collapse accordion>
                            {
                                this.state.exchangerequests.map((item, index) => {
                                    return <Panel header={`Заявка №${item.id}`}  extra={[
                                        this.getRequestStatus(item, index),
                                    item.tddate && <Tag key={index+1}>Торговый день: {moment(item.tddate).format('DD MMMM YYYY')}</Tag>
                                    ]} key={index}><ExchangeCartRequest updateRequests={() => this.updateRequests()} data={item} /></Panel>                        
                                })
                            }
                            
                        </Collapse>}
                        {
                            (this.state.exchangerequests.length === 0 && !this.state.exchangeloading) && <h1>Корзина пуста</h1>
                        }
                        </TabPane>                        
                    </Tabs>
                    </div>
                    </Spin>
                      
                    </Card>
                </Col>
            </Row>
        ]
    }
}

export default Cart;
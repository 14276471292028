import React from "react";
import "../../../App.scss";
import {
  Modal,
  Button,
  Row,
  Col,
  PageHeader,
  Card,
  Calendar,
  Badge,
  Spin,
  Popconfirm,
  message,

} from "antd";
import { withRouter, NavLink } from "react-router-dom";
import * as moment from "moment";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import rest from "../../../helpers/rest";
import AppContext from "../../.../../common/appContext";
moment.locale("ru");

class TouchCaneldar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarList: [],
      loading: true,
      drawerload: false,
    };
   
  }

  componentDidMount() {
    this.getCalendarData();
  }


  getCalendarData = () => {
    rest.get("/api/Account/touchestocall").then((data) => {
      this.setState({ calendarList: data.data.data, loading: false });
    });
  }


  dateCellRender = (value) => {
    let preorders = this.state.calendarList.filter(
      (i) => 
        moment(i.NEXT_CALL).format("DD MM YYYY") === value.format("DD MM YYYY") 
  
    );
    
    if (preorders.length > 0 && moment(preorders['0'].NEXT_CALL).format("DD MM YYYY") > moment(new Date()).format("DD MM YYYY")) {
      //console.log(preorders['0'].NEXT_CALL);
      return (
        <div className="calendar__badge">
          <Badge
            className="site-badge-count-109"
            count={preorders.length}
            style={{ backgroundColor: "#52c41a" }}
          />
        </div>
      );
    }

    if (preorders.length > 0 && moment(preorders['0'].NEXT_CALL).format("DD MM YYYY") < moment(new Date()).format("DD MM YYYY")) {
      //console.log(preorders['0'].NEXT_CALL);
      return (
        <div className="calendar__badge">
          <Badge
            className="site-badge-count-109"
            count={preorders.length}
            style={{ backgroundColor: "#f44336" }}
          />
        </div>
      );
    }

    if (preorders.length > 0 && moment(preorders['0'].NEXT_CALL).format("DD MM YYYY") == moment(new Date()).format("DD MM YYYY")) {
      //console.log(preorders['0'].NEXT_CALL);
      return (
        <div className="calendar__badge">
          <Badge
            className="site-badge-count-109"
            count={preorders.length}
            style={{ backgroundColor: "#ffca28" }}
          />
        </div>
      );
    }
  };

  monthCellRender = (value) => {
    let preorders = this.state.calendarList.filter(
      (i) => moment(i.NEXT_CALL).format("MM YYYY") === value.format("MM YYYY")
    );
    if (preorders.length > 0) {
      return (
        <div className="calendar__badge">
          <Badge
            className="site-badge-count-109"
            count={preorders.length}
            style={{ backgroundColor: "#52c41a" }}
          />
        </div>
      );
    }
  };

  removeNextCall = (id) => {
    this.setState({loading: true})
    this.context.closeDrawer();    
    rest.update('/api/Account/setdonenextcall', {id:id}).then(data => {
      message.success('Успешно');
      this.getCalendarData();
    })
  }

  selectCell = (value) => {
    let calls = this.state.calendarList.filter(i => moment(i.NEXT_CALL).format('DD MM YYYY') == value.format('DD MM YYYY'))
    if(calls.length == 0) return;
    this.context.openDrawer(
      `Просмотр`,
      <Spin spinning={this.state.drawerload}>
      <div style={{minWidth: '400px'}}>
        {
          calls.map((i, index) => {
            return <div key={index} className="next__call">
              <div className="next__call_param">
                <span className="label">ФИО</span>
                <span className="value"><NavLink to={`/profile/${i.CLIENTID}`}>
                {i.fname} {i.sname}
            </NavLink></span>
              </div>
              <div className="next__call_param">
                <span className="label">Дата прошлого звонка</span>
                <span className="value">{moment(i.createdAt).format('DD MMMM YYYY')}</span>
              </div>
              <div className="next__call_param">
                <span className="label">Дата следующего звонка</span>
                <span className="value">{moment(i.NEXT_CALL).format('DD MMMM YYYY')}</span>
              </div>
              <div className="next__call_param">
                <span className="label">Номер телефона</span>
                <span className="value">{i.phone}</span>
              </div>       
              {i.COMMENT && <div className="next__call_param next__call_param_comment">
                <span className="label">Комментарий</span>
                <span className="value">{i.COMMENT}</span>
              </div>}
              <div className="next__call_param">                
                {/* <Popconfirm okText="Да" cancelText="Нет" title="Звонок действительно совершен?"  onConfirm={() => this.removeNextCall(i.id)}> */}
                  <a href="#" onClick={() => {
                    Modal.confirm({
                      title: 'Перейти в профиль пользователя?',
                      content: 'Необходимо назначить новый звонок для закрытия текущего',
                      onOk: () => {
                        this.props.history.push({
                          pathname: `/profile/${i.CLIENTID}?tabkey=3`,
                          state: {tabkey: "3"}
                        })
                      }
                    })
                  }}>Звонок совершен</a>
                {/* </Popconfirm> */}
              </div>     
              
            </div>
          })
        }
      </div>
      </Spin>
    );
  }

 

  onPanelChange(value, mode) {
    console.log(value.format("YYYY-MM-DD"), mode);
  }
  render() {
    return (
      <Row key={2}>
        <Col span={24}>
          <Card className="content-card">
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Календарь звонков"
            />
            <Spin spinning={this.state.loading}>
            <Calendar
              monthCellRender={this.monthCellRender}
              fullscreen={true}
              locale={locale}
              dateCellRender={this.dateCellRender}
              onPanelChange={this.onPanelChange}
              onSelect={this.selectCell}
            />
            </Spin>
          </Card>
        </Col>
      </Row>
    );
  }
}


TouchCaneldar.contextType = AppContext;

export default withRouter(TouchCaneldar);

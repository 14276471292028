import React from "react";
import "../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import AppContext from "../../components/common/appContext";
import Breadcrumb from "../common/breadcrumb";
import { EyeOutlined, EditOutlined,DeleteOutlined } from "@ant-design/icons";

import {
  Spin,
  Card,
  Row,
  Col,
  Tooltip,
  Form,
  Input,
  Skeleton,
  Modal,
  message,
  InputNumber,
  Select,
  Switch,
  DatePicker
} from "antd";
import { PageHeader, Menu, Dropdown, Button, Tabs } from "antd";
import rest from "../../helpers/rest";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../helpers/app";
import BalanceHistory from "../user-cart/balancehistory/index";
import UsersRequestMini from "../user-cart/urequests/index";
import TouchesHistory from "../user-cart/touches/index";
import UPreorders from '../user-cart/upreorders/index';
moment.locale("ru");
const { TabPane } = Tabs;
const { Option } = Select;

class UserCart extends React.Component {
  constructor(props) {
    super(props);
    this.formBalanceRef = React.createRef();
    this.formReturnRef = React.createRef();
    //this.bHistoryRef = React.forwardRef();
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Профиль пользователя", key: "/" },
    ];

    this.state = {
      cataspan: 24,
      loading: true,
      userinfo: null,
      RequestIssueCount: null,
      RequestTotalPrice: null,
      balanseVisible: false,
      returnVisible: false,
      balancUpdateLoad: false,
      payments: [],
      tabkey: "1",
      userId: this.props.userid ? this.props.userid : this.props.match.params.id,
      isModalVisible: "",
      address: '',
      loadingAddress: false,
      disabled: '',
      isModalDateVisible: "",
      date: '',
      loadingDate: false,
      disabledDate: '',
    };

    this.getMainUserInfo = this.getMainUserInfo.bind(this);
    this.getRequestIssueCount = this.getRequestIssueCount.bind(this);
    this.getRequestTotalPrice = this.getRequestTotalPrice.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  getMainUserInfo(obj = {}) {
    return rest.get("/api/Account/", obj);
  }

  // Количество выданных заказов
  getRequestIssueCount(id) {
    return rest.get("/api/Requests/requestissuecount", { userid: id });
  }

  getRequestTotalPrice(id) {
    return rest.get("/api/Requests/requesttotalprice", { userid: id });
  }

  componentDidMount() {
    let tabkey = new URLSearchParams(this.props.location.search).get("tabkey");
    if(tabkey){
      this.setState({tabkey: tabkey})
    }

    rest.get("/api/Transactions/paytypes").then((data) => {
      this.setState({ payments: data.data.data });
    });

    // Количество выданных заказов
    this.getRequestIssueCount(this.state.userId || null).then(
      (data) => {
        this.setState({ RequestIssueCount: data.data.data.count });
      }
    );

    this.getRequestTotalPrice(this.state.userId || null).then(
      (data) => {
        this.setState({ RequestTotalPrice: data.data.data.sum });
      }
    );

    //this.getRequestTotalPrice

    if (this.state.userId && this.context.usertype != "admin") {
      this.props.history.push("/stock");
    } else {
      this.getMainUserInfo(
        this.state.userId ? { userid: this.state.userId } : {}
      ).then((data) => {
        this.setState(
          { loading: false, userinfo: { ...data.data.data[0] } },
          () => {
            let regdate = moment(this.state.userinfo.regdate);
            let cdate = moment(new Date());
            let diffdates = cdate.diff(regdate, "days");
            let years = Math.floor(diffdates / 365);
            let days = diffdates % 365;

            this.setState({ regdaysdiff: days, regyearsdiff: years });
          }
        );
      });
    }
  }

  minValidator(rule, value, callback) {
    if (value <= 0) {
      callback("Введите число больше 0");
    } else {
      callback();
    }
  }

  onSubmit(mreturn = false) {
    if (this.state.balancUpdateLoad) return;
    let form = mreturn
      ? this.formReturnRef.current.getFieldsValue()
      : this.formBalanceRef.current.getFieldsValue();
    this.setState({ balancUpdateLoad: true });
    rest
      .update("/api/Account/updatebalance", {
        ...form,
        userid: this.state.userId,
        mreturn: mreturn,
      })
      .then((data) => {
        this.setState({
          balancUpdateLoad: false,
          balanseVisible: false,
          returnVisible: false,
          loading: true,
        });
        this.getMainUserInfo(
          this.state.userId
            ? { userid: this.state.userId }
            : {}
        ).then((data) => {
          this.setState(
            { loading: false, userinfo: { ...data.data.data[0] } },
            () => {
              let regdate = moment(this.state.userinfo.regdate);
              let cdate = moment(new Date());
              let diffdates = cdate.diff(regdate, "days");
              let years = Math.floor(diffdates / 365);
              let days = diffdates % 365;
              this.setState({ regdaysdiff: days, regyearsdiff: years });
              this.child.getBalanceInfo();
              //this.bHistoryRef.current.getBalanceInfo()
            }
            
          );
        });
        if (this.formBalanceRef.current)
          this.formBalanceRef.current.resetFields();
        if (this.formReturnRef.current)
          this.formReturnRef.current.resetFields();
      })
      .catch((err) => {
        this.setState({ balancUpdateLoad: false });
        //console.log(err.response.data.data.detail)
        message.error(err.response.data.data.detail);
      });
     
  }

  
 

 

  render() {
    const menu = (
      <Menu>
        <Menu.Item onClick={() => this.setState({ balanseVisible: true })}>
          Пополнить баланс
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ returnVisible: true })}>
          Возврат средств
        </Menu.Item>
      </Menu>
    );
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,
      <Col key={2} span={this.state.cataspan}>
        <Card className="content-card">
          <PageHeader
            className="site-page-header"
            onBack={() => this.props.history.goBack()}
            title="Профиль"
            extra={[
              this.context.usertype == "admin" && (
                <Dropdown key={1} overlay={menu} placement="bottomCenter" arrow>
                  <Button>Действия</Button>
                </Dropdown>
              ),
              <Modal
                key={2}
                title="Пополнение баланса"
                visible={this.state.balanseVisible}
                onOk={() => this.formBalanceRef.current.submit()}
                onCancel={() => this.setState({ balanseVisible: false })}
              >
                <Spin spinning={this.state.balancUpdateLoad}>
                  <div className="balanceform">
                    <Form
                      onFinish={() => this.onSubmit(false)}
                      onFinishFailed={() => message.warn("Заполните данные")}
                      name="basic"
                      className="useredit"
                      ref={this.formBalanceRef}
                      layout="vertical"
                    >
                      <Form.Item
                        name="balance"
                        label="Сумма пополнения"
                        rules={[
                          {
                            required: true,
                            message: "Пожалуйста введите сумму пополнения",
                          },
                          {
                            validator: this.minValidator,
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                            "₽"
                          }
                          min={1}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Способ оплаты"
                        name="payment"
                        rules={[
                          {
                            required: true,
                            message: "Выберите способ оплаты",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Выберите способ"
                          loading={this.state.loading}
                        >
                          {this.state.payments.map((item, i) => {
                            return (
                              <Option
                                label={item.name}
                                value={item.id}
                                key={i.toString(36) + i}
                              >
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item name="fiscal" label="Фискализировать">
                        <Switch />
                      </Form.Item>
                    </Form>
                  </div>
                </Spin>
              </Modal>,
              <Modal
                key={3}
                title="Возврат средств"
                visible={this.state.returnVisible}
                onOk={() => this.formReturnRef.current.submit()}
                onCancel={() => this.setState({ returnVisible: false })}
              >
                <Spin spinning={this.state.balancUpdateLoad}>
                  <div className="balanceform">
                    <Form
                      onFinish={() => this.onSubmit(true)}
                      onFinishFailed={() => message.warn("Заполните данные")}
                      name="basic"
                      className="useredit"
                      ref={this.formReturnRef}
                      layout="vertical"
                    >
                      <Form.Item
                        name="balance"
                        label="Сумма возврата"
                        rules={[
                          {
                            required: true,
                            message: "Пожалуйста введите сумму возврата",
                          },
                          {
                            validator: this.minValidator,
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                            "₽"
                          }
                          min={1}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                </Spin>
              </Modal>,
            ]}
          />
          <div className="userinfo" style={{ padding: "0px 20px" }}>
            {this.state.loading ? (
              <Skeleton />
            ) : (
              <div className="userinfo_short" style={{ textAlign: "left" }}>
                <div className="fio">
                  <span>{`${this.state.userinfo.fname} ${this.state.userinfo.sname}`}</span>
                  {this.state.userinfo.orgName && (
                    <span className="org">{`${this.state.userinfo.orgName}`}</span>
                  )}
                </div>
                <div className="regdata">
                  <span>{`На сайте ${
                    this.state.regyearsdiff > 0 ? this.state.regyearsdiff : ""
                  } ${
                    this.state.regyearsdiff > 0
                      ? AppHelper.num_word(this.state.regyearsdiff, [
                          "год",
                          "года",
                          "лет",
                        ])
                      : ""
                  } 
                  ${this.state.regdaysdiff} ${AppHelper.num_word(
                    this.state.regdaysdiff,
                    ["день", "дня", "дней"]
                  )}`}</span>
                </div>

                <div className="balance">
                  <span>
                    Баланс:{" "}
                    <span
                      className={`value ${
                        this.state.userinfo.balance >= 0 ? "plus" : "minus"
                      }`}
                    >
                      {`${AppHelper.toCurrency(
                        this.state.userinfo.balance,
                        "RUB",
                        "Ru-ru"
                      )}`}
                    </span>
                  </span>
                </div>
                <div>
                
                </div>
                
              <div className="userinfo_address">
                  {!this.state.loadingAddress ? this.state.userinfo.address ? 
                  <div>
                    Адрес доставки: <span className="userinfo_address-text"><NavLink to={`/users/${this.state.userId}`}> {this.state.userinfo.address + " "} </NavLink></span>
                  </div> :
                  <div>Адрес доставки: <NavLink to={`/users/${this.state.userId}`}> установить </NavLink> </div>  : <Skeleton />}
              </div>
              <div className="userinfo_birthday">
                  {!this.state.loadingAddress ? this.state.userinfo.directorbirthday  ? 
                  <div>
                    Дата рождения: 
                    <span className="userinfo_address-text">
                      <NavLink to={`/users/${this.state.userId}`}> 
                      {moment(this.state.userinfo.directorbirthday).format("DD.MM.YYYY") + " "}
                      </NavLink> 
                      </span>
                  </div> : 
                  <div> 
                    Дата рождения: 
                    <NavLink to={`/users/${this.state.userId}`}> установить </NavLink> </div>  : <Skeleton /> 
                  }</div>
              <div className="userinfo_date">
                  {!this.state.loadingAddress ? this.state.userinfo.educationDate  ? 
                  
                  <div>
                    Дата образования салона: <span className="userinfo_address-text"><NavLink to={`/users/${this.state.userId}`}> {moment(this.state.userinfo.educationDate).format("DD.MM.YYYY") + " "}</NavLink> </span>
                  </div>: <div>Дата образования салона: <NavLink to={`/users/${this.state.userId}`}>установить</NavLink></div>   : <Skeleton />}
              </div>
              
            </div>
            )}
          </div>
          <div className="userstatic">
            {this.state.RequestIssueCount == null ? (
              <Skeleton />
            ) : (
              <div className="item">
                <p className="label">Выданных заказов</p>
                <p className="value">{this.state.RequestIssueCount}</p>
              </div>
            )}
            {this.state.RequestTotalPrice == null ? (
              <Skeleton />
            ) : (
              <div className="item">
                <p className="label">Сумма заказов</p>
                <p className="value">
                  {AppHelper.toCurrency(
                    this.state.RequestTotalPrice,
                    "RUB",
                    "Ru-ru"
                  )}
                </p>
              </div>
            )}
          </div>
          <div>
     
          </div>
          <div className="tabs" style={{ padding: "0px 20px" }}>
            <Tabs defaultActiveKey={this.state.tabkey}>
              <TabPane tab="История операций" key="1">
                <BalanceHistory
                  onRef={(ref) => (this.child = ref)}
                  userid={this.state.userId}
                />
              </TabPane>
              <TabPane tab="История заказов" key="2">
                <UsersRequestMini
                  onRef={(ref) => (this.childRequests = ref)}
                  userid={this.state.userId}
                />
              </TabPane>
              {this.state.userId && this.context.usertype == "admin" && (
                <TabPane tab="Контакт с клиентом" key="3">
                  {/* <UsersRequestMini onRef={ref => (this.childRequests = ref)} userid={this.state.userId} /> */}
                  <TouchesHistory userid={this.state.userId} />
                </TabPane>
              )}

              {this.state.userId && this.context.usertype == "admin" && (
                <TabPane tab="Предзаказы" key="4">
                  {/* <UsersRequestMini onRef={ref => (this.childRequests = ref)} userid={this.state.userId} /> */}
                  {/* <TouchesHistory userid={this.state.userId} /> */}
                  <UPreorders userid={this.state.userId} />
                </TabPane>
              )}
            </Tabs>
          </div>
        </Card>
      </Col>,
    ];
  }
}

UserCart.contextType = AppContext;

export default withRouter(UserCart);

import React from "react";
import "../../../App.scss";
import { message, Skeleton } from "antd";
import Breadcrumb from "../../common/breadcrumb";
import { Card, Row, Col, List, Modal, Button, Form, Input, Spin } from "antd";
import AppContext from "../../../components/common/appContext";
import { PageHeader, Popconfirm } from "antd";
import rest from "../../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../../loading";
import { withRouter } from "react-router-dom";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class TsTypes extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Справочники", key: "/directory/" },
      { title: "Тип транзакций", key: "/colors/" },
    ];
    this.formRefAdd = React.createRef();
    this.state = {
      cataspan: 24,
      typeList: [],
      modalload: false,
      loading: false
    };
    document.title = "Типы транзакций | Букетная.ОПТ";
    this.getTypes = this.getTypes.bind(this);
    this.addModalShow = this.addModalShow.bind(this);
    this.deteleType = this.deteleType.bind(this);
  }

  deteleType(id){
      this.setState({loading: true});
      rest.delete('/api/Transactions/deletetype', {id:id}).then(data => {
          this.getTypes();
      })
  }

  componentWillMount() {
    this.getTypes();
  }

  getTypes() {
    this.setState({loading: true})
    rest.get("/api/Transactions/typestoedit").then((data) => {      
      this.setState({ typeList: data.data.data, loading: false });
    });
  }

  addModalShow() {
    //this.formRef.current.getFieldsValue();
    this.setState({ addModalShow: true }, () => {
        this.formRefAdd.current.resetFields();
    });
    
  }

  onSubmitAdd() {
    let formdata = this.formRefAdd.current.getFieldsValue();
    this.setState({modalload: true})
    rest.post('/api/Transactions/type', formdata).then(data => {
        this.setState({false: true, addModalShow: false});
        this.getTypes();
    })
  }

  render() {
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card">
        <Row key={2}>
          <Col span={this.state.cataspan}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Тип транзакций"
              extra={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.addModalShow()}
                >
                  Добавить
                </Button>,
              ]}
            />
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
        <Row>
            
          <Col
            span={24}
            style={{ padding: "0px 20px" }}
            className="categorylist"
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.typeList}
              locale={{ emptyText: "Типы не найдены" }}
              renderItem={(item, index) => (
                <Skeleton
                  key={index}
                  title={false}
                  loading={this.state.loading}
                  active
                >
                  <List.Item
                    className="coloranizationList"
                    actions={[                      
                      <Popconfirm
                        title="Удалить тип?"
                        onConfirm={() => this.deteleType(item.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <a key="list-loadmore-more">
                          <DeleteOutlined />
                        </a>
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta title={item.name} />
                  </List.Item>
                </Skeleton>
              )}
            ></List>
          </Col>
        </Row>
        </Spin>
      </Card>,
      <Modal onCancel={() => {this.setState({addModalShow: false})}} onOk={() => this.formRefAdd.current.submit()} key={32} visible={this.state.addModalShow} title="Добавить новый тип">
          <Spin spinning={this.state.modalload}>
        <Form
        {...layout}
          name="addtypeform"
          ref={this.formRefAdd}
          onFinish={() => this.onSubmitAdd()}
        >
          <Form.Item
            label="Название типа"
            name="name"
            rules={[
              { required: true, message: "Пожалуйста введите название типа" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
        </Spin>
      </Modal>,
    ];
  }
}

TsTypes.TsTypes = AppContext;

export default withRouter(TsTypes);

import React from "react";
import "../../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import AppContext from "../../../components/common/appContext";
import Breadcrumb from "../../common/breadcrumb";

import {
  Spin,
  Card,
  Row,
  Col,
  Tooltip,
  Form,
  Input,
  Skeleton,
  Modal,
  message,
  InputNumber,
} from "antd";
import { PageHeader, Menu, Dropdown, Button, Tabs } from "antd";
import rest from "../../../helpers/rest";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../../helpers/app";
import BalanceHistory from "../../user-cart/balancehistory/index";
import UsersRequestMini from '../../user-cart/urequests/index'
moment.locale("ru");
const { TabPane } = Tabs;

class ManagerCart extends React.Component {
  constructor(props) {
    super(props);
    this.formBalanceRef = React.createRef();
    this.formReturnRef = React.createRef();
    //this.bHistoryRef = React.forwardRef();
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Профиль менеджера", key: "/" },
    ];

    this.state = {
      cataspan: 24,
      loading: true,
      userinfo: null,
      RequestIssueCount: null,
      RequestTotalPrice: null,
      balanseVisible: false,
      returnVisible: false,
      balancUpdateLoad: false
    };

    this.getMainUserInfo = this.getMainUserInfo.bind(this);
    this.getRequestIssueCount = this.getRequestIssueCount.bind(this);
    this.getRequestTotalPrice = this.getRequestTotalPrice.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getMainUserInfo(obj = {}) {
    return rest.get("/api/Account/", obj);
  }

  // Количество выданных заказов
  getRequestIssueCount(id) {
    return rest.get("/api/Requests/requestissuecount", { userid: id, byManager: true });
  }

  getRequestTotalPrice(id) {
    return rest.get("/api/Requests/requesttotalprice", { userid: id });
  }

  componentDidMount() {
    // Количество выданных заказов
    this.getRequestIssueCount(this.props.match.params.id || null).then(
      (data) => {
        this.setState({ RequestIssueCount: data.data.data.count });
      }
    );

    this.getRequestTotalPrice(this.props.match.params.id || null).then(
      (data) => {
        this.setState({ RequestTotalPrice: data.data.data.sum });
      }
    );

    //this.getRequestTotalPrice

    if (this.props.match.params.id && this.context.usertype != "admin") {
      this.props.history.push("/stock");
    } else {
      this.getMainUserInfo(
        this.props.match.params.id ? { userid: this.props.match.params.id } : {}
      ).then((data) => {
        this.setState(
          { loading: false, userinfo: { ...data.data.data[0] } },
          () => {
            let regdate = moment(this.state.userinfo.regdate);
            let cdate = moment(new Date());
            let diffdates = cdate.diff(regdate, "days");
            let years = Math.floor(diffdates / 365);
            let days = diffdates % 365;
            
            this.setState({ regdaysdiff: days, regyearsdiff: years });
          }
        );
      });
    }
  }

  minValidator(rule, value, callback){
      
      if(value <= 0){
          callback('Введите число больше 0')
      }else{
        callback();
      }
      
  }

  onSubmit(mreturn = false){

    let form = mreturn ? this.formReturnRef.current.getFieldsValue() :this.formBalanceRef.current.getFieldsValue();
    this.setState({balancUpdateLoad: true})
    rest.update('/api/Account/updatebalance', {...form, userid: this.props.match.params.id, mreturn: mreturn}).then(data => {
        
        this.setState({balancUpdateLoad: false, balanseVisible: false, returnVisible: false, loading: true});
        this.getMainUserInfo(this.props.match.params.id ? { userid: this.props.match.params.id } : {}).then(data => {
            this.setState(
                { loading: false, userinfo: { ...data.data.data[0] } },
                () => {
                  let regdate = moment(this.state.userinfo.regdate);
                  let cdate = moment(new Date());
                  let diffdates = cdate.diff(regdate, "days");
                  let years = Math.floor(diffdates / 365);
                  let days = diffdates % 365;                  
                  this.setState({ regdaysdiff: days, regyearsdiff: years });
                  this.child.getBalanceInfo();
                  //this.bHistoryRef.current.getBalanceInfo()
                }
              );
        })
        if(this.formBalanceRef.current) this.formBalanceRef.current.resetFields()
        if(this.formReturnRef.current) this.formReturnRef.current.resetFields()                
    }).catch(err => {
        this.setState({balancUpdateLoad: false});
        //console.log(err.response.data.data.detail)
        message.error(err.response.data.data.detail)
    } )
  }

  render() {
   
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,
      <Col key={2} span={this.state.cataspan}>
        <Card className="content-card">
          <PageHeader
            className="site-page-header"
            onBack={() => this.props.history.goBack()}
            title="Профиль менеджера"
          />
          <div className="userinfo" style={{ padding: "0px 20px" }}>
            {this.state.loading ? (
              <Skeleton />
            ) : (
              <div className="userinfo_short" style={{ textAlign: "left" }}>
                <div className="fio">
                  <span>{`${this.state.userinfo.fname} ${this.state.userinfo.sname}`}</span>
                  {this.state.userinfo.orgName && (
                    <span className="org">{`${this.state.userinfo.orgName}`}</span>
                  )}
                </div>
                <div className="regdata">
                  <span>{`На сайте ${this.state.regyearsdiff>0 ? this.state.regyearsdiff : ''} ${this.state.regyearsdiff>0 ? AppHelper.num_word(this.state.regyearsdiff, ["год","года","лет",]) : ''} 
                  ${this.state.regdaysdiff} ${AppHelper.num_word(this.state.regdaysdiff,["день", "дня", "дней"])}`}</span>
                </div>

                
              </div>
            )}
          </div>
          <div className="userstatic">
            {this.state.RequestIssueCount == null ? (
              <Skeleton />
            ) : (
              <div className="item">
                <p className="label">Выданных заказов</p>
                <p className="value">{this.state.RequestIssueCount}</p>
              </div>
            )}            
          </div>
          <div className="tabs" style={{ padding: "0px 20px" }}>
            <Tabs defaultActiveKey="1">              
              <TabPane tab="Заказы менеджера" key="1">              
              <UsersRequestMini onRef={ref => (this.childRequests = ref)} userid={this.props.match.params.id} formanager={true} />
              </TabPane>              
            </Tabs>
          </div>
        </Card>
      </Col>,
    ];
  }
}

ManagerCart.contextType = AppContext;

export default withRouter(ManagerCart);

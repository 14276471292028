import * as React from "react";
import {
  Breadcrumb,
  Skeleton,
  Form,
  Select,
  Button,
  Input,
  Tag,
  Alert,
} from "antd";
import { NavLink } from "react-router-dom";
import rest from "../../../helpers/rest";
import AppHelper from "../../../helpers/app";
const { Option } = Select;

class PayTypeMulti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      payTypes: [],
      methods: [],
      total: 0,
      paid: 0,
    };
    this.getTypes = this.getTypes.bind(this);
    this.addMethod = this.addMethod.bind(this);
    this.deleteMethod = this.deleteMethod.bind(this);
    this.getNeedToPay = this.getNeedToPay.bind(this);
    this.getFormSumm = this.getFormSumm.bind(this);
  }

  componentWillMount() {
    this.getTypes();
  }

  getTypes() {
    rest.get("/api/Transactions/paytypes").then((data) => {
      this.setState(
        {
          payTypes: data.data.data,
          methods: [{ type: data.data.data[0], ref: React.createRef() }],
        },
        () => {}
      );
    });
  }

  componentWillReceiveProps(nextProps) {    
    this.setState({ total: nextProps.total });
    if(this.state.methods.length == 1){
        if(this.state.methods[0].ref.current){
            this.state.methods[0].ref.current.setFieldsValue({
                pay__sum: nextProps.total
            })
        }
    }
  }

  addMethod() {
    let methods = this.state.methods;
    methods.push({ type: {}, ref: React.createRef() });
    this.setState({ methods: methods });
  }

  deleteMethod(index) {
    let methods = this.state.methods;
    methods.splice(index, 1);    
    this.setState({ methods: methods });
  }

  getNeedToPay() {
    let paid = 0;
    setTimeout(() => {
      this.state.methods.map((i) => {
        paid += parseFloat(i.ref.current.getFieldsValue().pay__sum) || 0;
      });

      this.setState({ paid: paid });
    }, 1);
  }

  getNeedToPayLabel(sum) {
    if (sum >= 0) {
      return (
        <Alert
          message={`Осталось оплатить: ${AppHelper.toCurrency(
            sum,
            "RUB",
            "Ru-ru"
          )}`}
          type="success"
        ></Alert>
      );
    } else if (sum < 0) {
      return (
        <Alert
          message={`Осталось оплатить: ${AppHelper.toCurrency(
            sum,
            "RUB",
            "Ru-ru"
          )}`}
          type="error"
        ></Alert>
      );
    }
  }

  // Получает сумма по всем формам
  async getFormSumm(){
      let sum = 0;
      return new Promise((res, rej) => {
        this.state.methods.map(async (i, index) => {
            sum += parseFloat(i.ref.current.getFieldsValue().pay__sum) || 0;
        })
        res(sum)
      })
    
  }
  
  async validPayForms() {
    let paytypes = [];
    let errors = false;
    return new Promise((res,rej) => {        
        
        this.state.methods.map(async (i, index) => {           
            i.ref.current.validateFields().catch(e => {
                res(false)
                errors = true;
            }).then(() => {                
                paytypes.push(i.ref.current.getFieldsValue());
                if(index == this.state.methods.length-1){
                    res(paytypes)
                }
            })
            
        });
        
    })  
    
    
  }

  render() {
    return (
      <div className="pay__types_wrapper">
        {this.state.methods.length > 1 &&
          this.state.total > 0 &&
          this.getNeedToPayLabel(this.state.total - this.state.paid)}
        {this.state.methods.map((i, index) => {
          return (
            <div className="pay__type">
              <Form
                key={index}
                name="paytype__form"
                className="paytype__form"
                ref={i.ref}
                layout="vertical"
              >
                <Form.Item
                  label="Тип оплаты"
                  name="pay__type"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста выберите тип оплаты",
                    },
                  ]}
                >
                  <Select>
                    {this.state.payTypes.map((i, index) => (
                      <Option key={index} value={i.id}>
                        {i.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item  >
                <Form.Item rules={[
                    {
                      required: true,
                      message: "Пожалуйста введите сумму",
                    },
                  ]} label="Сумма" name="pay__sum">
                  <Input onChange={() => this.getNeedToPay()} />
                </Form.Item>
              </Form>
              <div className="payment__actions">
                {this.state.methods.length > 1 && (
                  <Button danger onClick={() => this.deleteMethod(index)}>
                    Удалить метод оплаты
                  </Button>
                )}
                {this.state.methods.length == index + 1 && (
                  <Button type="primary" onClick={() => this.addMethod()}>
                    Добавить метод оплаты
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PayTypeMulti;

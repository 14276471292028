import React from "react";
import "../../../App.scss";
import { message, Skeleton } from "antd";
import Breadcrumb from "../../common/breadcrumb";
import { Card, Row, Col, List, Modal, Button, Form, Input, InputNumber, Spin } from "antd";
import AppContext from "../../../components/common/appContext";
import { PageHeader, Popconfirm } from "antd";
import rest from "../../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../../loading";
import { withRouter } from "react-router-dom";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import Item from "antd/lib/list/Item";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class Constans extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Константы", key: "/colors/" },
    ];

    this.state = {
      cataspan: 24,
      loading: true,
      consts: []
    };
    document.title = "Константы | Букетная.ОПТ";
    this.getConstants = this.getConstants.bind(this);
    this.formConstRef = React.createRef();
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(){
    let data = this.formConstRef.current.getFieldsValue();
    this.setState({loading: true})
    rest.update('/api/Settings', {setkey: "const", settings: data}).then(data => {
      this.setState({loading: false})
    })
  }

  componentDidMount() {
    this.getConstants();
  }
  getConstants() {
    rest.get("/api/Settings", { setkey: "const" }).then((data) => {
      this.setState({consts: data.data.data, loading: false})
    });
  }

  render() {
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card">
        <Row key={2}>
          <Col span={this.state.cataspan}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Постоянные величины"
            />
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row>
            <Col
              span={24}
              style={{ padding: "0px 20px" }}
              className="settingsform"
            >
              <Form
                name="filter"
                layout="vertical"
                ref={this.formConstRef}                                
                onFinish={() => this.submitForm()}
              >
                {
                  this.state.consts.map((i, index) => {
                    return <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Введите значение'
                      }
                    ]}
                     key={index} label={i.name} name={i.name} initialValue={i.value}>
                    <InputNumber />
                  </Form.Item>
                  })
                }
                <Form.Item>
                  <Button htmlType="submit" type="primary">Сохранить</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Card>,
    ];
  }
}

Constans.Constans = AppContext;

export default withRouter(Constans);

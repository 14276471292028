import React from "react";
import "../../../App.scss";
import { message, Skeleton } from "antd";
import Breadcrumb from "../../common/breadcrumb";
import {
  Card,
  Row,
  Col,
  List,
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
  Spin,
  Select
} from "antd";
import AppContext from "../../../components/common/appContext";
import rest from "../../../helpers/rest";
import ParsedItemKenya from "../parseditem/parseditemkenya";
import { withRouter } from "react-router-dom";
import * as moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru')

const { Option } = Select;

class Kenya extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: props.data,
      uploading: false,
      uploaded: false,
      selectTradingDayModal: false,
      tradinglist: []
    };
    this.itemsRef = [];
    // setInterval(() => {
    //     console.log(this.itemsRef);
    // }, 100)
    this.countSelfCost = this.countSelfCost.bind(this);
    this.modalUploadToExchange = this.modalUploadToExchange.bind(this);
    this.uploadToExchange = this.uploadToExchange.bind(this);
    this.TradingDaySelectForm = React.createRef();
    this.submitTradingForm = this.submitTradingForm.bind(this);
    this.deletePosition = this.deletePosition.bind(this);
  }

  componentDidMount() {
    if (this.itemsRef.length > 0) {
      this.countSelfCost(0);
    }
    rest.get('/api/Trading').then(data => {      
      this.setState({tradinglist: data.data.data})
    })
    
  }

  

  countSelfCost(index) {
    this.itemsRef[index].countSelfPrice().then((index) => {
      if (this.itemsRef.length - 1 > index) {
        this.countSelfCost(index + 1);
      }
    });
  }

  uploadToExchange(index, tradingday) {
    this.itemsRef[index].uploadToExchange(tradingday).then((index) => {
      if (this.itemsRef.length - 1 > index) {
        this.uploadToExchange(index + 1, tradingday);
      }
    });
  }

  modalUploadToExchange() {
    let valid = true;
    this.itemsRef.map((i) => {
      if (!i.checkValid()) {
        valid = false;
      }
    });
    if (!valid) {
      message.error("Не все позиции готовы к выгрузке");
      return;
    }
    this.setState({selectTradingDayModal: true})
    // message.success("Выгружаем позиции, подождите");
    // this.setState({ uploaded: true });
    // this.uploadToExchange(0);
  }

  submitTradingForm(){
    let params = this.TradingDaySelectForm.current.getFieldsValue();
    message.success("Выгружаем позиции, подождите");
    this.setState({ uploaded: true , selectTradingDayModal: false});
    this.uploadToExchange(0,params.tradingselect);
  }

  deletePosition(index){
    this.setState({positions: this.state.positions.filter((i, index) => index != index)})
  }

  render() {
    return [
      <Card key={1} style={{ width: "100%", padding: "10px 20px" }}>
        <Row gutter={[22, 22]}>
          {this.state.positions.map((i, index) => {            
            return (
              <Col key={index} md={6} xs={24} sm={24} lg={6}>
                <ParsedItemKenya
                  index={index}
                  type={"kenya"}
                  onRef={(ref) => (this.itemsRef[index] = ref)}
                  data={i}
                />
              </Col>
            );
          })}
        </Row>
        {(!this.state.uploaded && this.state.uploading) ||
          (!this.state.uploaded && !this.state.uploading && (
            <Row>
              <Col md={24}>
                <Button
                  disabled={this.state.uploading}
                  type="primary"
                  onClick={() => this.modalUploadToExchange()}
                  style={{ width: "100%" }}
                >
                  Выгрузить на биржу
                </Button>
              </Col>
            </Row>
          ))}
      </Card>,
      <Modal onOk={() => this.TradingDaySelectForm.current.submit()} key={2} onCancel={() => this.setState({selectTradingDayModal: false})} title="Выбор торгового дня" visible={this.state.selectTradingDayModal}>
          <Form onFinish={() => this.submitTradingForm()} ref={this.TradingDaySelectForm} layout="vertical">
            <Form.Item label="Торговый день" name="tradingselect" rules={[{required: true, message: 'Выберите торговый день'}]}>
            <Select style={{ width: "100%" }} placeholder="Торговый день">
             {this.state.tradinglist.map((item, i) => {
               return (
                 <Option value={item.id} key={i.toString(36) + i}>
                   {moment(item.date).format('DD MMMM YYYY')}
                 </Option>
               );
             })}
           </Select>
            </Form.Item>
          </Form>
      </Modal>
    ];
  }
}

Kenya.Kenya = AppContext;

export default withRouter(Kenya);

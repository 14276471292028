import * as React from "react";
import {
  Breadcrumb,
  Skeleton,
  Card,
  Row,
  Col,
  Button,
  PageHeader,
  Spin,
  message,
  Modal,
  Form,
  InputNumber,
} from "antd";
import { NavLink } from "react-router-dom";
import AppHelper from "../../../helpers/app";
import rest from "../../../helpers/rest";
import { DeleteOutlined } from "@ant-design/icons";
import * as moment from "moment";
import "moment/locale/ru";
moment.locale("ru");

class MiniProductComponent extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Быстрая заявка", key: "/" },
    ];
    this.state = {
      loading: true,
      data: null,
      cataloginfo: null,
      validcount: true,
      count: 0,
      countChangeVisibleModal: false,
    };
    this.getProductInfo = this.getProductInfo.bind(this);
    this.validate = this.validate.bind(this);
    this.addOneMultiplicity = this.addOneMultiplicity.bind(this);
    this.getLoading = this.getLoading.bind(this);
    this.addedTimeout = null;
    this.formRefChangeCount = React.createRef();
    this.changeCountFormFinish = this.changeCountFormFinish.bind(this);
  }

  getLoading() {
    return this.state.loading;
  }

  getProductInfo() {
    rest.get("/api/Stock/single", { id: this.props.id }).then((data) => {
      if(!data.data.data){
        message.error('Ошибка поиска позиции')
        this.props.onRemove(this.props.id);
        return;
      }
      this.setState({
        data: data.data.data,
        count: data.data.data.multiplicity,
      });      
      rest
        .get("/api/Catalog", { id: data.data.data.catalogid })
        .then((catalogdata) => {
          let catalogobj = catalogdata.data.data.list[0] || {};
          this.setState({
            cataloginfo: catalogobj,
            loading: false,
            errors: [],
          });
          this.validate();
          
          this.props.onChange(this.props.id);
          this.props.onLoad();
        });
    });
  }

  addOneMultiplicity() {
    this.setState(
      {
        countadded: true,
        count: this.state.count + this.state.data.multiplicity,
      },
      () => {
        this.validate();
        clearTimeout(this.addedTimeout);
        this.addedTimeout = setTimeout(() => {
          this.setState({ countadded: false });
        }, 1000);
        this.props.onChange(this.props.id);
      }
    );
    //this.formRefChangeCount.current.setFieldsValue({count: this.state.count + this.state.data.multiplicity})
  }

  changeCountFormFinish(){
    let formdata = this.formRefChangeCount.current.getFieldsValue();    
    this.setState({count: formdata.count, countChangeVisibleModal: false}, () => {
      this.props.onChange(this.props.id);
      this.validate();
    });
  }

  validate() {
    // Проверка количества на складе и в заявке
    let errors = [];
    if (this.state.count > this.state.data.available) {
      errors.push("На складе недостаточное количество товара");
    }

    this.setState({ errors: errors });
    if(errors.length > 0) return false;
    return true;
  }

  componentDidMount() {
    this.getProductInfo();
    //this.formRefChangeCount.current.setFieldsValue({count:this.state.count})
  }

  getProductData() {
    if(!this.state.data) return {
      multiplicity: 0,
      WR_PRICE: 0,
      price: 0,
      selfcost: 0,
      count: 0,
      id: 0
    }
    return {
      multiplicity: this.state.data.multiplicity,
      WR_PRICE: this.state.data.WR_PRICE,
      price: this.state.data.price,
      selfcost: this.state.data.selfcost,
      count: this.state.count,
      id: this.state.data.id
    };
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <div
          className={`simple__request__positions__item ${
            this.state.countadded ? "updated" : ""
          }`}
        >
          {!this.state.loading && (
            <div className="simple__request__positions__wrapper">
              <div className="left__block">
                <div
                  className="simple__request__positions__item__cover"
                  style={{
                    background: `url(${rest.getavatarurl(
                      this.state.cataloginfo.id,
                      this.state.cataloginfo.image
                    )})`,
                  }}
                ></div>
                <div className="simple__request__positions__item__info">
                  <p className="name">{this.state.cataloginfo.name}</p>
                  <div className="simple__request__positions__item__params">
                    <div className="simple__request__positions__item__params__item">
                      <span className="label">Рост:</span>
                      <span className="value">{this.state.data.growth}см</span>
                    </div>
                    <div className="simple__request__positions__item__params__item">
                      <span className="label">Страна:</span>
                      <span className="value">{this.state.data.country}</span>
                    </div>
                    <div className="simple__request__positions__item__params__item">
                      <span className="label">Кратность:</span>
                      <span className="value">
                        {this.state.data.multiplicity}
                      </span>
                    </div>
                    <div className="simple__request__positions__item__params__item">
                      <span className="label">Остаток:</span>
                      <span className="value">{this.state.data.available}</span>
                    </div>
                    <div className="simple__request__positions__item__params__item">
                      <span className="label">Цена мелкий опт:</span>
                      <span className="value">{AppHelper.toCurrency(this.state.data.WR_PRICE, "RUB", "Ru-ru") }</span>
                    </div>
                    <div
                      className="simple__request__positions__item__params__item clicked"
                      onClick={() =>{
                        this.setState({ countChangeVisibleModal: true }, () => {
                          this.formRefChangeCount.current.setFieldsValue({count: this.state.count})
                        });
                        //this.formRefChangeCount.current.setFieldsValue({count: this.state.count + this.state.data.multiplicity})
                      }}
                    >
                      <span className="label">Количество:</span>
                      <span
                        className={`value ${
                          this.state.countadded ? "green" : ""
                        }`}
                      >
                        {this.state.count}
                      </span>
                    </div>
                  </div>
                  <div className="simple__request__positions__item__errors">
                    {this.state.errors.map((error, i) => {
                      return (
                        <div
                          key={i}
                          className="simple__request__positions__item__error"
                        >
                          <span>{error}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="simple__request__positions__item__actions">
                <button
                  className="remove"
                  onClick={() => this.props.onRemove(this.props.id)}
                >
                  <DeleteOutlined />
                </button>
              </div>
              <Modal
                visible={this.state.countChangeVisibleModal}
                title={`Количество позиции ${this.state.cataloginfo.name}`}
                onCancel={() => {
                  this.setState({ countChangeVisibleModal: false });
                }}
                onOk={() => {this.formRefChangeCount.current.submit()}}
              >
                <div className="product__form_count">
                <Form
                  onFinish={this.changeCountFormFinish}
                  name="count"
                  ref={this.formRefChangeCount}
                  layout="vertical"
                >
                  <Form.Item
                    label="Количество"
                    name="count"                                        
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста введите количество",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Form>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </Spin>
    );
  }
}

export default MiniProductComponent;

import React from "react";
import { Spin, Button, Input, Form, message } from "antd";
import rest from "../../helpers/rest";
import * as moment from "moment";
import "moment/locale/ru";
moment.locale("ru");

const { TextArea } = Input;

class RequestCommentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      comments: [],
      comment: "",
    };

    this.getComments = this.getComments.bind(this);
    this.addComment = this.addComment.bind(this);
    this.formRef = React.createRef();
  }

  componentWillMount() {
    this.getComments();
  }

  addComment() {
    //console.log(123);
    this.setState({loading: true});
    rest.post('/api/Requests/postcomments', {
        request: this.props.id,
        comment: this.formRef.current.getFieldsValue().comment
    }).then(data => {
        this.getComments();
        message.success('Комментарий оставлен');
        this.formRef.current.setFieldsValue({
            comment: ''
        })
    }).catch(err => {
        this.setState({loading: false});
        message.error(err.response.data.data.detail)
        //console.log(err.response.data.data.detail);
    })
  }

  getComments() {
    rest
      .get("/api/Requests/comments", { request: this.props.id })
      .then((data) => {
        //console.log(data.data.data.data);
        this.setState({ comments: data.data.data, loading: false });
      });
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Form
          onFinish={this.addComment}
          name="basic"
          ref={this.formRef}
          layout="vertical"
        >
          <div className="p10 comments" style={{ margin: "50px 0px" }}>
            <h1>Комментарии к заявке</h1>
            <div className="comments_list">
              {this.state.comments.length === 0 && !this.state.loading && (
                <h3 style={{ color: "#a5a5a5" }}>Нет комментариев</h3>
              )}
              {this.state.comments.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="comment_item"
                    style={{
                      borderBottom: "1px solid rgb(234 234 234)",
                      marginBottom: "30px",
                    }}
                  >
                    <p
                      className="login"
                      style={{
                        fontWeight: "bold",
                        margin: "0px",
                        fontSize: "20px",
                      }}
                    >
                      {`${item.sname} ${item.fname}`} - {item.login}
                    </p>
                    <p
                      className="date"
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#989898",
                      }}
                    >
                      {moment(item.date).add(7, 'hours').format("DD MMMM YYYY hh:mm")}
                    </p>
                    <p className="commenttext" style={{ fontSize: "18px" }}>
                      {item.text}
                    </p>
                  </div>
                );
              })}
            </div>
            <h2>Оставить комментарий</h2>
            <Form.Item
            label="Текст комментария"
            name="comment"
            rules={[{ required: true, message: "Пожалуйста введите комментарий" }]}
          >
            <TextArea
              style={{
                minHeight: "160px",
                width: "100%",
                marginBottom: "15px",
              }}
              showCount
              maxLength={500}
              rows={4}
            />
            </Form.Item>
            <Form.Item>
            <Button  
              htmlType="submit"
              style={{ width: "100%", marginTop: "20px" }}
              type="primary"
              className="submitcartrequestbtn"
            >
              Оставить комментарий
            </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    );
  }
}

export default RequestCommentsComponent;

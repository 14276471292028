import React from "react";
import "../../App.scss";
import {
  Spin,
  Card,
  Row,
  Col,
  Switch,
  message,
  Select,
  Popconfirm,
} from "antd";
import Breadcrumb from "../common/breadcrumb";
import { PageHeader, Button,  Form, Input, DatePicker } from "antd";
import { withRouter } from "react-router-dom";
import rest from "../../helpers/rest";
import MaskedInput from 'antd-mask-input'
import AppHelper from "../../helpers/app";
import * as moment from "moment";
import "moment/locale/ru";

const { Option } = Select;

const layout = {
  labelCol: { offset: 6, sm: 12 },
  wrapperCol: { offset: 6, sm: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

class UsersComponentEdit extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      cataspan: 24,
      loading: true,
      breadcrumbs: [
        { title: "Букетная.ОПТ", key: "/" },
        { title: "Справочники", key: "/directory" },        
        { title: "Редактирование", key: "/users/" },
      ],
      managers: [],
      user: null,
      selectmanager: true,
      organizationList: [],
    };
  }

  getOrganizations() {
    rest.get("/api/Organizations").then((organizations) => {
      this.setState({ organizationList: organizations.data.data });
    });
  }

  componentWillMount() {
    document.title = "Редактирование пользователя | Букетная.ОПТ";
    rest
      .get("/api/Account/", { userid: this.props.match.params.id })
      .then((user) => {
        this.getOrganizations();
        rest.get("/api/Account/managers").then((managers) => {          
          this.setState({managers: managers.data.data})
          let currentuser = user.data.data[0];
          console.log(currentuser)          
          this.setState({ user: currentuser });
          let breadcrumbs = this.state.breadcrumbs;
          breadcrumbs[1].title = `Пользователь ${currentuser.id}`;
          this.setState({ breadcrumbs: breadcrumbs, loading: false });
          this.formRef.current.setFieldsValue({
            login: currentuser.login,
            fname: currentuser.fname,
            sname: currentuser.sname,
            type: currentuser.type,
            email: currentuser.email,
            ban: currentuser.ban === 1,
            organization: currentuser.orgId,
            manager: currentuser.manager,
            phone : currentuser.phone,
            tradeaccess: currentuser.tradeaccess,
            discount: currentuser.discount,
            NOT_STAT: currentuser.NOT_STAT,
            address: currentuser.address,
            dateOfeducation: moment(currentuser.educationdate),
            directorbirthday: moment(currentuser.directorbirthday)
          });
          this.setState({ selectmanager: currentuser.type === "user" });
        });
      });
    this.onSubmit = this.onSubmit.bind(this);
    this.resetPass = this.resetPass.bind(this);
  }

  resetPass() {
    let form = this.formRef.current.getFieldsValue();
    if (!form.email || form.email.length < 0) {
      message.error("Укажите E-mail пользователя");
    } else {
      message.success(
        `Новый пароль выслан пользователю на E-mail: ${form.email}`
      );
      rest
        .post("/api/Account/resetpass", {
          id: this.state.user.id,
          mail: form.email,
        })
        .then();
    }
  }

  onSubmit() {
    let params = this.formRef.current.getFieldsValue();
    
    params.id = this.state.user.id;
    this.setState({ loading: true });

    rest.update("/api/Account", params).then((data) => {
      message.success('Успешно сохранено');
      this.setState({ loading: false});
      //this.props.history.push("/users");
    });
  }

  

  render() {    
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.state.breadcrumbs}
      />,
      <Row key={2}>
        <Col span={this.state.cataspan}>
          <Card className="content-card">
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Редактирование пользователя"
              extra={[
                <Popconfirm
                  title="Выслать пользователю новый пароль ?"
                  onConfirm={this.resetPass}
                  okText="Да"
                  cancelText="Нет"
                  key={1}
                >
                  <Button key="1">Новый пароль</Button>
                </Popconfirm>,                
                <Button
                  key={3}
                  type="primary"
                  onClick={() => this.formRef.current.submit()}
                >
                  Сохранить
                </Button>,
              ]}
            />
            <Spin spinning={this.state.loading}>
              <Form
                {...layout}
                onFinish={this.onSubmit}
                onFinishFailed={() => message.warn("Заполните данные")}
                name="basic"
                className="useredit"
                ref={this.formRef}
                layout="vertical"
              >
                <Form.Item
                  label="Логин"
                  name="login"
                  rules={[
                    { required: true, message: "Пожалуйста введите логин" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Имя"
                  name="fname"
                  rules={[
                    { required: true, message: "Пожалуйста введите имя" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Фамилия"
                  name="sname"
                  rules={[
                    { required: true, message: "Пожалуйста введите фамилию" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="День рождения"
                  name="directorbirthday"
                >
                  <DatePicker placeholder="Выберите день"/>
                </Form.Item>
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    { required: true, message: "Пожалуйста введите email" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Номер телефона"
                  name="phone"
                  rules={[
                    { required: true, message: "Пожалуйста введите телефон" },
                  ]}
                >
                  <MaskedInput mask="+1 111 111 11 11" name="card" size="20"/>
                </Form.Item>
                <Form.Item label="Организация" name="organization" rules={[]}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Выберите организацию"
                    optionFilterProp="children"
                    loading={this.state.loading}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.organizationList.map((item, index) => {
                      return (
                        <Option key={index} value={item.orgId}>
                          {item.orgName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="День образования салона"
                  name="dateOfeducation"
                >
                  <DatePicker placeholder="Выберите день"/>
                </Form.Item>
                <Form.Item
                  label="Адрес доставки"
                  name="address"
                >
                  <Input placeholder="Введите адрес"/>
                </Form.Item>
                <Form.Item
                  label="Заблокировать аккаунт?"
                  name="ban"
                  valuePropName="checked"
                  className="userswitch"
                >
                  <Switch
                    checkedChildren="Разблокировать"
                    unCheckedChildren="Заблокировать"
                  />
                </Form.Item>

                <Form.Item
                  label="Не учитыватьв статистике"
                  name="NOT_STAT"
                  valuePropName="checked"
                  className="userswitch"
                >
                  <Switch
                    checkedChildren="Да"
                    unCheckedChildren="Нет"
                  />
                </Form.Item>


                <Form.Item label="Тип пользователя" name="type">
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ selectmanager: e === "user" })
                    }
                  >
                    <Option value="admin">Администратор</Option>
                    <Option value="user">Пользователь</Option>
                  </Select>
                </Form.Item>
                {
                  this.state.selectmanager && <Form.Item
                  label="Вечный доступ к бирже"
                  name="tradeaccess"
                  valuePropName="checked"
                  className="userswitch"
                >
                  <Switch
                    checkedChildren="Нет"
                    unCheckedChildren="Да"
                  />
                </Form.Item>
                }
                {
                  this.state.selectmanager && <Form.Item
                  label="Дисконт"
                  name="discount"                  
                >
                  <Select style={{ width: "100%" }} >
                    {
                     AppHelper.discountArray().map((i, index) => {
                        return <Option key={index} value={i.value}>{i.name}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
                }
                {this.state.selectmanager && (
                  <Form.Item
                    label="Личный менеджер"
                    name="manager"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста выберите менеджера",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Выберите менеджера"
                      optionFilterProp="children"
                      loading={this.state.loadingmanager}
                    >
                      {this.state.managers.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {`${item.fname} ${item.sname}`}
                          </Option>
                        );
                      })}
                    </Select>
                    
                  </Form.Item>
                )}

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Card>
        </Col>
      </Row>,
    ];
  }
}

export default withRouter(UsersComponentEdit);

import React from "react";
import "../../App.scss";
import { DatePicker, message, Skeleton, Spin, Tag, Switch } from "antd";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  Row,
  Col,
  List,
  Modal,
  Button,
  Form,
  Input,
  Tooltip,
} from "antd";
import AppContext from "../../components/common/appContext";
import { PageHeader, Popconfirm } from "antd";
import rest from "../../helpers/rest";
import {
  EditOutlined,
  DeleteOutlined,
  ShrinkOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";
import { TimePicker } from 'antd';
import Loading from "../loading";
import { NavLink, withRouter } from "react-router-dom";
import locale from "antd/es/date-picker/locale/ru_RU";
//import 'moment/locale/ru_RU';

import * as moment from "moment";
import "moment/locale/ru";
moment.locale("ru");

///

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class TradingDaysComponent extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Справочники", key: "/directory/" },
      { title: "Торговые дни", key: "/category/" },
    ];

    this.state = {
      cataspan: 24,
      loading: true,
      addModalVisible: false,
      addModalLoad: false,
      editModalLoad: false,
      editModalVisible: false,
      dayslist: [1, 2],
      editactive: null,
      showtime: false
    };
    document.title = "Торговые дни | Букетная.ОПТ";

    this.formRef = React.createRef();

    this.formRefEdit = React.createRef();

    this.addModalShow = this.addModalShow.bind(this);

    this.getTradeDaysList = this.getTradeDaysList.bind(this);

    this.deleteDay = this.deleteDay.bind(this);

    this.showEditDay = this.showEditDay.bind(this);

    this.movetostock = this.movetostock.bind(this);
  }

  componentWillMount() {
    this.getTradeDaysList();
  }

  getTradeDaysList() {
    this.setState({ loading: true });
    rest.get("/api/Trading/", {fromlist: true}).then((data) => {
      let tradings = data.data.data;
      tradings.map((item, index) => {
        item.key = index;
      });
      this.setState({ dayslist: tradings, loading: false });
    });
  }

  addModalShow() {
    this.setState({ addModalVisible: true, showtime: false });
    // Закончил на том, что надо свитчек дезактивировать 
    setTimeout(() => {
      //console.log(this.formRefEdit.current)
      //th/is.formRefEdit.current.setFieldsValue({ needtime: false });
    }, 100 )
  }

  addDayFormSubmit() {
    message.success("Добавляем форму");
    this.setState({ addModalLoad: true });
    let date = moment(this.formRef.current.getFieldsValue().date).format(
      "YYYY-MM-DD"
    );
    rest
      .post("/api/Trading/", { date: date, needtime: this.formRef.current.getFieldsValue().needtime, time: moment(this.formRef.current.getFieldsValue().time).format('YYYY-MM-DD HH:mm:ss')  })
      .then((data) => {
        this.setState({ addModalLoad: false, addModalVisible: false });
        this.getTradeDaysList();
      })
      .catch((err) => {
        message.error(err.response.data.data.detail);
        this.setState({ addModalLoad: false });
      });
  }

  deleteDay(id) {
    this.setState({ loading: true });
    rest.delete("/api/Trading/", { id: id }).then((data) => {
      this.getTradeDaysList();
    });
  }

  editDayFormSubmit() {
    this.setState({ editModalLoad: true });
    
    rest
      .update("/api/Trading", {
        date: moment(this.formRefEdit.current.getFieldsValue().date).format(
          "YYYY-MM-DD"
        ),
        id: this.state.editactive,
        needtime: this.formRefEdit.current.getFieldsValue().needtime, time: moment(this.formRefEdit.current.getFieldsValue().time).format('YYYY-MM-DD HH:mm:ss')
      })
      .then((data) => {
        this.setState({ editModalVisible: false, editModalLoad: false });
        this.getTradeDaysList();
      })
      .catch((err) => {
        message.error(err.response.data.data.detail);
        this.setState({ editModalLoad: false });
      });
  }

  showEditDay(item) {
    setTimeout(() => {
      this.formRefEdit.current.setFieldsValue({ date: moment(item.date), needtime: item.needtime == 1, time: moment(item.time || new Date(), 'YYYY-MM-DD HH:mm:ss') });
    }, 10);

    this.setState({ editactive: item.id, editModalVisible: true, showtime: item.needtime == 1 });
  }

  movetostock(day) {
    this.setState({ loading: true });
    rest.delete("/api/Exchange/movestock", { day: day.id }).then((result) => {
      message.success("Позиции успешно перемещенны на склад");
      this.getTradeDaysList();
    });
  }

  render() {
    const style = { padding: "0px 20px" };
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card">
        <Row key={2}>
          <Col span={this.state.cataspan}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Торговые дни"
              extra={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.addModalShow()}
                >
                  Добавить
                </Button>,
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "0px 20px" }}>
            <List
              itemLayout="horizontal"
              className="dayslist"
              dataSource={this.state.dayslist}
              locale={{ emptyText: "Торговые дни не найдены" }}
              renderItem={(item, index) => (
                <Skeleton
                  key={index}
                  title={false}
                  loading={this.state.loading}
                  active
                >
                  <List.Item
                    key={index}
                    className="coloranizationList"
                    actions={[
                      <Tooltip title="Перейти в биржу">
                        <NavLink to={`/exchange/${item.id}`}>
                          <ArrowRightOutlined />
                        </NavLink>
                      </Tooltip>,
                      <Tooltip title="Списать позиции на склад">
                        <Popconfirm
                          title="Списать позиции на склад?"
                          onConfirm={() => this.movetostock(item)}
                          okText="Списать"
                          cancelText="Отмена"
                        >
                          <a key="list-loadmore-edit">
                            <ShrinkOutlined />
                          </a>
                        </Popconfirm>
                      </Tooltip>,
                      <a
                        key="list-loadmore-edit"
                        onClick={() => this.showEditDay(item)}
                      >
                        <EditOutlined />
                      </a>,
                      <Popconfirm
                        title="Удалить торговый день?"
                        onConfirm={() => this.deleteDay(item.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <a key="list-loadmore-more">
                          <DeleteOutlined />
                        </a>
                      </Popconfirm>,
                    ]}
                  >
                    <Tag color="green">
                      {moment(item.date).format("DD MMMM YYYY")}
                      {item.needtime == 1 ? ` - Время открытия биржи : ${item.time}` : ''}
                    </Tag>
                  </List.Item>
                </Skeleton>
              )}
            ></List>
          </Col>
        </Row>
      </Card>,
      <Modal
        key={3}
        title="Добавить торговый день"
        visible={this.state.addModalVisible}
        onCancel={() => this.setState({ addModalVisible: false })}
        cancelText="Отмена"
        onOk={() => this.formRef.current.submit()}
        className="addDayModal"
      >
        <Spin spinning={this.state.addModalLoad}>
          <Form
            onFinish={() => this.addDayFormSubmit()}
            onFinishFailed={() => message.error("Ошибка")}
            name="basic"
            ref={this.formRef}
            layout="vertical"
          >
            <Form.Item
              name="date"
              rules={[
                { required: true, message: "Укажите дату торгового дня" },
              ]}
            >
              <DatePicker format="DD.MM.YYYY" locale={locale} />
            </Form.Item>

            <Form.Item name="needtime" label="Открыть по времени">
              <Switch checked={this.state.showtime}  onChange={() => this.setState({ showtime: !this.state.showtime })} />
            </Form.Item>
            {this.state.showtime && (
              <Form.Item
                name="time"
                rules={[
                  {
                    required: true,
                    message: "Укажите время открытия торгового дня",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>,
      <Modal
        key={4}
        title="Редактирование торгового дня"
        visible={this.state.editModalVisible}
        onCancel={() => this.setState({ editModalVisible: false })}
        cancelText="Отмена"
        onOk={() => this.formRefEdit.current.submit()}
        className="addDayModal"
      >
        <Spin spinning={this.state.editModalLoad}>
          <Form
            onFinish={() => this.editDayFormSubmit()}
            onFinishFailed={() => message.error("Ошибка")}
            name="basic"
            ref={this.formRefEdit}
            layout="vertical"
          >
            <Form.Item
              name="date"
              rules={[
                { required: true, message: "Укажите дату торгового дня" },
              ]}
            >
              <DatePicker format="DD.MM.YYYY" locale={locale} />
            </Form.Item>
            <Form.Item name="needtime" label="Открыть по времени">
              <Switch checked={this.state.showtime}  onChange={() => this.setState({ showtime: !this.state.showtime })} />
            </Form.Item>
            {this.state.showtime && (
              <Form.Item
                name="time"
                rules={[
                  {
                    required: true,
                    message: "Укажите время открытия торгового дня",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>,
    ];
  }
}

TradingDaysComponent.contextType = AppContext;

export default withRouter(TradingDaysComponent);

import React from "react";
import "../../App.scss";
import { message, Skeleton } from "antd";
import Breadcrumb from "../common/breadcrumb";
import { Card, Row, Col, List, Modal, Button, Form, Input } from "antd";
import AppContext from "../../components/common/appContext";
import { PageHeader, Popconfirm, Upload, Spin } from "antd";
import rest from "../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../loading";
import { withRouter } from "react-router-dom";
import { FileOutlined, InboxOutlined } from "@ant-design/icons";
import FarmElement from "../excelparser/holland/farmelement";
import Kenya from '../excelparser/kenya/kenya'
const { Dragger } = Upload;

class ExcelParser extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Выгрузка из Excel", key: "/" },
    ];
    this.state = {
      uploadload: false,
      uploadprops: {},
      plantationFrom: null,
      showupload: true
    };
    document.title = "Выгрузка из Excel | Букетная.ОПТ";
    this.setProps = this.setProps.bind(this);
    this.renderPlantationType = this.renderPlantationType.bind(this);
  }

  setProps(th) {
    this.setState({
      uploadprops: {
        name: "file",
        multiple: false,
        action: rest.getfileurl(`/files/forexcelparser`),
        showUploadList: false,
        accept: ".xls, .xlsx",
        onChange(info) {
          th.setState({ uploadload: true });
          const { status } = info.file;
          if (status !== "uploading") {
            
          }
          if (status === "done") {
            message.success(`${info.file.name} file uploaded successfully.`);
            th.setState({
              plantationFrom: info.file.response.excel.from,
              positionsjson: info.file.response.excel.json || [],
              showupload: false
            });
            th.setState({ uploadload: false });
          } else if (status === "error") {
            //console.log(info.file.response.detail)
            th.setState({ uploadload: false });
            message.error(info.file.response.detail);
          }
        },
        onDrop(e) {
          
        },
      },
    });
  }

  componentDidMount() {
    this.setProps(this);
    this.setState({uploadload: true})
    rest.get("/api/Settings", { setkey: "const" }).then((data) => {
      this.setState({consts: data.data.data, loading: false, uploadload: false})
    });
  }

  
  renderPlantationType(type) {
    
    switch (type) {
      case "holland": {
        return <FarmElement type="holland" data={this.state.positionsjson} />;
      }
      case "KENYA": {
        return <FarmElement type="kenya" data={this.state.positionsjson} />
      }
      case "ECUADOR": {
        return <FarmElement type="ecuador" data={this.state.positionsjson} boxTypes={this.state.positionsjson.filter(i => i.BoxType != undefined).map(i => i.BoxType)} />
      }
      case null: {
        return null;
      }
      default: {
        return <h1>smth other</h1>;
      }
    }
  }

  render() {
    const style = { padding: "10px 20px" };
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card" style={{ padding: "20px 0px" }}>
        <Row key={4}>
          <Col span={this.state.cataspan} style={{ width: "100%" }}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Excel выгрузка"
              extra={[<Button key={1} onClick={() => {this.setState({positionsjson: [], plantationFrom: null, showupload: true})}}>Сбросить</Button>]}
            />
          </Col>
        </Row>
        {this.state.showupload && (
          <Row key={5} style={{ padding: "0px 20px" }}>
            <Col key={6} style={{ width: "100%" }}>
              <Spin key={7} spinning={this.state.uploadload}>
                <Dragger
                  accept=".xls, .xlsx"
                  {...this.state.uploadprops}
                  style={{ marginTop: "15px" }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Нажмите или перенесите файлы для загрузки
                  </p>
                  <p className="ant-upload-hint">
                    Поддерживается только одиночная загрузка файлов
                  </p>
                </Dragger>
              </Spin>
            </Col>
          </Row>
        )}
        ,
        <Row key={6}>
          <Col
            span={this.state.cataspan}
            style={{ width: "100%", padding: "10px 20px" }}
          >
            {this.renderPlantationType(this.state.plantationFrom)}
          </Col>
        </Row>
      </Card>,
    ];
  }
}

ExcelParser.contextType = AppContext;

export default withRouter(ExcelParser);

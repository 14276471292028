import React from "react";
import "../../../App.scss";
import { message, Skeleton, Tag, Popconfirm } from "antd";
import Breadcrumb from "../../common/breadcrumb";
import {
  Card,
  Row,
  Col,
  List,
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
  Spin,
} from "antd";
import AppContext from "../../../components/common/appContext";
import rest from "../../../helpers/rest";
import AppHelper from "../../../helpers/app";
import { withRouter } from "react-router-dom";
import CatalogSelect from "../catalogselect/catalogselect";
import TextArea from "antd/lib/input/TextArea";

class ParsedItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      catalogid: null,
      imageName: "XXAuBw9O2vR8WmrBimage.jpg",
      catalogload: false,
      loading: true,
      catalogName: null,
      uploaded: false,
      multiplicity: null,
    };
    this.moreinfoform = React.createRef();
    this.selfCostCounting = this.selfCostCounting.bind(this);
    this.RenderFormToCount = this.RenderFormToCount.bind(this);
    this.checkValid = this.checkValid.bind(this);
    this.RenderFormExchangeUpload = this.RenderFormExchangeUpload.bind(this);
    this.exchangeinfoform = React.createRef();
    this.setPositionValid = this.setPositionValid.bind(this);
    this.uploadToExchange = this.uploadToExchange.bind(this);
    this.ExchangePercentRateChange = this.ExchangePercentRateChange.bind(this);
    this.ExchangePriceChange = this.ExchangePriceChange.bind(this);
  }

  /**
   * Функция расчета себестоимости
   */
  countSelfPrice() {
    this.setState({ readyToUpload: false });
    return new Promise((res) => {
      rest
        .get("/api/Catalog/getpositionbyexcel", {
          ...this.state.data,
          ...{ type: this.props.type || "holland" },
        })
        .then((data) => {
          let found = data.data.data.found;
          let boxvalue = data.data.data.boxvalue;
          let mult = data.data.data.mult;

          if (found.length > 0) {
            this.setState(
              {
                catalogid: found[0].id,
                imageName: found[0].image,
                catalogName: found[0].name,
                catalogload: false,
                selfcost: null,
                editmode: false,
                readyToUpload: false,
                percentrate: data.data.data.percentrate || 0,
              },
              () => {
                if (mult) {
                  this.setState({ multiplicity: mult });
                }
                if (boxvalue) {
                  this.moreinfoform.current.setFieldsValue({
                    boxvalue: boxvalue[0],
                  });
                  this.setState({ boxvalue: boxvalue[0] });
                  //console.log(this.moreinfoform.current)
                  this.moreinfoform.current.submit();
                }
              }
            );
          }
          this.setState({ loading: false });
          res(this.props.index);
        });
    });
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  uploadToExchange(tradingday) {
    this.setState({ loading: true });
    let formdata = this.exchangeinfoform.current.getFieldValue();
    let params = {
      catalogid: this.state.catalogid,
      growth: formdata.growth,
      country: formdata.country,
      multiplicity: formdata.multiplicity,
      price: formdata.price,
      available: formdata.available,
      more: formdata.more,
      tradingday: tradingday,
      selfcost: this.state.selfcost,
      S1: this.props.data.S1,
      S2: this.props.data.S2,
      boxvalue: this.state.boxvalue,
      article: this.props.data.Article,
      WR_PRICE: formdata.WR_PRICE,
    };
    //console.log(this.state.boxvalue)

    return new Promise((res, rej) => {
      rest.post("/api/Exchange", params).then((e) => {
        res(this.props.index);
        this.setState({ uploaded: true, loading: false });
      });
    });
  }

  checkValid() {
    //console.log(this.exchangeinfoform.current.validateFields());
    return (
      this.state.readyToUpload && this.state.selfcost && !this.state.editmode
    );
  }

  setPositionValid() {
    //this.exchangeinfoform.current.getFieldValue();

    this.exchangeinfoform.current
      .validateFields()
      .then((e) => {
        this.setState({ readyToUpload: true });
      })
      .catch(() => this.setState({ readyToUpload: false }));
  }

  selfCostCounting() {
    this.setState({ readyToUpload: false, editmode: false });
    let formdata = this.moreinfoform.current.getFieldValue();
    this.setState({ loading: true });
    if (formdata.boxvalue) {
      rest
        .post("/api/Catalog/countselfcost", {
          ...this.props.data,
          ...formdata,
        })
        .then((data) => {
          //console.log(data.data.data.seftcost);
          this.setState({
            selfcost: data.data.data.selfcost,
            boxvalue: formdata.boxvalue,
          });
          this.setState({ loading: false });
          if (this.state.percentrate > 0) {
            this.exchangeinfoform.current.setFieldsValue({
              percentrate: this.state.percentrate,
            });
            this.ExchangePercentRateChange({
              target: { value: this.state.percentrate },
            });
          }
        })
        .catch((e) => {
          this.setState({ readyToUpload: false, editmode: false });
          this.setState({ loading: false });
          message.error("Упс, что-то пошло не так");
        });
    } else {
      this.setState({ selfcost: formdata.selfcost, loading: false });
    }
  }

  ExchangePercentRateChange(e) {
    let value = e.target.value;
    if (!parseFloat(value)) return;
    let percentToRub = (this.state.selfcost * e.target.value) / 100;
    let totalPriceRub = Math.round(
      parseFloat(this.state.selfcost) + percentToRub
    );
    this.exchangeinfoform.current.setFieldsValue({
      price: totalPriceRub.toFixed(2),
    });
  }

  ExchangePriceChange(e) {
    let value = e.target.value;
    if (!parseFloat(value)) return;
    let percent = (value * 100) / this.state.selfcost - 100;
    this.exchangeinfoform.current.setFieldsValue({
      percentrate: percent.toFixed(2),
    });
  }

  RenderFormExchangeUpload() {
    return (
      <div className="i_item">
        <Form
          name="exchangeinfo"
          layout="vertical"
          ref={this.exchangeinfoform}
          className="exchangeinfo"
          onChange={() => this.setPositionValid()}
          //onFinish={() => this.selfCostCounting()}
        >
          <Form.Item
            label="Рост"
            initialValue={this.props.data.S1 || null}
            name="growth"
            rules={[{ required: true, message: "Введите рост цветка" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Страна"
            initialValue={this.props.type == "holland" ? "Голландия" : null}
            name="country"
            rules={[{ required: true, message: "Введите страну" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Кратность"
            name="multiplicity"
            initialValue={this.state.multiplicity}
            rules={[{ required: true, message: "Введите кратность" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Накрутка, %"
            name="percentrate"
            onChange={(e) => this.ExchangePercentRateChange(e)}
            rules={[{ required: true, message: "Введите % накрутки" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Стоимость"
            name="price"
            onChange={(e) => this.ExchangePriceChange(e)}
            rules={[
              { required: true, message: "Введите стоимость" },
              { required: true, message: "Введите стоимость " },
            ]}
          >
            <InputNumber type="number" />
          </Form.Item>
          <Form.Item label="Цена мелкий опт" name="WR_PRICE">
            <InputNumber type="number" />
          </Form.Item>
          <Form.Item
            label="Количество"
            name="available"
            initialValue={this.state.data.Total}
            rules={[{ required: true, message: "Количество" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Дополнительная информация"
            name="more"
            initialValue={this.state.data.S2}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </div>
    );
  }

  RenderFormToCount() {
    return (
      <div className="i_item">
        <Form
          name="moreinfo"
          layout="vertical"
          ref={this.moreinfoform}
          className="moreinfo"
          onFinish={() => this.selfCostCounting()}
        >
          {!this.state.editmode && (
            <Form.Item
              label="Объем коробки"
              name="boxvalue"
              rules={[{ required: true, message: "Введите объем коробки" }]}
            >
              <InputNumber />
            </Form.Item>
          )}
          {this.state.editmode && (
            <Form.Item
              label="Себестоимость"
              name="selfcost"
              rules={[{ required: true, message: "Введите себестоимость" }]}
            >
              <InputNumber />
            </Form.Item>
          )}
          <Button htmlType="submit" type="primary">
            {!this.state.editmode ? "Рассчитать себестоимость" : "Сохранить"}
          </Button>
          <div style={{ width: "100%", textAlign: "center" }}>
            <span
              className="subtitlemini"
              onClick={() =>
                this.setState({
                  editmode: !this.state.editmode,
                  selfcost: null,
                })
              }
            >
              {this.state.editmode
                ? "Рассчитать автоматически"
                : "Внести значение вручную"}
            </span>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    return [
      <Card className="parseditem" style={{ padding: "0px" }} key={1}>
        <Spin spinning={this.state.loading}>
          <Spin spinning={this.state.catalogload && !this.state.loading}>
            <div
              className="cover_parseditem"
              style={{
                background: `url(${rest.getavatarurl(
                  this.state.catalogid,
                  this.state.imageName
                )})`,
              }}
            >
              {this.state.uploaded && (
                <p className="uploaded_label">Выгружено на биржу</p>
              )}
            </div>
          </Spin>
          <div className={this.state.uploaded ? "disabled open" : "disabled"}>
            <div className="info_parseditem">
              <div className="i_item">
                <p className="value">{this.state.data.Article}</p>
                <p className="label">Название в накладной</p>
              </div>
              <div className="i_item">
                <p className="value">
                  {AppHelper.toCurrency(this.state.data.SPRICE, "EUR", "de-DE")}
                </p>
                <p className="label">Стоимость на плантации 1.ед</p>
              </div>
              <div className="i_item">
                <p className="value">
                  {AppHelper.toCurrency(this.state.data.EUR, "EUR", "de-DE")}
                </p>
                <p className="label">Стоимость на плантации, всего</p>
              </div>

              <div className="i_item">
                <p className="value">{this.state.data.Total}</p>
                <p className="label">Итоговое количество</p>
              </div>
              <div className="i_item">
                <p className="value">{this.state.data.S1}</p>
                <p className="label">Параметр S1</p>
              </div>
              {this.state.selfcost && (
                <div className="i_item">
                  <p className="value">
                    {AppHelper.toCurrency(this.state.selfcost, "RUB", "ru-RU")}
                  </p>
                  <p className="label">Себестоимость 1шт</p>
                  {!this.state.uploaded && (
                    <span
                      className="subtitlemini"
                      onClick={() => this.setState({ selfcost: null })}
                    >
                      Изменить
                    </span>
                  )}
                </div>
              )}
              <div className="i_item">
                <p className="value">
                  {this.state.catalogid ? (
                    this.state.catalogName
                  ) : (
                    <Tag style={{ marginBottom: "6px" }} color="red">
                      Не выбрано
                    </Tag>
                  )}
                </p>
                <p className="label">Позиция в каталоге</p>
                <CatalogSelect
                  canSelect={!this.state.uploaded}
                  selected={this.state.catalogid ? true : false}
                  onSelect={(catalog) =>
                    this.setState({
                      catalogid: catalog.id,
                      catalogName: catalog.name,
                      imageName: catalog.imageName,
                    })
                  }
                />
              </div>

              {this.state.catalogid &&
                !this.state.selfcost &&
                !this.state.uploaded &&
                this.RenderFormToCount()}
              {this.state.catalogid &&
                this.state.selfcost &&
                !this.state.uploaded &&
                this.RenderFormExchangeUpload()}
            </div>
          </div>
          <Popconfirm
            title="Действительно удалить?"
            onConfirm={() => this.props.onDelete(this.props.index)}            
            okText="Да"
            cancelText="Нет"
          >
            <Button style={{ width: "100%" }} danger>
              Удалить позицию
            </Button>
          </Popconfirm>
        </Spin>
      </Card>,
    ];
  }
}

ParsedItem.ParsedItem = AppContext;

export default withRouter(ParsedItem);

import React from "react";
import "../../App.scss";
import { message, Skeleton, Spin } from "antd";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  Row,
  Col,
  List,
  Modal,
  Button,
  Form,
  Input,
  Collapse,
  Tag,
} from "antd";
import AppContext from "../../components/common/appContext";
import { PageHeader, Popconfirm } from "antd";
import rest from "../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../loading";
import { withRouter } from "react-router-dom";
import AppHelper from "../../helpers/app";
import SingleRequestList from "../requests/singlerequestlist";
import * as moment from "moment";
import TotalPrice from "./totalprice";
const { Panel } = Collapse;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class RequestsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Мои заявки", key: "/requests/" },
    ];

    this.state = {
      cataspan: 24,
      requests: [],
      loading: true,
    };
    document.title = "Мои заявки | Букетная.ОПТ";
    this.getRequests = this.getRequests.bind(this);
  }

  getRequestStatus(item, index) {
    let result = "";
    if (item.status == "active") {
      result = (
        <Tag key={index} color="green">
          Активная
        </Tag>
      );
    } else {
      result = (
        <Tag key={index} color="orange">
          Незивестный статус
        </Tag>
      );
    }
    return result;
  }

  componentWillMount() {
    this.getRequests();
  }

  getRequests() {
    this.setState({ loading: true });
    rest.get("/api/Requests/my").then((data) => {
      this.setState({ requests: data.data.data, loading: false });
    });
  }

  render() {
    const style = { padding: "0px 20px" };
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card">
        <Row key={2}>
          <Col span={this.state.cataspan}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Мои заявки"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Spin spinning={this.state.loading}>
              <div className="requests_wrapper">
                <Collapse accordion>
                  {this.state.requests.map((item, index) => {
                    return (
                      <Panel
                        header={`Заявка ${item.id}`}
                        extra={[
                          item.tddate && <Tag key={index+1}>Торговый день: {moment(item.tddate).format('DD MMMM YYYY')}</Tag>,
                          <Tag
                            key={0}
                            color={
                              AppHelper.getRequestStatus(item.status).color
                            }
                          >
                            {AppHelper.getRequestStatus(item.status).label}
                          </Tag>,
                          <Tag key={1}>
                            {moment(item.created).format("DD.MM.YYYY")}
                          </Tag>,
                          <Tag key={2}>
                            {item.type === "stock" ? "Со склада" : "С биржи"}
                          </Tag>,
                          
                        ]}
                        key={index}
                      >
                        <SingleRequestList
                          updateRequests={() => this.getRequests()}
                          data={item}
                        />
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
            </Spin>
          </Col>
        </Row>
      </Card>,
    ];
  }
}

RequestsComponent.contextType = AppContext;

export default withRouter(RequestsComponent);

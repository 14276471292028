import React from "react";
import "../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  FileAddOutlined,
  PlusSquareOutlined,
  SwapOutlined,
  QrcodeOutlined
} from "@ant-design/icons";
import rest from "../../helpers/rest";
import FsLightbox from "fslightbox-react";
import {
  Typography,
  Divider,
  Popconfirm,
  Spin,
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
  Tooltip,
  message,
  Tag,
} from "antd";
import AppContext from "../../components/common/appContext";
import StockEditForm from "../../components/stock/editstocksform";
import FormItem from "antd/lib/form/FormItem";
import AppHelper from "../../helpers/app"
const {Title } = Typography;



const layout = {
  labelCol: { sm: 24 },
  wrapperCol: { sm: 24 },
};


class SingleGoodComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      loading: false,
      deleted: false,
      addstockvisible: false,
      addexchangevisible: false,
      writeOffVisible:false,
      addloading: false,
      available: 0,
      changeCountPositionLoading: false,
      count: 0,
      total: 0,
      loadingWriteOffForm:false,
      percentDiscountPrice: null
    };
    this.formRef = React.createRef();
    this.countChangePriceByAdminFormRef = React.createRef();
    this.childForm = React.createRef();
    this.childFormExchange = React.createRef();
    this.writeOffForm = React.createRef();
    this.controlFormRef = React.createRef();
    this.openModal = this.openModal.bind(this);
    this.onAddToStock = this.onAddToStock.bind(this);
    this.onAddExchange = this.onAddExchange.bind(this);
    this.controlAction = this.controlAction.bind(this);
    this.addToCartFromStock = this.addToCartFromStock.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.deletePositionFromCart = this.deletePositionFromCart.bind(this);
    this.openModalAddExchange = this.openModalAddExchange.bind(this);
    this.changeCount = this.changeCount.bind(this);
    this.sumbitChangeCountByAdmin = this.sumbitChangeCountByAdmin.bind(this);
    this.generateBarCode = this.generateBarCode.bind(this);
  }

  componentWillMount() {  
    if (this.props.fromStock || this.props.fromExchange) {
      this.setState({ available: this.props.data.available });
    }
    
    if(this.props.data.incart){
      this.setState({count: this.props.data.incart})
    }
    if (this.props.data.count) {
      //console.log(this.props)
      if(this.props.percent){
        //console.log(this.props.data.price/100*this.props.percent);
        //console.log(this.props.data.price, this.props.percent)
        
        this.setState({percentDiscountPrice: this.props.data.price/100*this.props.percent}, () => {
          //console.log(this.props.data.count * (this.props.data.price - this.state.percentDiscountPrice))
          this.setState({
            count: this.props.data.count,
            total: this.props.data.count * (this.props.data.price - this.state.percentDiscountPrice),
          });
        });
        
        
      }else{
        this.setState({
          count: this.props.data.count,
          total: this.props.data.count * (this.props.selftcostdiscount ? this.props.data.selfcost : this.props.data.ustype == 'walker' ? this.props.data.WR_PRICE : this.props.data.price),
        });
      }
      
    }
  }

  componentDidMount() {    
    this.controlFormRef.current.setFieldsValue({
      count: this.props.data[this.props.incart || "incart"] || 0,
    });
  }

  openModal() {
    this.setState({ addstockvisible: true }, () => {
      // this.formRef.current.setFieldsValue({
      //   name: this.props.data.name
      // })
    });
  }

  openModalAddExchange() {
    this.setState({ addexchangevisible: true });
  }

  onAddExchange(data) {
    data.catalogid = this.props.data.id;    
    rest.post("/api/Exchange/", data).then((data) => {
      this.setState({ addexchangevisible: false });
      message.success("Позиция добавлена к торговому дню");
      this.childFormExchange.setLoad(false);
      this.childFormExchange.resetForm();
    });
  }

  onAddToStock(data) {
    // let data = this.formRef.current.getFieldsValue();
    data.catalogid = this.props.data.id;    
    rest.post("/api/Stock/", data).then((data) => {      
      this.setState({ addstockvisible: false });
      message.success("Позиция добавлена на склад");
      this.childForm.setLoad(false);
      this.childForm.resetForm();
      
    });
    
  }

  deletePositions() {
    if (this.props.fromStock) {
      this.setState({ loading: true });
      rest.delete("/api/Stock/", { id: this.props.data.id }).then((data) => {
        this.props.onDelete();
      });
    } else if (this.props.fromExchange) {
      rest.delete("/api/Exchange/", { id: this.props.data.id }).then((data) => {
        this.props.onDelete();
      });
    } else {
      this.setState({ loading: true });
      rest.delete("/api/Catalog/", { id: this.props.data.id }).then((data) => {
        this.props.onDelete();
      });
    }
  }
 

  removeFromCart(stockid, count) {
    
    this.setState({ loading: true });
    rest
      .delete("/api/Cart/deletefromcart", {
        stockid: stockid,
        count: count,
        type: this.props.type,
        tradingday: this.props.tradingday || null,
        requestid : this.props.data.requestid || null 
      })
      .then((data) => {
        let result = data.data.data;
        this.setState({ available: result.available, loading: false });
        if (this.props.update) {
          this.props.update();
        }
      })
      .catch((error) => {
        message.error(error.response.data.data.detail);
        this.setState({ loadnig: false });
        this.controlFormRef.current.setFieldsValue({
          count: count + this.props.data.multiplicity,
        });
        this.setState({count: count + this.props.data.multiplicity})
      });
  }

  addToCartFromExchange(exchangeid, count) {
    let params = {
      type: "exchange",
      exchangeid: exchangeid,
      count: count,
      requestid:
        (this.props.data.requestid || this.props.requestid) &&
        this.props.data.incart > 0
          ? this.props.data.requestid || this.props.requestid
          : null,
      throwadd: this.props.throwadd || false,
      tradingday: this.props.tradingday,
    };
    this.setState({ loading: true });
    rest.update("/api/Cart/addexchangetocart", params).then((data) => {      
      let result = data.data.data;
      if (result.status === "done") {
        message.success("Позиция добавлена в корзину");
      } else if (result.status === "error") {
        message.error(result.detail);
        this.controlFormRef.current.setFieldsValue({
          count: count - this.props.data.multiplicity,
        });
        
        this.setState({count: count - this.props.data.multiplicity})
      }
      if (this.props.update) {
        this.props.update();
      }
      this.setState({ available: result.available, loading: false });
    });
  }

  addToCartFromStock(stockid, count) {
    let params = {
      type: "stock",
      stockid: stockid,
      count: count,
      requestid:
        (this.props.data.requestid || this.props.requestid) &&
        ((this.props.data.incart > 0) || (this.props.data.count > 0))
          ? this.props.data.requestid || this.props.requestid
          : null,
      throwadd: this.props.throwadd || false,
    };
    this.setState({ loading: true });
    rest.update("/api/Cart/addtocart", params).then((data) => {      
      let result = data.data.data;
      if (result.status === "done") {
        message.success("Позиция добавлена в корзину");
      } else if (result.status === "error") {
        message.error(result.detail);        
        if(result.added_user !== undefined && result.added_user !== 'undefined' && result.added_user !== null){
          this.controlFormRef.current.setFieldsValue({
            count: result.added_user,
          });
          this.setState({count: result.added_user})
        }else{
          this.controlFormRef.current.setFieldsValue({
            count: count - this.props.data.multiplicity,
          });
          this.setState({count: count - this.props.data.multiplicity})
        }
        
        
      }
      this.setState({ available: result.available, loading: false });
      if (this.props.update) {
        this.props.update();
      }
    });
  }

  controlAction(action) {
    let data = this.controlFormRef.current.getFieldsValue();    
    if (action === "plus") {
      if (this.props.fromStock) {
        
        let count = data.count + this.props.data.multiplicity;
        
        if(this.state.available < this.props.data.multiplicity && this.state.available > 0){
          count = this.state.available + data.count;
        }
        
        this.addToCartFromStock(
          this.props.data.id,
          count
        );       
        
        this.controlFormRef.current.setFieldsValue({
          count: count
        });
        this.setState({count: count})
      } else if (this.props.fromExchange) {  
        let count = data.count + this.props.data.multiplicity;
        
        if(this.state.available < this.props.data.multiplicity && this.state.available > 0){
          count = this.state.available + data.count;
        }      
        this.addToCartFromExchange(
          this.props.data.id,
          count
        );
        this.controlFormRef.current.setFieldsValue({
          count: count
        });
        this.setState({count: count})
      }
    } else if (action === "minus") {
      if (data.count >= this.props.data.multiplicity) {
        if (this.props.fromStock) {
          let count = data.count - this.props.data.multiplicity;                              
          if(Math.ceil((count+this.props.data.multiplicity)%this.props.data.multiplicity) > 0){
            count = count+this.props.data.multiplicity - Math.ceil((count+this.props.data.multiplicity)%this.props.data.multiplicity);
          }          
          this.removeFromCart(
            this.props.data.id,
            count
          );
          this.controlFormRef.current.setFieldsValue({
            count: count,
          });
          this.setState({count: count})
        } else if (this.props.fromExchange) {   
          let count = data.count - this.props.data.multiplicity;                    
          if(Math.ceil((count+this.props.data.multiplicity)%this.props.data.multiplicity) > 0){
            count = count+this.props.data.multiplicity - Math.ceil((count+this.props.data.multiplicity)%this.props.data.multiplicity);
          }
          this.removeFromCart(
            this.props.data.id,
            count
          );
          this.controlFormRef.current.setFieldsValue({
            count: count
          });
          this.setState({count: count})
        }
      }
    }
  }

  changeCount() {    
    if(this.props.data.status != 'active'){
      message.warn("Изменить количество можно только в статусе заявки \"Активная\"")
    }else{  
      this.setState({ changeCountPositionVisible: true });

    setTimeout(() => {
      this.countChangePriceByAdminFormRef.current.setFieldsValue({
        count: this.state.count,
      });
    }, 5);
    }

  }

  sumbitChangeCountByAdmin(data) {
    this.setState({ changeCountPositionLoading: true });
    // После запроса вырубаем загрузку
    rest
      .update("/api/Requests/changecount", {
        count: data.count,
        id: this.props.data.id,
        requestid: this.props.data.requestid,
        cartid: this.props.data.cartid,
        type: this.props.type,
      })
      .then((res) => {  
        this.setState({
          available:res.data.data.available,
          changeCountPositionLoading: false,
          changeCountPositionVisible: false,
          count: data.count,
          total: data.count * (this.props.selftcostdiscount ? this.props.data.selfcost : this.props.data.price),
        });
        message.success("Количество позиций изменено");
        this.controlFormRef.current.setFieldsValue({
          count: this.props.data[this.props.incart || "incart"] || 0,
        });        
        if (typeof this.props.update === "function") {
          this.props.update();
        }

        
      })
      .catch((e) => {
        console.log(e);
        message.error("Ошибка не определена");
      });

    
  }

  submitEdit(data) {
    
  }

  writeOffPosition(value){
    this.setState({loadingWriteOffForm:true})
    let body = value;
    let id = this.props.data.id;
    let catalogid = this.props.data.catalogid
    body.id = id;
    body.catalogid = catalogid;
    
    body.fromCatalog = (!this.props.fromStock && !this.props.fromExchange);
    
    rest.post('api/WriteOffs',body).then(res => {
      this.setState({loadingWriteOffForm:false})
      if(this.props.refreshList){
        this.props.refreshList()
      }
      this.setState({ writeOffVisible: false })
    })

    
  }
  // Метод отправляет запрос на удаление позиции из корзины
  deletePositionFromCart() {
    rest
      .delete("/api/Cart/deletefromcart", {
        stockid: this.props.data.id,
        count: 0,
        type: this.props.type,
        requestid : this.props.data.requestid || null 
      })
      .then((data) => {
        
      });
    this.props.deleteFromCart(this.props.data.id);
  }

  generateBarCode(){
    //this.props.data.id
    this.context.setLoad(true, 'Генерация штрих-кода');
    rest.post('/api/BarCodes/', {type: 'stock', ids: [this.props.data.id]}).then(data => {
        //data.data.data.pdf__name
        let url = rest.getfileurl(`/stickers/${data.data.data.pdf__name}`);
        window.open(url, 'blank');
        this.context.setLoad(false, '');
    })
  }

  render() {
    return [
      <Spin spinning={this.state.loading} key={1}>
        <div className="singlegood" key={2}>
          <div
            className="image cover"
            onClick={() => this.setState({ toggler: !this.state.toggler })}
            style={{
              background: `url(${rest.getavatarurl(
                this.props.fromStock || this.props.fromExchange
                  ? this.props.data.catalogid
                  : this.props.data.id,
                this.props.data.image
              )})`,
            }}
          >
            <FsLightbox
              toggler={this.state.toggler}
              sources={[
                rest.getavatarurl(
                  this.props.fromStock || this.props.fromExchange
                    ? this.props.data.catalogid
                    : this.props.data.id,
                  this.props.data.image
                ),
              ]}
            />
          </div>
          <div className="singlegood__info">
            <Title level={4}>
              {this.props.data.name}{" "}
              {this.context.usertype === "admin" &&
                (this.props.fromStock || this.props.fromExchange) &&
                this.props.fromCart && (
                  <div className="tags">
                    {
                      <Tag onClick={() => this.changeCount()} color="green">
                        Количество: {this.state.count}{" "}
                        <EditOutlined className="hover" />
                      </Tag>  
                    }
                    {" "}
                    <Tag color="orange">Итого: {this.state.total}р</Tag>
                  </div>
                )}{" "}
            </Title>
            {(this.props.fromStock || this.props.fromExchange) && (
              <div className="more">{this.props.data.more} &nbsp;</div>
            )}
            <div className="countcontrols">
              <Form
                name="countcontrols"
                ref={this.controlFormRef}
                initialValues={{ count: 0 }}
              >
                {(this.context.usertype !== "admin" && !this.props.WRPRICE_ONLY && !this.props.forPreorder) && (
                  <div className="control__item countcontrol">
                    <span className="label">В корзине  </span>                    
                    {((this.props.data.confirmedquantity || 0) < this.state.count) && (
                      <Button onClick={() => this.controlAction("minus")}>
                        -
                      </Button>
                    )}

                    <Form.Item name="count">
                      <Input disabled={true} />
                    </Form.Item>
                    {!this.props.blockPlus && (
                      <Button onClick={() => this.controlAction("plus")}>
                        +
                      </Button>
                    )}
                  </div>
                )}
                {/* {
                  this.context.usertype === 'admin' && <div className="control__item">
                    <h3>Количество: {this.props.data.count}</h3>
                  </div>
                }                 */}
              </Form>
            </div>
            <div className="bottom">
              <div className="params">
                <div className="param__item">
                  Цвет: <span>{this.props.data.colorname}</span>{" "}
                  <div
                    className="colordisplay"
                    style={{ background: this.props.data.colorvalue }}
                  ></div>
                </div>
                {(this.props.fromStock || this.props.fromExchange) && (
                  <div className="param__item">
                    Кратность: <span>{this.props.data.multiplicity}</span>
                  </div>
                )}
                {(this.props.fromStock || this.props.fromExchange) && (
                  <div className="param__item">
                    Страна: <span>{this.props.data.country}</span>
                  </div>
                )}
                {(this.props.fromStock || this.props.fromExchange) && (
                  <div className="param__item">
                    Рост: <span>{this.props.data.growth}</span>
                  </div>
                )}
                {(this.props.fromStock || this.props.fromExchange) && ((!this.props.WRPRICE_ONLY)) && ((!this.state.percentDiscountPrice)) && (
                  <div className="param__item">
                    Цена: <span><span className={`${this.props.selftcostdiscount ? 'old_price' : ''}`}>{AppHelper.toCurrency(this.props.data.price, "RUB", "Ru-ru")}</span>{this.props.selftcostdiscount && <span className="newprice">{AppHelper.toCurrency(this.props.data.selfcost, "RUB", "Ru-ru")}</span>}</span>                    
                  </div>
                )}

                {(this.props.fromStock || this.props.fromExchange) && ((!this.props.WRPRICE_ONLY)) && ((this.state.percentDiscountPrice)) && (
                  <div className="param__item">
                    
                    Цена: <span><span className={`${this.state.percentDiscountPrice ? 'old_price' : ''}`}>{AppHelper.toCurrency(this.props.data.price, "RUB", "Ru-ru")}</span>{this.state.percentDiscountPrice && <span className="newprice">{AppHelper.toCurrency(this.props.data.price - this.state.percentDiscountPrice, "RUB", "Ru-ru")}</span>}</span>                    
                  </div>
                )}
                {(this.props.fromStock || this.props.fromExchange) && (
                  <div className="param__item">
                    Доступно: <span>{this.state.available}</span>
                  </div>
                )}
                {((this.props.fromStock || this.props.fromExchange) && (this.context.usertype == "admin") && (!this.props.WRPRICE_ONLY)) && (
                  <div className="param__item">
                    Себестоимость: <span>{AppHelper.toCurrency(this.props.data.selfcost, "RUB", "Ru-ru")}</span>
                  </div>
                )}
                {((this.props.fromStock) && (this.context.usertype == "admin" || (this.props.WRPRICE_ONLY))) && (
                  <div className="param__item">
                    Мелкий опт: <span>{AppHelper.toCurrency(this.props.data.WR_PRICE, "RUB", "Ru-ru")}</span>
                  </div>
                )}
              </div>
              
              {!this.props.hideActions && (
                <div className="actions">
                  {this.props.fromStock && this.props.fromCart && (
                    <Popconfirm
                      title={`Удалить позицию из корзины ?`}
                      onConfirm={() => this.deletePositionFromCart()}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <a style={{ color: "red" }}>
                        <DeleteOutlined />
                      </a>
                    </Popconfirm>
              )}
                
                {this.context.usertype === "admin" && (
                  <span>
                    {(!this.props.fromStock && !this.props.fromExchange) && (
                      <span>
                        <Tooltip placement="top" title="Добавить на биржу">                          
                          <PlusSquareOutlined  onClick={() => this.openModalAddExchange()} />
                        </Tooltip>
                        <Divider type="vertical" />
                        <Tooltip placement="top" title="Добавить на склад">
                          <FileAddOutlined onClick={() => this.openModal()} />
                        </Tooltip>
                        <Divider type="vertical" />                        
                        
                      </span>
                    )}
                    <Tooltip placement="top" title="Редактировать">
                    <NavLink to={`/${this.props.fromStock ? 'stock' :  this.props.fromExchange ? 'exchange' : 'catalog'}/edit/${this.props.data.id}`}>
                      <EditOutlined/>
                    </NavLink>
                    </Tooltip>

                    {((this.props.fromStock || (!this.props.fromStock && !this.props.fromExchange))&&this.context.usertype === "admin" )&&(
                      <span>
                        <Divider type="vertical" />
                        <Tooltip placement="top" title="Списать">               
                        <SwapOutlined onClick={() => this.setState({writeOffVisible:true})}/>
                        </Tooltip>
                      </span>
                      
                    )}
                    {(this.props.fromStock && this.context.usertype === "admin" )&&(
                      <span>
                        <Divider type="vertical" />
                        <Tooltip placement="top" title="Получить BarCode">               
                        <QrcodeOutlined onClick={() => this.generateBarCode()}/>
                        </Tooltip>
                      </span>
                      
                    )}
                      
                    <Divider type="vertical" />
                    <Popconfirm
                      title={`Удалить позицию ${
                        this.props.fromStock ? "со склада" : this.props.fromExchange ? "с биржи" : ""
                      }?`}
                      onConfirm={() => this.deletePositions()}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <a style={{ color: "red" }}>
                        <DeleteOutlined />
                      </a>
                    </Popconfirm>
                  </span>
            )}      
              </div>
              
              )}                
            </div>
            {
                this.props.forSelect && <div className="actions">
                  <Button type="primary" onClick={() => this.props.onSelect(this.props.data)}>Выбрать</Button>
                </div>
              }
          </div>
          
        </div>
      </Spin>,
      this.context.usertype == "admin" && (
        <Modal
          key={2}
          className="addstock"
          title="Добавить на склад"
          visible={this.state.addstockvisible}
          onOk={() => this.childForm.submitForm()}
          onCancel={() => this.setState({ addstockvisible: false })}
          maskClosable={false}
        >
          <StockEditForm
            onSuccessSubmit={(data) => this.onAddToStock(data)}
            onRef={(ref) => (this.childForm = ref)}
            catalogid={this.props.data.id}
          />
        </Modal>
      ),
      this.context.usertype === "admin" && (
        <Modal
          key={3}
          className="addstock"
          title="Добавить на биржку"
          visible={this.state.addexchangevisible}
          onOk={() => this.childFormExchange.submitForm()}
          onCancel={() => this.setState({ addexchangevisible: false })}
          maskClosable={false}
        >
          <StockEditForm
            forTrade={true}
            onSuccessSubmit={(data) => this.onAddExchange(data)}
            onRef={(ref) => (this.childFormExchange = ref)}
            catalogid={this.props.data.id}
          />
        </Modal>
      ),
      <Modal
        key={4}
        title="Изменение количества"
        visible={this.state.changeCountPositionVisible}
        onOk={() => this.countChangePriceByAdminFormRef.current.submit()}
        onCancel={() => this.setState({ changeCountPositionVisible: false })}
        maskClosable={false}
      >
        <Spin spinning={this.state.changeCountPositionLoading}>
          <Form
            name="countcontrols"
            ref={this.countChangePriceByAdminFormRef}
            onFinish={this.sumbitChangeCountByAdmin}
          >
            <div className="control__item countcontrol">
              <Form.Item name="count">
                <Input disabled={false} />
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>,
 
      
      <Modal
        key={5}
        title="Списать" 
        visible={this.state.writeOffVisible}
        onOk={() => this.writeOffForm.current.submit()}
        onCancel={() => this.setState({ writeOffVisible: false })}
        maskClosable={false}
        >
          <Spin spinning={this.state.loadingWriteOffForm}>
          <Form
          {...layout}
            onFinish={(data)=>this.writeOffPosition(data)}
            ref={this.writeOffForm}
            layout="vertical"
          >
            
            <FormItem
              label="Введите количество"
              name="amount"
              rules={[
                { required: true, message: "Пожалуйста введите колличество!" },
              ]}
            >
               <InputNumber style={{ width: "100%" }} step={1} min={1} max={this.props.data.available}/>
            </FormItem>

            <FormItem
              label="Введите Комментарий"
              name="commentary"
              rules={[
                { required: true, message: "Пожалуйста введите Комментарий" },
              ]}
            >
              <Input/>
            </FormItem>
            
          </Form>
          </Spin>
      </Modal>
    ];
  }
}

SingleGoodComponent.contextType = AppContext;

export default withRouter(SingleGoodComponent);

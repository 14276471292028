import React from 'react';
import {Link} from 'react-router-dom';
import '../../App.scss';
import { Card, Button } from 'antd';


class NotFound extends React.Component{
    constructor(props){
        super(props);
    }

    componentWillMount(){
        document.title = 'Страница не найдена | Букетная.ОПТ'
    }

    render(){
        return [                
                <Card
                    key={2}
                    className="content-card"
                    title="Страница не найдена"
                >
                    <Link to="/exchange">
                        <Button type="primary">Биржа</Button>
                    </Link>
                </Card>
            ]
        
    }
}

export default NotFound;
import * as React from "react";
import { Select, Form, InputNumber, Button, Spin, message } from "antd";

import { withRouter } from "react-router-dom";
import * as moment from "moment";

import "moment/locale/ru";
import rest from "../../../../../helpers/rest";

moment.locale("ru");
const { Option } = Select;

class RequestDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onFinish = (value) => {
    this.setState({loading: true});
    rest.update('/api/Requests/setdiscount', {id: this.props.request.id, PERCENT_DISCOUNT: value.PERCENT_DISCOUNT}).then(data => {
        this.setState({loading: false});
        message.success('Скидка установлена');
        this.props.done();
    })
  }

  componentDidMount(){
      console.log(this.props.request)
  }

  

  render() {
    return [
      <Spin key={1} spinning={this.state.loading}>
        <div className="set__discount">
          <Form name="discount__form" layout="vertical" initialValues={{PERCENT_DISCOUNT: this.props.request.PERCENT_DISCOUNT || 0}} onFinish={this.onFinish} autoComplete="off">
            <Form.Item
              label="Скидка по заявке, %"
              name="PERCENT_DISCOUNT"
              rules={[
                { required: true, message: "Укажите скидку!" },
              ]}
            >
              <InputNumber
                min={0}
                max={100}                
                onChange={this.onChange}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit">Установить скидку</Button>
          </Form>
        </div>
      </Spin>,
    ];
  }
}

export default withRouter(RequestDiscount);

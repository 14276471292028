import React from 'react';
import '../../App.scss';
import { message, Spin } from 'antd';
import AppContext from "../common/appContext";
import rest from '../../helpers/rest';
import {    
    Redirect    
  } from "react-router-dom"

class ExchangePrivateRouter extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isAccept: false,
            loading: true
        }
    }

    componentDidMount(){
        this.context.setLoad(true, 'Проверка доступа к бирже')        
        this.checkActiveTrades();
    }

    checkActiveTrades() { 
        let byAdmin = false;
        if(localStorage.getItem('c_token')){byAdmin = true};
    
        rest.get("/api/Trading/", {active: true, byAdmin: byAdmin}).then((data) => {
        //console.log(data.data.data);
        //console.log(this.props.computedMatch.params.day)
        let compare = data.data.data.filter(i => i.needtime != 1).filter(i => i.id == parseInt(this.props.computedMatch.params.day));
        console.log(data.data.data.filter(i => i.needtime != 1))
        console.log(compare)
        //console.log(this.props.computedMatch.params.day)
        this.setState({isAccept: true}, () => {
            this.setState({loading: false});
            this.context.setLoad(false, '');                        
        })
        // if(compare.length == 0 && this.context.usertype != 'admin'){
        //     this.setState({loading: false});
        //     this.context.setLoad(false, '');
        //     message.error('Ошибка доступа к торговому дню')   
        // }else{
        //     this.setState({isAccept: true}, () => {
        //         this.setState({loading: false});
        //         this.context.setLoad(false, '');                        
        //     })
        // }
          
        });
      }

    

    render(){
        const { component: Component, ...rest } = this.props
        //console.log(this.props);
        return(
            (this.state.loading) ? <h1>Загрузка</h1> : (!this.state.loading && this.state.isAccept) ? <Component {...this.props } /> : <Redirect to='/stock' />
        )
    }
}

ExchangePrivateRouter.contextType = AppContext;

export default ExchangePrivateRouter;
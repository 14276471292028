import * as React from "react";
import { Select, Form, InputNumber, Button, Spin  } from "antd";

import { withRouter } from "react-router-dom";
import * as moment from "moment";

import "moment/locale/ru";
import rest from "../../../../../helpers/rest";
moment.locale("ru");
const { Option } = Select;

class PaymentAddV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {ptypes: [], loading: true};
    this.formRef = React.createRef();
  }

  componentDidMount(){
      rest.get('/api/Transactions/paytypes').then(data => {
          this.setState({ptypes: data.data.data, loading: false})
      })
  }

  submitForm = () => {
      let params = {
          comment : 'Внесение через интерфейс',
          ...this.formRef.current.getFieldsValue(),
          request: this.props.request
      }
      this.setState({loading: true})
      rest.post('/api/Transactions/create', params).then(data => {
        this.setState({loading: false})
        this.formRef.current.resetFields();
        this.props.onAdd()
      })
  }

  render() {
    return [
    <Spin key={1} spinning={this.state.loading}>
      <div key={1} className="paymentadd__form">
        <Form key={1} ref={this.formRef} layout="vertical" onFinish={() => this.submitForm()}>
          <Form.Item
          key={1}
          label="Сумма"
          name="summ"
            rules={[
              {
                required: true,
                message: "Пожалуйста введите сумму",
              },
            ]}
          >
              <InputNumber />
          </Form.Item>
          <Form.Item
          key={2}
          label="Тип оплаты"
          name="payment"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите тип",
              },
            ]}
          >
              <Select          
          placeholder="Укажите тип"                    
        >
          {this.state.ptypes.map((item, i) => {
            return (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
          </Form.Item>
          <Button key={3} type="primary" htmlType="submit">Добавить</Button>
        </Form>
      </div>
      </Spin>
    ];
  }
}

export default withRouter(PaymentAddV2);
